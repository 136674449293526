import { Route } from '@angular/router';
import { mzicIoRoutes } from '@mzic/mzic-io';

export const appRoutes: Route[] = [
  {
    path: '',
    children: mzicIoRoutes,
    title: (param) => {
      const lang = param.params['lang'];

      if (lang === 'pt-br') {
        return 'MZIC PARA ARTISTAS';
      }

      return 'MZIC FOR ARTISTS';
    },
  },
];
