import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueNames',
  standalone: true,
})
export class UniqueNamesPipe implements PipeTransform {
  transform(
    value: { name?: string; title?: string }[],
    limit?: number,
  ): string {
    if (!Array.isArray(value) || value.length === 0) {
      return '';
    }

    const uniqueValues = [
      ...new Set(value.map((item) => item.name || item.title).filter(Boolean)),
    ];

    if (!limit) {
      return uniqueValues.join(', ');
    }

    if (uniqueValues.length > limit) {
      const remaining = uniqueValues.length - limit;
      return `${uniqueValues.slice(0, limit).join(', ')} +${remaining}`;
    }

    return uniqueValues.join(', ');
  }
}
