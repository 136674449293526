import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MzicSvgComponent } from '../mzic-svg/mzic-svg.component';
import { ExplainDirective } from './directives/explain.directive';

@Component({
  selector: 'mzic-explain',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent, ExplainDirective, OverlayModule],
  templateUrl: './explain.component.html',
  styleUrl: './explain.component.scss',
})
export class ExplainComponent {
  @Input({ required: true }) text: string | undefined;
  @Input() iconClass = 'neutral-300';
  @Input() iconSize = 'w12';
  @Input() iconUrl = 'assets/icons/mzic-exclamation.svg';
  isOpen = false;

  positions = [
    new ConnectionPositionPair(
      { originX: 'center', originY: 'top' },
      { overlayX: 'center', overlayY: 'bottom' },
    ),
  ];

  get classes() {
    return `${this.iconClass} ${this.iconSize}`;
  }
}
