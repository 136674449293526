export interface WalletFeeSimulate {
  timestamp: string;
  status: string;
  data: WalletFeeSimulateData;
}

export interface WalletFeeSimulateData {
  feeType?: string;
  fees: Fee[];
  totalFeeCalculatedPrice: number;
  transactionCalculatedPrice: number;
  transactionConvertedPrice: number;
  rate: number;
  fromCurrency: string;
  toCurrency: string;
  simulatedFeeId: string;
}

export interface Fee {
  id?: number;
  type: string;
  fixedPrice: number;
  taxPercent: number;
  calculatedPrice: number;
  code: string;
  displayName: string;
  currencyType: string;
}

export interface WalletFeeSimulateParams {
  feeType: string; // FeeTypeEnum
  currencyType: string; // currencyType
  toCurrencyType?: string; // currencyType
  transactionPrice: number;
}

export function createEmptyWalletFeeSimulateData(): WalletFeeSimulateData {
  return {
    fees: [
      {
        id: undefined,
        type: '',
        fixedPrice: 0,
        taxPercent: 0,
        calculatedPrice: 0,
        code: '',
        displayName: '',
        currencyType: '',
      },
    ],
    totalFeeCalculatedPrice: 0,
    transactionCalculatedPrice: 0,
    transactionConvertedPrice: 0,
    rate: 0,
    fromCurrency: '',
    toCurrency: '',
    simulatedFeeId: '',
  };
}
