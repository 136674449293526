import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ImageCDNSizes, MzicImagePipe, ZeropadPipe } from '@mzic/mzic-utils';

@Component({
  selector: 'mzic-track-list-item',
  standalone: true,
  imports: [CommonModule, MzicImagePipe, ZeropadPipe],
  templateUrl: './mzic-track-list-item.component.html',
  styleUrls: ['./mzic-track-list-item.component.scss'],
})
export class MzicTrackListItemComponent {
  @Input() showImage = true;
  @Input() title = '';
  @Input() version = '';
  @Input() description = '';
  @Input() image: string | undefined = '';
  @Input() imageLayout: 'circle' | 'rounded' = 'circle';
  @Input() disabled = false;
  @Input() nameInBold = false;
  @Input() textOverImage: any = '';
  @Input() opacityImage = false;
  @Input() trackNumber = 0;

  imageCDNSizes = ImageCDNSizes;
}
