import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'mzic-svg',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mzic-svg.component.html',
  styleUrls: ['./mzic-svg.component.scss'],
})
export class MzicSvgComponent implements OnInit, OnChanges {
  @Input() src = '';

  constructor(
    private el: ElementRef,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this._getImg();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.src = changes['src'].currentValue;
    this._getImg();
  }

  private _getImg(): void {
    // gambiarra pro icone funcionar no storybook

    let url = this.src;

    if (window.location.origin === 'http://localhost:4400') {
      const origin = window.location.origin.replace(':4400', ':4200');
      url = `${origin}/${this.src}`;
    }

    this.http.get(url, { responseType: 'text' }).subscribe((svg) => {
      this.el.nativeElement.innerHTML = svg;
    });
  }
}
