import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  MediaResourceDownloadTrack,
  MediaResourceUploadTrack,
  ResponseInterface,
} from '@mzic/mzic-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private apiUrl = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  uploadImage(
    file: File,
    cover = false,
  ): Observable<ResponseInterface<string>> {
    const url = `${this.apiUrl}/api/distribution/media/v2/upload/image?cover=${cover}`;
    const formData = new FormData();
    formData.append('file', file);
    return this._http.post<ResponseInterface<string>>(url, formData);
  }

  downloadTrackAudioFile(
    trackId: number,
  ): Observable<MediaResourceDownloadTrack> {
    return this._http.get<MediaResourceDownloadTrack>(
      `${this.apiUrl}/api/distribution/media/v2/download/track?trackId=${trackId}`,
    );
  }

  uploadTrackAudioFile(
    file: File,
    trackId?: number,
  ): Observable<MediaResourceUploadTrack> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    // Se passar o TrackID então atualiza o aúdio
    return this._http.post<MediaResourceUploadTrack>(
      `${environment.apiUrlLegacy}/api/distribution/media/v2/upload/track${trackId ? `?trackId=${trackId}` : ''}`,
      formData,
    );
  }
}
