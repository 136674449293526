<ng-container *transloco="let t">
  <audio
    preload="auto"
    [ngClass]="{ visible: showAudioPlayer() }"
    #audioPlayer
    controls
    [src]="audioPath"
    (error)="audioError($event)"
    (play)="audioPlay($event)"
    (playing)="audioPlaying($event)"
    (pause)="audioPause($event)"
    (timeupdate)="audioTimeUpdate($event, this)"
    (canplay)="audioCanplay($event)"
    (loadedmetadata)="audioLoadedMetadata($event)"
    (loadstart)="audioLoadstart($event)"
    (loadeddata)="audioLoadedData($event)"
    (ended)="audioEnded($event)"></audio>

  <div
    *ngIf="loading || !drawPath"
    class="no-audio"
    [style.width]="width() + 'px'"
    [style.height]="height() + 'px'">
    <div class="line" [style.background-color]="colorDefault()"></div>
  </div>

  <div class="player-container">
    @if (showStartPauseButton()) {
      <div class="buttons-container">
        @if (audioIsPlaying) {
          <mzic-svg
            src="assets/icons/mzic-pause.svg"
            (click)="buttonPause()"
            class="purple" />
        } @else {
          <mzic-svg
            src="assets/icons/mzic-play.svg"
            (click)="buttonPlay()"
            class="purple" />
        }
      </div>
    }

    <div
      id="svgContainer"
      class="svg-container"
      [ngClass]="{ visible: !loading && !!audioData }"
      #svgContainer>
      <div class="to-responsive-works"></div>
      <svg id="audioSvg" [attr.height]="height()" [attr.width]="width()">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%">
            @if (showPreview) {
              <stop offset="0" [attr.style]="'stop-color: ' + colorDefault()" />
              <stop
                [attr.offset]="startPlayInsidePreview"
                [attr.style]="'stop-color: ' + colorDefault()" />
              <stop
                [attr.offset]="startPlayInsidePreview"
                [attr.style]="'stop-color: ' + colorActive()" />
            } @else {
              <stop offset="0" [attr.style]="'stop-color: ' + colorActive()" />
            }
            <stop
              [attr.offset]="audioPercent"
              [attr.style]="'stop-color: ' + colorActive()" />
            <stop
              [attr.offset]="audioPercent"
              [attr.style]="'stop-color: ' + colorDefault()" />
            <stop offset="1" [attr.style]="'stop-color: ' + colorDefault()" />
          </linearGradient>
        </defs>
        <path
          style="width: 100%"
          stroke-width="2px"
          fill="none"
          stroke="url(#gradient)"
          d="" />
      </svg>

      <div
        class="preview-container visible-{{ showPreview }}"
        id="previewContainer">
        <div class="arrows-container">
          <mzic-svg src="assets/icons/mzic-arrow-left.svg" class="w14" />
          <mzic-svg src="assets/icons/mzic-arrow-right.svg" class="w14" />
        </div>
      </div>
    </div>
  </div>

  @if (showDuration() && !loading) {
    <div class="duration-row">
      <span>00:00</span>
      <span>{{ duration | mzicDuration }}</span>
    </div>
  }

  @if (showPreviewButton()) {
    <div class="preview-config-container">
      <div class="toggle-container">
        <label class="text-body-xsm">
          {{ t('player.preview') }} / {{ t('player.clip_start_time') }}
        </label>
        <mzic-toggle (changed)="enablePreview($event)" size="mini" />
      </div>

      @if (showPreview) {
        <div class="mz-form-row for-text">
          <div class="mz-label">
            <label>
              {{ t('player.preview') }} / {{ t('player.clip_start_time') }}
            </label>
          </div>
          <div class="mz-input">
            <input
              [formControl]="startTimeControl"
              type="text"
              mask="00:00"
              class="visible"
              placeholder="{{ t('player.enter_preview_start') }}" />
          </div>
        </div>
      }
    </div>
  }
</ng-container>
