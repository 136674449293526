<mzic-select-basic
  [useLightStyle]="useLightStyle"
  [useSuccessStyle]="useSuccessStyle"
  [useErrorStyle]="useErrorStyle"
  [disabled]="disabled"
  [loading]="loading"
  [variation]="variation"
  [options]="options"
  [invalid]="invalid"
  (optionChange)="handleOptionChange($event)"
  (closedChange)="handleSelectClosedChange($event)"
  [placeholder]="placeholder"></mzic-select-basic>
