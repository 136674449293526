export interface WalletTransactions {
  timestamp: string;
  status: string;
  data: WalletTransactionsData;
}

export interface WalletTransactionsData {
  content: WalletTransactionsDataContent[];
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
}

export interface WalletTransactionsDataContent {
  transactionDate: string;
  transactionValue: number;
  fromCurrencyType: string;
  toCurrencyType: string;
  eventName: string;
  transactionType: string;
  status: string;
  transactionCode: string;
  tokenAmount?: any;
}

export interface WalletTransactionsParams {
  transactionType?: string;
  transactionDateStart?: string | undefined;
  transactionDateEnd?: string | undefined;
  pageable: WalletTransactionsPaginationParams;
}

export interface WalletTransactionsPaginationParams {
  page: number;
  size: number;
  sort?: string[];
}

export function createEmptyWalletTransactionsData(): WalletTransactionsData {
  return {
    content: [],
    pageNumber: 0,
    pageSize: 0,
    totalElements: 0,
    totalPages: 0,
  };
}
