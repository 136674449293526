import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoaderService {
  showLoader() {
    document.getElementById('globalServiceLoader')?.classList.remove('success');
    document.getElementById('globalServiceLoader')?.classList.add('visible');
  }

  showSuccess() {
    document.getElementById('globalServiceLoader')?.classList.remove('visible');
    document.getElementById('globalServiceLoader')?.classList.add('success');
  }

  hide() {
    document.getElementById('globalServiceLoader')?.classList.remove('visible');
    document.getElementById('globalServiceLoader')?.classList.remove('success');
  }
}
