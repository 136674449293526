export interface Composer {
  nome: string;
  biografia: string[];
  composicoes: Compose[];
}

export interface Compose {
  musica: string;
  artistas: string;
  cover: string;
}

export const composersData = [
  {
    nome: 'Christian Ribeiro',
    biografia: [
      'Cristhyan Ribeiro é um compositor brasileiro que se consolidou como um dos maiores criadores de sucessos na cena sertaneja contemporânea.',
      'Reconhecido por sua habilidade em transformar sentimentos em letras marcantes, Cristhyan compôs hits que embalam milhões de fãs. Entre seus maiores sucessos estão “Infrato” (Diego & Victor Hugo), “Barzinho Aleatório” e “Textão” (Zé Neto & Cristiano), além de “Briga Feia” (Henrique & Juliano) e “Peguei Ranço” (Maiara & Maraisa).',
      'Suas composições se destacam por contar histórias envolventes e por conectar com o público de forma genuína, confirmando seu lugar como referência indispensável na música sertaneja contemporânea.',
    ],
    composicoes: [
      {
        musica: 'Briga Feia – Ao Vivo',
        artistas: 'Henrique & Juliano',
        cover: 'briga-feia.jpeg',
      },
      {
        musica: 'Estranho',
        artistas: 'Marília Mendonça',
        cover: 'estranho.jpeg',
      },
      {
        musica: 'Deu Moral',
        artistas: 'Zé Neto & Cristiano',
        cover: 'deu-moral.jpeg',
      },
      {
        musica: 'Cidade Vizinha – Ao Vivo',
        artistas: 'Henrique & Juliano',
        cover: 'cidade-visinha.jpeg',
      },
      {
        musica: 'Ela e Ela',
        artistas: 'Zé Neto & Cristiano',
        cover: 'ela-e-ela.jpeg',
      },
    ],
  },
  {
    nome: 'Flavinho Tinto',
    biografia: [
      'Cantor e compositor natural de Sarandi, Paraná, Matheus Marcolino iniciou sua carreira aos 18 anos e se destacou como um dos principais nomes da nova geração da música sertaneja.',
      'Conhecido por sua habilidade em transformar emoções profundas em letras marcantes, já compôs mais de 100 músicas e soma mais de 28 milhões de streams no Spotify. Entre suas músicas de destaque estão “DEJA VU” (Luan Santana, Ana Castela) e “ABALO EMOCIONAL” (Luan Santana) e “Nem Doeú” (Matheus & Kauan).',
      'Além de seu trabalho como compositor, Matheus também investe na carreira solo e busca transformar suas vivências pessoais em canções, refletindo sua influência no cenário musical atual.',
    ],
    composicoes: [
      {
        musica: 'Facas – Ao Vivo',
        artistas: 'Diego & Victor Hugo, Bruno & Marrone',
        cover: 'facas.jpeg',
      },
      {
        musica: '3 Batidas (Ao Vivo)',
        artistas: 'Guilherme & Benuto',
        cover: '3batidas.jpeg',
      },
      {
        musica: 'Pulei na Piscina',
        artistas: 'Guilherme & Benuto',
        cover: 'puleiNaPiscina.jpeg',
      },
      {
        musica: 'Mulher Maravilha – Ao Vivo',
        artistas: 'Zé Neto & Cristiano',
        cover: 'mulher-maravilha.jpeg',
      },
      {
        musica: 'Textão – Ao Vivo',
        artistas: 'Zé Neto & Cristiano',
        cover: 'textao.jpeg',
      },
    ],
  },
  {
    nome: 'Matheus Marcolino',
    biografia: [
      'Cantor e compositor, Flavinho Tinto nasceu em Uberaba/MG. Superando uma infância difícil, começou a compor na adolescência e encontrou na música uma forma de expressar seus sentimentos e desventuras.',
      'Foi segunda voz uma dupla sertaneja mineira, e, em 2005, teve uma de suas composições gravada por um artista de renome nacional. Fundou com seus parceiros, Nando e Douglas Mello, a Fabrika Hitz, a primeira casa de composição do país.',
      'Flavinho assina mais de mil composições e soma mais de 3 bilhões de visualizações no Youtube. Entre seus sucessos estão “Facas” – Diego e Victor Hugo feat. Bruno e Marrone, “Mulher Maravilha” – Zé Neto e Cristiano e “Pulei na Piscina” – Guilherme e Benuto.',
    ],
    composicoes: [
      {
        musica: 'Deja Vu',
        artistas: 'Luan Santana, Ana Castela',
        cover: 'dejavu.jpeg',
      },
      {
        musica: 'Abalo Emocional – Ao Vivo',
        artistas: 'Luan Santana',
        cover: 'abalo-emocional.jpeg',
      },
      {
        musica: 'Nem Doeu – Ao Vivo',
        artistas: 'Matheus & Kauan',
        cover: 'nem-doeu.jpeg',
      },
      {
        musica: 'Sorria',
        artistas: 'Luan Santana, Mc Don Juan',
        cover: 'sorria.jpeg',
      },
      {
        musica: 'Bobinha – Ao Vivo',
        artistas: 'Jorge & Mateus',
        cover: 'terra-sem-cep.jpeg',
      },
    ],
  },
  {
    nome: 'Douglas Mello',
    biografia: [
      'Douglas Mello é um cantor e compositor nascido em Sacramento, interior de Minas Gerais. Vindo de uma família musical, ele aprendeu violão aos 9 anos e descobriu sua paixão pela música.',
      'Reconhecido pela facilidade em compor letras cativantes, suas canções já foram gravadas por diversos artistas renomados. Entre seus maiores sucessos estão “Desbloqueado – Ao Vivo” (Diego & Victor Hugo), “Facilita” – Ao Vivo (Marília Mendonça & Maiara & Maraisa) e “Para de Me Chamar Pra Trair” (Matheus & Kauan).',
      'Junto com Flavinho Tinto e Nando Marx, Douglas é um dos sócios fundadores da Fabrika Hitz, que acumula mais de 4 bilhões de plays em suas composições.',
    ],
    composicoes: [
      {
        musica: 'Desbloqueado – Ao Vivo',
        artistas: 'Diego & Victor Hugo',
        cover: 'desbloqueado.jpeg',
      },
      {
        musica: 'Para de Me Chamar',
        artistas: 'Marília Mendonça, Maiara & Maraisa',
        cover: 'para-de.jpeg',
      },
      {
        musica: 'Facilita – Ao Vivo',
        artistas: 'Luan Santana',
        cover: 'facilita.jpeg',
      },
      {
        musica: 'Cidade Vizinha – Ao Vivo',
        artistas: 'Henrique & Juliano',
        cover: 'cidade-visinha.jpeg',
      },
      {
        musica: '3 Batidas (Ao Vivo)',
        artistas: 'Guilherme & Benuto',
        cover: '3batidas.jpeg',
      },
    ],
  },
  {
    nome: 'Nando Marx',
    biografia: [
      'Nando Marx, cantor e compositor natural de Igarapava, São Paulo, iniciou sua carreira aos 18 anos, tocando em casas de shows, rádios, bandas regionais e abrindo shows de artistas como Bruno & Marrone e Zezé Di Camargo & Luciano.',
      'Seu dom na composição foi logo reconhecido, e em pouco tempo de carreira assinou com a Som Livre, uma das maiores gravadoras do país. Entre seus sucessos estão “Trégua (Eu Quero Trégua)” (Bruno & Marrone), “Para de Me Chamar Pra Trair” (Marília Mendonça e Maiara & Maraisa) e “Raiva” (Israel & Rodolffo).',
      'É um dos sócios fundadores da Fabrika Hitz, a primeira casa de composições do Brasil que acumula mais de 4 bilhões de plays em suas composições.',
    ],
    composicoes: [
      {
        musica: 'Trégua (Eu Quero Trégua)...',
        artistas: 'Bruno & Marrone',
        cover: 'tregua.jpeg',
      },
      {
        musica: 'Para de Me Chamar',
        artistas: 'Marília Mendonça, Maiara & Maraisa',
        cover: 'para-de.jpeg',
      },
      {
        musica: 'Raiva – Ao Vivo',
        artistas: 'Israel & Rodolffo',
        cover: 'raiva.jpeg',
      },
      {
        musica: 'Facas – Ao Vivo',
        artistas: 'Diego & Victor Hugo, Bruno & Marrone',
        cover: 'facas.jpeg',
      },
      {
        musica: 'Ela e Ela',
        artistas: 'Zé Neto & Cristiano',
        cover: 'ela-e-ela.jpeg',
      },
    ],
  },
];
