<div class="mzic-table-sort" (click)="toggleSort()">
  <ng-container *ngIf="sort === 'default'">
    <button class="arrow up"></button>
    <button class="arrow down"></button>
  </ng-container>

  <ng-container *ngIf="sort === 'asc'">
    <button class="arrow up"></button>
  </ng-container>

  <ng-container *ngIf="sort === 'desc'">
    <button class="arrow down"></button>
  </ng-container>
</div>
