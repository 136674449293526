/**
 * Ensure given function can be called only once.
 * Source: https://stackoverflow.com/a/58084026/6279385
 *
 * @param fn
 */
export function once(fn) {
    let done = false;
    return function (...args) {
        if (done) {
            return void 0;
        }
        done = true;
        return fn.apply(this, args);
    };
}
