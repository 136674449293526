import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import { LanguageResourceData, ResponseInterface } from '@mzic/mzic-interfaces';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private apiUrl = environment.apiUrl;

  // TODO: need to improve when more langs needed
  languageState = signal(
    this.getBrowserLanguage().startsWith('pt') ? 'pt-br' : 'en',
  );

  constructor(private readonly _http: HttpClient) {}

  getMusicLanguages(): Observable<ResponseInterface<LanguageResourceData>> {
    return this._http.get<ResponseInterface<LanguageResourceData>>(
      `${this.apiUrl}/api/distribution/language/v2/find?size=200`,
    );
  }

  getBrowserLanguage(): string {
    const language =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
    return language.toLowerCase();
  }
}
