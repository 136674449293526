<div data-cy="paginationCounter" class="pagination-counter">
  @if (current > 1) {
    <mzic-svg
      class="pagination-counter__icon w8 neutral-300"
      src="assets/icons/mzic-arrow-left.svg" />
  }
  <div class="pagination-counter__wrapper">
    <span
      data-cy="paginationCounterCurrent"
      class="pagination-counter__current">
      {{ current }}
    </span>
    <span class="pagination-counter__separator">/</span>
    <span data-cy="paginationCounterTotal" class="pagination-counter__total">
      {{ total }}
    </span>
  </div>
  @if (current < total) {
    <mzic-svg
      class="pagination-counter__icon w8"
      [ngClass]="{ 'purple-500': current === 1, 'neutral-300': current > 1 }"
      src="assets/icons/mzic-arrow-right.svg" />
  }
</div>
