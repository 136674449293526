export const getContainerCss = ({ uid }) => `
    #${uid} iframe.${uid}_prerender-frame {
        display: inline-block;
        position: absolute;

        /* loader frame size hardcoded intentionally*/
        width: 150px;
        height: 300px;

        /* center iframe inside parent relative */
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    #${uid} iframe {
        opacity: 0;
        transition: opacity .2s ease-in-out;
    }

    #${uid} iframe.${uid}_invisible {
        opacity: 0;
        z-index: -1;
    }

    #${uid} iframe.${uid}_visible {
        opacity: 1;
    }
`;
export const getLoaderCSS = () => `
    body {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
    }

    /* Active Animation */
    @-webkit-keyframes loader {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @keyframes loader {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }


    .ui.loader.active,
    .ui.loader.visible {
      display: block;
    }
    .ui.loader {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      text-align: center;
      z-index: 1000;
      transform: translateX(-50%) translateY(-50%);
    }

    .ui.loader:before {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      border-radius: 500rem;
      border: .2em solid rgba(0,0,0,.1);
    }

    .ui.loader:before {
      border: 0;
    }

    .ui.loader:after {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      animation: loader .6s linear;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: #e25468 transparent transparent;
      border-style: solid;
      border-width: .2em;
      box-shadow: 0 0 0 1px transparent;
    }


    .ui.inverted.dimmer .ui.loader,
    .ui.loader {
      width: 2.28571429rem;
      height: 2.28571429rem;
      font-size: 1em;
    }

    .ui.inverted.dimmer .ui.big.loader,
    .ui.big.loader {
      width: 3.71428571rem;
      height: 3.71428571rem;
      font-size: 1.28571429em;
    }

    .ui.loader:after,
    .ui.loader:before {
      width: 2.28571429rem;
      height: 2.28571429rem;
      margin: 0 0 0 -1.14285714rem;
    }

    .ui.big.loader:after,
    .ui.big.loader:before {
      width: 3.71428571rem;
      height: 3.71428571rem;
      margin: 0 0 0 -1.85714286rem;
    }
`;
