import {
  Directive,
  ElementRef,
  OnInit,
  Renderer2,
  DoCheck,
} from '@angular/core';

@Directive({
  selector: '[mzicCheckbox]',
  standalone: true,
})
export class MzicCheckboxDirective implements OnInit, DoCheck {
  private checkbox!: HTMLInputElement;
  private fakeCheckboxElement!: HTMLSpanElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.init();
  }

  ngDoCheck(): void {
    this.updateCheckboxState();
  }

  private init() {
    // Cria elementos span para o wrapper e o fake checkbox
    const parentElement = this.renderer.createElement('span');
    this.fakeCheckboxElement = this.renderer.createElement('span');

    // Adiciona classes para estilização
    this.renderer.addClass(parentElement, 'mzic-checkbox');
    this.renderer.addClass(this.fakeCheckboxElement, 'fake-checkbox');

    // Obtém o checkbox original
    this.checkbox = this.el.nativeElement;
    const parent = this.checkbox.parentNode;

    // Insere a nova span (wrapper) no DOM antes do checkbox
    this.renderer.insertBefore(parent, parentElement, this.checkbox);

    // Move o checkbox original e o fake checkbox para dentro do wrapper
    this.renderer.appendChild(parentElement, this.checkbox);
    this.renderer.appendChild(parentElement, this.fakeCheckboxElement);

    // Copia os estilos do checkbox original para o fake checkbox
    this.copyStyles(this.checkbox, this.fakeCheckboxElement);

    // Inicializa o estado da classe active
    this.updateCheckboxState();
  }

  private updateCheckboxState() {
    if (this.checkbox.checked) {
      this.renderer.addClass(this.fakeCheckboxElement, 'checked');
    } else {
      this.renderer.removeClass(this.fakeCheckboxElement, 'checked');
    }
  }

  private copyStyles(sourceElement: HTMLElement, targetElement: HTMLElement) {
    const computedStyles = window.getComputedStyle(sourceElement);
    const properties = [
      'margin-top',
      'margin-right',
      'margin-bottom',
      'margin-left',
      'margin',
    ];

    properties.forEach((styleName) => {
      const styleValue = computedStyles.getPropertyValue(styleName);
      if (styleValue !== '0px') {
        this.renderer.setStyle(targetElement, styleName, styleValue);
        this.renderer.setStyle(sourceElement, styleName, 'inherit');
      }
    });
  }
}
