import { Pipe, PipeTransform } from '@angular/core';
import { SelectOptionData } from '@mzic/mzic-interfaces';

@Pipe({
  name: 'extractTitle',
  standalone: true,
})
export class ExtractTitlePipe implements PipeTransform {
  transform(options: SelectOptionData[], placeholder: string): string {
    if (options.length === 0) {
      return placeholder;
    }

    return options.map((option) => option.title).join(', ');
  }
}
