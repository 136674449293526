import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { MzicSvgComponent } from '../mzic-svg/mzic-svg.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AutofocusDirective } from './directives/autofocus/autofocus.directive';

@Component({
  selector: 'mzic-select-search',
  standalone: true,
  templateUrl: './select-search.component.html',
  styleUrl: './select-search.component.scss',
  imports: [
    CommonModule,
    FormsModule,
    MzicSvgComponent,
    AutofocusDirective,
    SpinnerComponent,
  ],
})
export class SelectSearchComponent {
  @Input() loading = false;
  @Input() debounceTime = 550;
  @Output() searchTextChange = new EventEmitter<string>();
  @Output() clearTextChange = new EventEmitter<boolean>();

  searchSubject = new BehaviorSubject<string>('');
  searchTerm = '';
  focused = signal(false);

  constructor() {
    this.searchSubject
      .pipe(debounceTime(this.debounceTime))
      .subscribe((searchTerm) => {
        this.searchTextChange.emit(searchTerm);
      });
  }

  toggleFocus() {
    this.focused.set(this.focused() ? false : true);
  }

  onInputChange(searchTem: string) {
    this.searchSubject.next(searchTem);
  }

  resetSearch() {
    this.searchTextChange.emit('');
    this.clearTextChange.emit(true);
    this.searchTerm = '';
  }
}
