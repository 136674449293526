import { RevenueType } from './revenue-type.interface';

export interface RevenueTypeResponse {
  timestamp: string;
  status: string;
  data: RevenueType;
}

export function createEmptyRevenueType(): RevenueType {
  return {
    additionalProp1: '',
    additionalProp2: '',
    additionalProp3: '',
  };
}
