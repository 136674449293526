import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TOOLTIP_DATA } from '@mzic/mzic-utils';
import { MzicTooltipContentComponent } from './mzic-tooltip-content.component';

@Injectable({
  providedIn: 'root',
})
export class MzicTooltipService {
  constructor(private overlay: Overlay) {}

  open(
    target: HTMLElement,
    content: SafeHtml | string,
    openEvent: 'click' | 'hover',
  ): OverlayRef {
    const targetWidth = target.getBoundingClientRect().width;
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(target)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    const overlayRef = this.overlay.create({
      hasBackdrop: openEvent === 'click' ? true : false,
      positionStrategy: positionStrategy,
    });

    const injector = Injector.create({
      providers: [{ provide: TOOLTIP_DATA, useValue: { content } }],
    });

    const tooltipPortal = new ComponentPortal(
      MzicTooltipContentComponent,
      null,
      injector,
    );
    overlayRef.attach(tooltipPortal);

    // Aguardar até que o tooltip seja renderizado para calcular sua largura
    setTimeout(() => {
      if (overlayRef && overlayRef.overlayElement) {
        const tooltipElement = overlayRef.overlayElement.querySelector(
          '.style-tooltip',
        ) as HTMLElement;
        if (tooltipElement) {
          const bounding = tooltipElement.getBoundingClientRect();
          const tooltipWidth = bounding.width;
          const tooltipHeight = bounding.height;
          const marginLeft = targetWidth / 2 - tooltipWidth / 2;

          const gap = 7;

          tooltipElement.style.marginLeft = `${marginLeft}px`;
          tooltipElement.style.opacity = '1';
          tooltipElement.style.marginTop = `-${tooltipHeight + gap}px`;
        }
      }
    }, 0);

    overlayRef.backdropClick().subscribe(() => close());

    const close = () => {
      if (overlayRef) {
        overlayRef.dispose();
      }
    };

    return overlayRef;
  }
}
