import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { MzicPopoverOptions } from '../model/mzic-popover-options.interface';

@Component({
  selector: 'mzic-popover-old',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mzic-popover-OLD.component.html',
  styleUrls: ['./mzic-popover-OLD.component.scss'],
})
export class MzicPopoverOLDComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('popover') private popover!: ElementRef;
  @ViewChild('container', { read: ViewContainerRef })
  container!: ViewContainerRef;

  @Input() options: MzicPopoverOptions = {};
  @Output() closeEvent = new EventEmitter();

  private resizeObservable$!: Observable<Event>;
  private resizeSubscription$!: Subscription;

  private targetSize = { width: '0px', height: '0px' };
  private eventPosition = {
    x: window.innerWidth / 2 + 'px',
    y: window.innerHeight / 2 + 'px',
  };

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.options.closeOnClickOutside !== false) {
      this.options.closeOnClickOutside = true;
    }

    if (this.options.showOutside !== false) {
      this.options.showOutside = true;
    }

    this.options.target = this.getCorrectTarget();

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.setStyle();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.setStyle(), 10);
  }

  ngOnDestroy(): void {
    this.resizeSubscription$?.unsubscribe();
  }

  getCorrectTarget() {
    let target = this.options.target;
    if (!target) {
      return this.options.target;
    }

    const ignoreTags = ['path', 'svg'];
    while (ignoreTags.includes(target.tagName.toLowerCase().trim())) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
      target = target?.parentElement!;
    }

    const nextParent = target.parentElement;
    const tagName = nextParent?.tagName?.toLowerCase().trim() || '';
    if (nextParent && (tagName === 'button' || tagName === 'a')) {
      target = nextParent;
    }

    const positions = ['fixed', 'absolute', 'relative'];
    if (!positions.includes(target.style.position)) {
      target.style.position = 'relative';
    }
    return target;
  }

  setStyle() {
    if (this.options?.target) {
      this.targetSize = {
        width: this.options?.target.offsetWidth + 'px',
        height: this.options?.target.offsetHeight + 'px',
      };

      const rect = this.options.target.getBoundingClientRect();
      if (rect.x && rect.y) {
        this.eventPosition = {
          x: `${rect.x}px`,
          y: `${rect.y}px`,
        };
      }
    }

    const style = this.getPositionStyle();
    this.popover.nativeElement.style.left = style.x;
    this.popover.nativeElement.style.top = style.y;
    this.popover.nativeElement.style.transform = `translate(${style.translate})`;
    this.popover.nativeElement.style.opacity = '1';

    if (this.options?.minWidth) {
      this.popover.nativeElement.style.minWidth = this.options.minWidth;
    }
    if (this.options?.maxWidth) {
      this.popover.nativeElement.style.maxWidth = this.options.maxWidth;
    }
    if (this.options?.minHeight) {
      this.popover.nativeElement.style.minHeight = this.options?.minHeight;
    }
    if (this.options?.maxHeight) {
      this.popover.nativeElement.style.maxHeight = this.options?.maxHeight;
    }
  }

  getPositionStyle(): { x: string; y: string; translate: string } {
    const style = { x: '50%', y: '50%', translate: '-50%,-50%' };
    const component =
      this.popover.nativeElement.getBoundingClientRect() as DOMRect;

    if (this.options.position === 'topLeft') {
      style.x = this.eventPosition.x;
      style.y = this.eventPosition.y;
      style.translate = '-100%,-100%';
    }

    if (this.options.position === 'topCenter') {
      if (parseInt(this.targetSize.width) > component.width) {
        style.x =
          parseInt(this.eventPosition.x) +
          (parseInt(this.targetSize.width) - component.width) / 2 +
          'px';
      } else {
        style.x =
          parseInt(this.eventPosition.x) -
          (component.width - parseInt(this.targetSize.width)) / 2 +
          'px';
      }

      style.y = this.eventPosition.y;
      style.translate = '0,-100%';
    }

    if (this.options.position === 'topRight') {
      style.x =
        parseInt(this.eventPosition.x) + parseInt(this.targetSize.width) + 'px';
      style.y = this.eventPosition.y;
      style.translate = '0,-100%';
    }

    if (this.options.position === 'center') {
      style.x = this.eventPosition.x;
      style.y = this.eventPosition.y;
      style.translate = '-50%,-50%';
    }

    if (this.options.position === 'bottomLeft') {
      style.x = this.eventPosition.x;
      style.y =
        parseInt(this.eventPosition.y) +
        parseInt(this.targetSize.height) +
        'px';
      style.translate = '-95%,0';
    }

    if (this.options.position === 'bottomCenter') {
      style.x = this.eventPosition.x;
      style.y =
        parseInt(this.eventPosition.y) +
        parseInt(this.targetSize.height) +
        'px';
      style.translate = '-50%,0';
    }

    if (this.options.position === 'bottomRight') {
      style.x =
        parseInt(this.eventPosition.x) + parseInt(this.targetSize.width) + 'px';
      style.y =
        parseInt(this.eventPosition.y) +
        parseInt(this.targetSize.height) +
        'px';
      style.translate = '0,0';
    }

    style.y = parseInt(style.y) + window.scrollY + 'px';
    style.x = parseInt(style.x) + window.scrollX + 'px';

    if (this.options.position === 'center' && this.options.fixed) {
      style.x = '50%';
      style.y = '50%';
    }

    if (this.options.position === 'inherit') {
      style.x = 'inherit';
      style.y = 'inherit';
      style.translate = '0,0';
    }

    if (this.options.gapX) {
      style.x = parseInt(style.x) + this.options.gapX + 'px';
    }
    if (this.options.gapY) {
      style.y = parseInt(style.y) + this.options.gapY + 'px';
    }

    // console.log(`FINAL X: ${style.x}, Y: ${style.y}`);
    // console.log(
    //   `EVENT TRIGGER X: ${this.eventPosition.x}, Y: ${this.eventPosition.y}`
    // );
    // console.log(
    //   `TARGET Width: ${this.targetSize.width}, Height: ${this.targetSize.height}`
    // );
    // console.log(`MODAL Width: ${component.width}, Height: ${component.height}`);

    return style;
  }

  close() {
    this.elementRef.nativeElement.remove();
    this.closeEvent.emit();
  }

  outsideClick() {
    if (this.options.closeOnClickOutside) {
      this.close();
    }
  }
}
