import { Pipe, PipeTransform } from '@angular/core';
import { format, isEqual } from 'date-fns';

@Pipe({
  name: 'dateIsEqual',
  standalone: true,
})
export class DateIsEqualPipe implements PipeTransform {
  transform(date: Date, dateToCompare: Date | null): boolean {
    if (dateToCompare) return format(date, 'dd/MM/yyyy') === format(dateToCompare, 'dd/MM/yyyy');

    return false;
  }
}
