<mzic-select
  [variation]="variation"
  [orientation]="orientation"
  [optionsSelected]="optionsSelected"
  [optionsLabel]="optionsLabel"
  [placeholder]="placeholder"
  [useLightStyle]="useLightStyle"
  [useSuccessStyle]="useSuccessStyle"
  [useErrorStyle]="useErrorStyle"
  [invalid]="invalid"
  [searchable]="searchable"
  [multiple]="multiple"
  [fixedContent]="fixedContent"
  [placeholder]="placeholder"
  [disabled]="disabled"
  (closedChange)="handleClosedChange($event)"
  (searchTextChange)="handleSearch($event)">
  <div content>
    @for (option of options; track option.id) {
      <mzic-option
        [id]="option.id"
        [value]="option.info.title"
        (optionChange)="handleOptionChange($event)">
        <section class="select-option-wrapper">
          <div class="select-option">
            <div class="select-option__main">
              <header>
                <div class="select-option__main-title">
                  <span [innerHTML]="option.info.title"></span>
                </div>
              </header>
            </div>
          </div>
        </section>
      </mzic-option>
    }
    @if (searched && filteredOptions.length === 0) {
      <div class="empty">No results found</div>
    }
  </div>
</mzic-select>
