import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SideFloatingMenuOption } from './side-floating-menu.interface';

@Component({
  selector: 'mzic-side-floating-menu',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './side-floating-menu.component.html',
  styleUrl: './side-floating-menu.component.scss',
})
export class SideFloatingMenuComponent implements OnInit {
  @Input({ required: true }) options: SideFloatingMenuOption[] = [];
  @Input() sectionClassName = 'block-section';
  @Input() hideBreakpoint = 1400;

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.manageScrollActive();
  }

  ngOnInit(): void {
    this.manageScrollActive();
    setTimeout(this.manageScrollActive, 500);
  }

  manageScrollActive() {
    const sections = document.querySelectorAll(
      `.${this.sectionClassName}`,
    ) as NodeListOf<HTMLElement>;
    const menuLinks = document.querySelectorAll(
      '.side-floating-menu ul li',
    ) as NodeListOf<HTMLElement>;

    const threshold = 18;
    const scrollPosition = window.scrollY + window.innerHeight / 2 - threshold;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionBottom = sectionTop + section.offsetHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        menuLinks.forEach((link) => {
          link.classList.remove('active');
          const id = '#' + link.dataset['scrollId'];
          if (id && id.substring(1) === section.id) {
            link.classList.add('active');
          }
        });
      }
    });
  }
}
