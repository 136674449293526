<ng-container *transloco="let t">
  @if (!selectedOption().uuid.length) {
    <div
      class="mzic-select-spotify"
      [class.error]="error || artistNotFound"
      [class.mzic-select-spotify--responsive]="responsive">
      <img
        class="mzic-select-spotify__icon"
        alt="MZIC spotify icon"
        priority="high"
        width="24"
        height="24"
        [ngSrc]="'assets/icons/mzic-spotify-icon.svg'" />

      <span class="mzic-select-spotify__divisor"></span>
      <span
        [ngClass]="{
          'mzic-select-spotify__search-icon': !this.changeIcon(),
          'mzic-select-spotify__search-icon--focus': this.changeIcon(),
        }"></span>

      <input
        class="mzic-select-spotify__input"
        type="text"
        data-cy="search-spotify-input"
        [placeholder]="t('lang.placeholder')"
        [value]="searchValue()"
        (input)="onInputChange($event)"
        (click)="onInputChange($event)" />

      @if (searchValue().length) {
        <img
          data-cy="search-spotify-clear-search-icon"
          class="mzic-select-spotify__clear-search-icon"
          alt="MZIC clear search icon"
          priority="high"
          width="20"
          height="20"
          [ngSrc]="'assets/icons/mzic-clear-search-icon.svg'"
          (click)="onClearInput()" />
      }

      <div
        class="mzic-select-spotify__options"
        [ngClass]="values.length && searchValue().length ? 'visible' : ''"
        [mzicScrollingPagination]="20"
        (scrollingEvent)="onScrolling($event)">
        @for (value of values; track value.uuid) {
          <mzic-option-md-mekari
            data-cy="search-spotify-option"
            [optionData]="value"
            (mousedown)="onSelectedOption(value)" />
        }
      </div>
    </div>
  } @else {
    <mzic-search-spotify-option-selected
      [optionData]="selectedOption()"
      [loading]="loading"
      [responsive]="responsive" />
  }

  @if (artistNotFound || error) {
    <div
      data-cy="search-spotify-error-message"
      class="form-hint-error form-hint--with-space">
      @if (artistNotFound) {
        No results found.
      } @else {
        Something went wrong, please try again later.
      }
    </div>
  }
</ng-container>
