export interface WalletTeamData {
  id: number;
  walletId: number;
  artistId: number;
  member: WalletTeamMember;
  team: WalletTeam;
  role: WalletTeamRole;
  active: boolean;
}

export interface WalletTeamMember {
  name: string;
  email: string;
  photo: string;
}

export interface WalletTeam {
  id: number;
  artistId: number;
  name: string;
  partnerUserId?: number;
  enterpriseId?: number;
  registryType: RegistryTypeEnum;
  hash?: string;
  artistIsVerified: boolean;
  artistUuidViberate: string;
}

export interface WalletTeamRole {
  id: number;
  name: string;
}

export interface WalletTeamResponse {
  timestamp: string;
  status: string;
  data: WalletTeamData[];
}

export enum RegistryTypeEnum {
  ARTIST = 'Artist',
  LABEL = 'Label',
  PARTNER = 'Partner',
}
