<div
  class="mzic-selected-option"
  [class.mzic-selected-option--responsive]="responsive">
  <mzic-search-spotify-avatar
    altImage="MZIC artist thumbnail"
    [imageUri]="optionData.imageUri" />
  <span class="mzic-selected-option__text">
    {{ optionData.name }}
  </span>

  @if (loading) {
    <mat-spinner diameter="25" />
  }
</div>
