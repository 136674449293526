import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'mzic-loading-percent',
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: './loading-percent.component.html',
  styleUrl: './loading-percent.component.scss',
})
export class LoadingPercentComponent implements OnInit, OnDestroy {
  private intervalId: any;

  @Input() percent = 0;
  @Input() automatic = true;

  ngOnInit(): void {
    if (this.automatic) {
      this.startLoadingEffect();
    }
  }

  start() {
    this.startLoadingEffect();
  }

  startLoadingEffect(): void {
    const duration = 5000; // Total duration of 5 seconds
    const interval = 50; // Update every 50ms
    const step = 100 / (duration / interval);

    this.intervalId = setInterval(() => {
      this.percent += step;
      if (this.percent >= 100) {
        this.percent = 100;
        clearInterval(this.intervalId);
      }
    }, interval);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
