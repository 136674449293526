import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  GetUserProfile,
  PostUserImagesUpload,
  PostUserImagesUploadBody,
  PutUserUpdateProfile,
  PutUserUpdateProfileBody,
} from '@mzic/mzic-interfaces';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = `${environment.apiUrl}/api/user/user/v3`;

  constructor(private readonly _http: HttpClient) {}

  getUserProfile() {
    return this._http
      .get<GetUserProfile>(`${this.apiUrl}/profile`)
      .pipe(map((response) => response.data));
  }

  putUserUpdateProfile(body: PutUserUpdateProfileBody) {
    return this._http.put<PutUserUpdateProfile>(`${this.apiUrl}/update`, body);
  }

  postUserImagesUpload(params: PostUserImagesUploadBody, data: FormData) {
    return this._http.post<PostUserImagesUpload>(
      `${this.apiUrl}/images/upload?userPhotoType=${params.userPhotoType}`,
      data,
    );
  }
}
