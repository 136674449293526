export interface Interpreter {
  name: string;
  social: {
    title: string;
    accountLink: string;
    accountName: string;
    blocks: {
      label: string;
      value: string;
    }[];
  }[];
  musics: {
    name: string;
    artist: string;
    cover: string;
    social: {
      value: string;
      label: string;
    }[];
  }[];
}

export const interpretersData = [
  {
    name: 'Guilherme & Benuto',
    social: [
      {
        title: 'Spotify',
        accountLink: '',
        accountName: 'Guilherme & Benuto',
        blocks: [
          {
            label: 'Seguidores',
            value: '1.3M',
          },
          {
            label: 'Ouvintes mensais',
            value: '9.2M',
          },
        ],
      },
      {
        title: 'Youtube',
        accountLink: '',
        accountName: '@GuilhermeBenuto',
        blocks: [
          {
            label: 'Inscritos',
            value: '2.5M',
          },
          {
            label: 'Visualizações',
            value: '2.7B',
          },
        ],
      },
      {
        title: 'Instagram',
        accountLink: '',
        accountName: '@guilhermeebenuto',
        blocks: [
          {
            label: 'Seguidores',
            value: '1.9M',
          },
          {
            label: 'Engajamento',
            value: '0.5%',
          },
        ],
      },
      {
        title: 'TikTok',
        accountLink: '',
        accountName: '@guilhermeebenuto',
        blocks: [
          {
            label: 'Seguidores',
            value: '1.1M',
          },
          {
            label: 'Curtidas',
            value: '8M',
          },
        ],
      },
    ],
    musics: [
      {
        name: 'Haja Colírio – Ao Vivo',
        artist: 'Guilherme & Benuto, Hugo & Guilherme',
        cover: 'haja-colirio.jpeg',
        social: [
          {
            value: '303.5M',
            label: 'Streams',
          },
          {
            value: '324M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Duas Três',
        artist: 'Guilherme & Benuto, Hugo & Guilherme',
        cover: 'DuasTres.jpeg',
        social: [
          {
            value: '301.7M',
            label: 'Streams',
          },
          {
            value: '72.7M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Assunto Delicado',
        artist: 'Guilherme & Benuto',
        cover: 'ASsuntoDelicado.jpeg',
        social: [
          {
            value: '214.5M',
            label: 'Streams',
          },
          {
            value: '140.3M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: '3 Batidas – Ao Vivo',
        artist: 'Guilherme & Benuto',
        cover: '3batidas.jpeg',
        social: [
          {
            value: '188M',
            label: 'Streams',
          },
          {
            value: '178M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Pulei na Piscina – Ao Vivo',
        artist: 'Guilherme & Benuto, DJ Lucas Beat',
        cover: 'puleiNaPiscina.jpeg',
        social: [
          {
            value: '170M',
            label: 'Streams',
          },
          {
            value: '248M',
            label: 'Visualizações',
          },
        ],
      },
    ],
  },
  {
    name: 'Simone Mendes',
    social: [
      {
        title: 'Spotify',
        accountLink: '',
        accountName: 'Simone Mendes',
        blocks: [
          {
            label: 'Seguidores',
            value: '2.3M',
          },
          {
            label: 'Ouvintes mensais',
            value: '10.4M',
          },
        ],
      },
      {
        title: 'Youtube',
        accountLink: '',
        accountName: '@simonemendesoficial',
        blocks: [
          {
            label: 'Inscritos',
            value: '4.8M',
          },
          {
            label: 'Visualizações',
            value: '1.66B',
          },
        ],
      },
      {
        title: 'Instagram',
        accountLink: '',
        accountName: '@simonemendes',
        blocks: [
          {
            label: 'Seguidores',
            value: '39.6M',
          },
          {
            label: 'Engajamento',
            value: '0.3%',
          },
        ],
      },
      {
        title: 'TikTok',
        accountLink: '',
        accountName: '@simonemendes',
        blocks: [
          {
            label: 'Seguidores',
            value: '16.6M',
          },
          {
            label: 'Curtidas',
            value: '94M',
          },
        ],
      },
    ],
    musics: [
      {
        name: 'Erro Gostoso – Ao Vivo',
        artist: 'Simone Mendes',
        cover: 'ErroGostoso.jpeg',
        social: [
          {
            value: '368.4M',
            label: 'Streams',
          },
          {
            value: '385M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Daqui pra Sempre',
        artist: 'MANU, Simone Mendes',
        cover: 'DaquiPraSempre.jpeg',
        social: [
          {
            value: '214.9M',
            label: 'Streams',
          },
          {
            value: '412M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Dois Tristes – Ao Vivo',
        artist: 'Simone Mendes',
        cover: 'DoisTristes.jpeg',
        social: [
          {
            value: '138.8M',
            label: 'Streams',
          },
          {
            value: '174M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Oceano – Ao Vivo',
        artist: 'Felipe & Rodrigo, Simone Mendes',
        cover: 'Oceano.jpeg',
        social: [
          {
            value: '64.4M',
            label: 'Streams',
          },
          {
            value: '25M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Mulher Foda – Ao Vivo',
        artist: 'Simone Mendes',
        cover: 'MulherFoda.jpeg',
        social: [
          {
            value: '61.3M',
            label: 'Streams',
          },
          {
            value: '6M',
            label: 'Visualizações',
          },
        ],
      },
    ],
  },
];
