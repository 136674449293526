import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@mzic/mzic-environments';
import {
  createEmptyRoyaltySplit,
  createEmptyRoyaltySplitDetail,
  createEmptyRoyaltySplitForm,
  createEmptyRoyaltySplitList,
  RoyaltySplit,
  RoyaltySplitCreateRequest,
  RoyaltySplitDetail,
  RoyaltySplitInvitationPending,
  RoyaltySplitList,
} from '@mzic/mzic-interfaces';
import { GlobalLoaderService } from '../../global-loader/global-loader.service';
import { MzicArtistLocalService } from '../../mzic-artist-local/mzic-artist-local.service';
import { RoyaltySplitsService } from '../royalty-splits.service';

import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { FeedbackState } from '../../feedback/state/feedback.state';
import { AssetsAvailableState } from './assets-available.state';

interface RoyaltySplitModel {
  isLoading: boolean;
  royaltySplit: RoyaltySplit;
  royaltySplitList: RoyaltySplitList;
  royaltySplitDetail: RoyaltySplitDetail;
  royaltySplitForm: RoyaltySplitCreateRequest;
  royaltySplitInvitationPending: RoyaltySplitInvitationPending[];
}

@Injectable({
  providedIn: 'root',
})
export class RoyaltySplitState {
  private royaltySplitsService = inject(RoyaltySplitsService);
  private feedbackState = inject(FeedbackState);
  private globalLoaderService = inject(GlobalLoaderService);
  private router = inject(Router);
  private mzicArtistLocalService = inject(MzicArtistLocalService);
  private assetsAvailableState = inject(AssetsAvailableState);

  private initialState = {
    isLoading: false,
    royaltySplit: createEmptyRoyaltySplit(),
    royaltySplitList: createEmptyRoyaltySplitList(),
    royaltySplitDetail: createEmptyRoyaltySplitDetail(),
    royaltySplitForm: createEmptyRoyaltySplitForm(),
    royaltySplitInvitationPending: [],
  };

  private state = signal<RoyaltySplitModel>(this.initialState);

  isLoadingSnapshot = computed(() => this.state().isLoading);
  royaltySplitSnapshot = computed(() => this.state().royaltySplit);
  royaltySplitDetailSnapshot = computed(() => this.state().royaltySplitDetail);
  royaltySplitFormSnapshot = computed(() => this.state().royaltySplitForm);
  royaltySplitInvitationPendingSnapshot = computed(
    () => this.state().royaltySplitInvitationPending,
  );

  private isLoading$ = new BehaviorSubject<boolean>(false);
  private royaltySplit$ = new BehaviorSubject<RoyaltySplit>({} as RoyaltySplit);
  private royaltySplitList$ = new BehaviorSubject<RoyaltySplitList>(
    {} as RoyaltySplitList,
  );
  private royaltySplitDetail$ = new BehaviorSubject<RoyaltySplitDetail>(
    {} as RoyaltySplitDetail,
  );
  private royaltySplitForm$ = new BehaviorSubject<RoyaltySplitCreateRequest>(
    {} as RoyaltySplitCreateRequest,
  );
  private royaltySplitInvitationPending$ = new BehaviorSubject<
    RoyaltySplitInvitationPending[]
  >([] as RoyaltySplitInvitationPending[]);

  get artistId(): number {
    return this.mzicArtistLocalService.getWalletTeam()['team'].artistId;
  }

  get isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  get royaltySplit(): Observable<RoyaltySplit> {
    return this.royaltySplit$.asObservable();
  }

  get royaltySplitList(): Observable<RoyaltySplitList> {
    return this.royaltySplitList$.asObservable();
  }

  get royaltySplitDetail(): Observable<RoyaltySplitDetail> {
    return this.royaltySplitDetail$.asObservable();
  }

  get royaltySplitInvitationPending(): Observable<
    RoyaltySplitInvitationPending[]
  > {
    return this.royaltySplitInvitationPending$.asObservable();
  }

  constructor() {
    effect(() => {
      if (!environment.production) {
        console.log('Royalty Split State', this.state());
      }
    });

    this.isLoading$.pipe(takeUntilDestroyed()).subscribe((isLoading) => {
      this.state.update((state) => ({
        ...state,
        isLoading,
      }));
    });

    this.royaltySplit$.pipe(takeUntilDestroyed()).subscribe((royaltySplit) => {
      this.state.update((state) => ({
        ...state,
        royaltySplit,
      }));
    });

    this.royaltySplitList$
      .pipe(takeUntilDestroyed())
      .subscribe((royaltySplitList) => {
        this.state.update((state) => ({
          ...state,
          royaltySplitList,
        }));
      });

    this.royaltySplitDetail$
      .pipe(takeUntilDestroyed())
      .subscribe((royaltySplitDetail) => {
        this.state.update((state) => ({
          ...state,
          royaltySplitDetail,
        }));
      });

    this.royaltySplitForm$
      .pipe(takeUntilDestroyed())
      .subscribe((royaltySplitForm) => {
        this.state.update((state) => ({
          ...state,
          royaltySplitForm,
        }));
      });

    this.royaltySplitInvitationPending$
      .pipe(takeUntilDestroyed())
      .subscribe((royaltySplitInvitationPending) => {
        this.state.update((state) => ({
          ...state,
          royaltySplitInvitationPending,
        }));
      });
  }

  loadRoyaltySplitList(
    created: boolean,
    searchTerm?: string,
    pageable?: {
      page: number;
      size: number;
      sort?: string;
    },
  ) {
    this.isLoading$.next(true);

    this.royaltySplitsService
      .getRoyaltySplitList({ created, searchTerm, pageable })
      .subscribe({
        next: (royaltySplitList) => {
          this.royaltySplitList$.next(royaltySplitList.data);
          this.isLoading$.next(false);
        },
        error: () => {
          this.isLoading$.next(false);
        },
      });
  }

  loadRoyaltySplitDetail(splitId: string) {
    this.isLoading$.next(true);

    this.royaltySplitsService.getRoyaltySplitDetail(splitId).subscribe({
      next: (royaltySplitDetail) => {
        // TODO: Fazer os patches
        this.royaltySplitDetail$.next(royaltySplitDetail.data);
        this.isLoading$.next(false);
        this.resetAssetsAvailable();
        this.setAssetsAvailable();
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }

  resetAssetsAvailable() {
    this.assetsAvailableState.toggleAllAssetsChecked(false);
  }

  setAssetsAvailable() {
    this.state().royaltySplitDetail.split.assets.map((asset) => {
      if (asset.track) {
        this.assetsAvailableState.toggleAssetChecked(asset.track.id, true);
      }

      if (asset.release) {
        this.assetsAvailableState.toggleAssetChecked(asset.release.id, true);
      }
    });
  }

  createRoyaltySplit(payload: RoyaltySplitCreateRequest) {
    this.royaltySplitForm$.next(payload);
  }

  confirmCreateRoyaltySplit(edit: boolean) {
    this.isLoading$.next(true);
    this.globalLoaderService.showLoader();

    if (edit) {
      this.royaltySplitsService
        .putRoyaltySplitUpdate(this.royaltySplitFormSnapshot())
        .subscribe({
          next: (royaltySplit) =>
            this.confirmCreateRoyaltySplitSuccess(royaltySplit.data),
          error: () => this.confirmCreateRoyaltySplitError(),
        });
      return;
    }

    this.royaltySplitsService
      .postRoyaltySplitCreate(this.royaltySplitFormSnapshot())
      .subscribe({
        next: (royaltySplit) =>
          this.confirmCreateRoyaltySplitSuccess(royaltySplit.data),
        error: () => this.confirmCreateRoyaltySplitError(),
      });
  }

  confirmCreateRoyaltySplitSuccess(royaltySplit: RoyaltySplit) {
    this.royaltySplit$.next(royaltySplit);
    this.isLoading$.next(false);

    this.globalLoaderService.showSuccess();

    setTimeout(() => {
      this.router.navigate([
        `/dashboard/${this.artistId}/royalty-splits/success`,
      ]);
      this.globalLoaderService.hide();
    }, 1500);

    this.feedbackState.setFeedback(
      'Royalty Split created successfully',
      'SUCCESS',
    );
  }

  confirmCreateRoyaltySplitError() {
    this.isLoading$.next(false);

    setTimeout(() => {
      this.globalLoaderService.hide();
    }, 1500);

    this.feedbackState.setFeedback('Failed to create royalty split', 'ERROR');
  }

  resendEmailInvitation(splitId: number, payeeId: number) {
    this.isLoading$.next(true);

    this.royaltySplitsService
      .postResendEmailInvitation(splitId, payeeId)
      .subscribe({
        next: () => {
          this.isLoading$.next(false);
          this.feedbackState.setFeedback('Email sent successfully', 'SUCCESS');
        },
        error: () => {
          this.isLoading$.next(false);
          this.feedbackState.setFeedback('Failed to send email', 'ERROR');
        },
      });
  }

  verifyInvitationPending() {
    this.isLoading$.next(true);

    this.royaltySplitsService.getVerifyInvitationPending().subscribe({
      next: (invitationPending) => {
        this.royaltySplitInvitationPending$.next(
          invitationPending.data.content,
        );
        this.isLoading$.next(false);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }

  confirmInvitation(splitId: number, payeeId: number) {
    this.isLoading$.next(true);

    this.royaltySplitsService
      .postConfirmInvitation(splitId, payeeId)
      .subscribe({
        next: () => {
          this.isLoading$.next(false);
          this.feedbackState.setFeedback(
            'Invitation confirmed successfully',
            'SUCCESS',
          );
          this.verifyInvitationPending();
        },
        error: () => {
          this.isLoading$.next(false);
          this.feedbackState.setFeedback(
            'Failed to confirm invitation',
            'ERROR',
          );
        },
      });
  }
}
