<ul class="switch__navbar__langs">
  <li>
    <button
      class="language-button"
      [class.language-button--active]="languageActive === 'en'"
      type="button"
      (click)="switchLanguage('en')">
      en
    </button>
  </li>
  <li class="arrow">
    <p [class.arrow--reverse]="languageActive === 'pt-br'">←</p>
  </li>
  <li>
    <button
      class="language-button"
      [class.language-button--active]="languageActive === 'pt-br'"
      type="button"
      (click)="switchLanguage('pt-br')">
      pt
    </button>
  </li>
</ul>
