import { PayeeType } from './payee-type.interface';

export interface PayeeTypeResponse {
  timestamp: string;
  status: string;
  data: {
    content: PayeeType[];
  };
}

export function createEmptyPayeeType(): PayeeType[] {
  return [
    {
      id: 0,
      roleId: 0,
      name: '',
      priority: 0,
    },
  ];
}
