import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'mzic-search-spotify-avatar',
  standalone: true,
  imports: [NgIf, NgStyle, NgClass, MatProgressSpinnerModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search-spotify-avatar.component.html',
  styleUrls: ['./search-spotify-avatar.component.scss'],
})
export class SearchSpotifyAvatarComponent {
  @Input({ required: true }) altImage!: string;
  @Input({ required: true }) imageUri!: string;

  protected isLoading = signal(true);

  onLoading() {
    this.isLoading.set(false);
  }
}
