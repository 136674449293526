export const CountriesInfoPreferred = [
  {
    country: 'Brazil',
    countryRU: 'Бразилия',
    internalPhoneCode: '+55',
    countryCode: 'BR',
    phoneMask: '+00 (00) 00000-0000',
    altMasks: ['+00 (00) 00000-0000'],
  },
  {
    country: 'United States',
    countryRU: 'США',
    internalPhoneCode: '+1',
    countryCode: 'US',
    phoneMask: '+0 (000) 000 0000',
  },
];

export const CountriesInfo = [
  {
    country: 'Afghanistan',
    countryRU: 'Афганистан',
    internalPhoneCode: '+93',
    countryCode: 'AF',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: 'Albania',
    countryRU: 'Албания',
    internalPhoneCode: '+355',
    countryCode: 'AL',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Algeria',
    countryRU: 'Алжир',
    internalPhoneCode: '+213',
    countryCode: 'DZ',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'American Samoa',
    countryRU: 'Американское Самоа',
    internalPhoneCode: '+1684',
    countryCode: 'AS',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Andorra',
    countryRU: 'Андорра',
    internalPhoneCode: '+376',
    countryCode: 'AD',
    phoneMask: '+000 000 000 0000',
  },
  {
    country: 'Angola',
    countryRU: 'Ангола',
    internalPhoneCode: '+244',
    countryCode: 'AO',
    phoneMask: '+000 0000 000 0000',
  },
  {
    country: 'Anguilla',
    countryRU: 'Ангилья',
    internalPhoneCode: '+1264',
    countryCode: 'AI',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Antigua and Barbuda',
    countryRU: 'Антигуа и Барбуда',
    internalPhoneCode: '+1268',
    countryCode: 'AG',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Argentina',
    countryRU: 'Аргентина',
    internalPhoneCode: '+54',
    countryCode: 'AR',
    phoneMask: '+00 0 000 0000',
  },
  {
    country: 'Armenia',
    countryRU: 'Армения',
    internalPhoneCode: '+374',
    countryCode: 'AM',
    phoneMask: '+000 000 000 00',
    altMasks: ['+000 000 000 0000'],
  },
  {
    country: 'Aruba',
    countryRU: 'Аруба',
    internalPhoneCode: '+297',
    countryCode: 'AW',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Australia',
    countryRU: 'Австралия',
    internalPhoneCode: '+61',
    countryCode: 'AU',
    phoneMask: '+00 0000 0000',
    altMasks: ['+00 0 0000 0000'],
  },
  {
    country: 'Austria',
    countryRU: 'Австрия',
    internalPhoneCode: '+43',
    countryCode: 'AT',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: 'Azerbaijan',
    countryRU: 'Азербайджан',
    internalPhoneCode: '+994',
    countryCode: 'AZ',
    phoneMask: '+000 000 000 0000',
  },
  {
    country: 'Bahamas',
    countryRU: 'Багамские острова',
    internalPhoneCode: '+1242',
    countryCode: 'BS',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Bahrain',
    countryRU: 'Бахрейн',
    internalPhoneCode: '+973',
    countryCode: 'BH',
    phoneMask: '+000 000 000 0000',
  },
  {
    country: 'Bangladesh',
    countryRU: 'Бангладеш',
    internalPhoneCode: '+880',
    countryCode: 'BD',
    phoneMask: '+000 000 000 0000',
  },
  {
    country: 'Barbados',
    countryRU: 'Барбадос',
    internalPhoneCode: '+1246',
    countryCode: 'BB',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Belarus',
    countryRU: 'Беларусь',
    internalPhoneCode: '+375',
    countryCode: 'BY',
    phoneMask: '+000 (00) 000-00-00',
  },
  {
    country: 'Belgium',
    countryRU: 'Бельгия',
    internalPhoneCode: '+32',
    countryCode: 'BE',
    phoneMask: '+00 000 00 00 00',
  },
  {
    country: 'Belize',
    countryRU: 'Белиз',
    internalPhoneCode: '+501',
    countryCode: 'BZ',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Benin',
    countryRU: 'Бенин',
    internalPhoneCode: '+229',
    countryCode: 'BJ',
    phoneMask: '+000 000 000 0000',
  },
  {
    country: 'Bermuda',
    countryRU: 'Бермуды',
    internalPhoneCode: '+1441',
    countryCode: 'BM',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Bhutan',
    countryRU: 'Бутан',
    internalPhoneCode: '+975',
    countryCode: 'BT',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Bosnia and Herzegovina',
    countryRU: 'Босния и Герцеговина',
    internalPhoneCode: '+387',
    countryCode: 'BA',
    phoneMask: '+000 000 000 0000',
  },
  {
    country: 'Botswana',
    countryRU: 'Ботсвана',
    internalPhoneCode: '+267',
    countryCode: 'BW',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Brazil',
    countryRU: 'Бразилия',
    internalPhoneCode: '+55',
    countryCode: 'BR',
    phoneMask: '+00 (00) 0000-0000',
    altMasks: ['+00 (00) 00000-0000'],
  },
  {
    country: 'British Indian Ocean Territory',
    countryRU: 'Британская территория в Индийском океане',
    internalPhoneCode: '+246',
    countryCode: 'IO',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Bulgaria',
    countryRU: 'Болгария',
    internalPhoneCode: '+359',
    countryCode: 'BG',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Burkina Faso',
    countryRU: 'Буркина-Фасо',
    internalPhoneCode: '+226',
    countryCode: 'BF',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Burundi',
    countryRU: 'Бурунди',
    internalPhoneCode: '+257',
    countryCode: 'BI',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Cambodia',
    countryRU: 'Камбоджа',
    internalPhoneCode: '+855',
    countryCode: 'KH',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Cameroon',
    countryRU: 'Камерун',
    internalPhoneCode: '+237',
    countryCode: 'CM',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'United States',
    countryRU: 'США',
    internalPhoneCode: '+1',
    countryCode: 'US',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Canada',
    countryRU: 'Канада',
    internalPhoneCode: '+1',
    countryCode: 'CA',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Cape Verde',
    countryRU: 'Кабо-Верде',
    internalPhoneCode: '+238',
    countryCode: 'CV',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Cayman Islands',
    countryRU: 'Каймановы острова',
    internalPhoneCode: '+345',
    countryCode: 'KY',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Central African Republic',
    countryRU: 'Центральноафриканская Республика',
    internalPhoneCode: '+236',
    countryCode: 'CF',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Chad',
    countryRU: 'Чад',
    internalPhoneCode: '+235',
    countryCode: 'TD',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Chile',
    countryRU: 'Чили',
    internalPhoneCode: '+56',
    countryCode: 'CL',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'China',
    countryRU: 'Китай',
    internalPhoneCode: '+86',
    countryCode: 'CN',
    phoneMask: '+00 000 0000 0000',
  },
  {
    country: 'Christmas Island',
    countryRU: 'Остров Рождества',
    internalPhoneCode: '+61',
    countryCode: 'CX',
    phoneMask: '+00 0 0000 0000',
  },
  {
    country: 'Colombia',
    countryRU: 'Колумбия',
    internalPhoneCode: '+57',
    countryCode: 'CO',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: 'Comoros',
    countryRU: 'Коморские острова',
    internalPhoneCode: '+269',
    countryCode: 'KM',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Congo',
    countryRU: 'Конго',
    internalPhoneCode: '+242',
    countryCode: 'CG',
    phoneMask: '+000 00 00 00000',
  },
  {
    country: 'Cook Islands',
    countryRU: 'Острова Кука',
    internalPhoneCode: '+682',
    countryCode: 'CK',
    phoneMask: '+682 00 000',
  },
  {
    country: 'Costa Rica',
    countryRU: 'Коста-Рика',
    internalPhoneCode: '+506',
    countryCode: 'CR',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Croatia',
    countryRU: 'Хорватия',
    internalPhoneCode: '+385',
    countryCode: 'HR',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Cuba',
    countryRU: 'Куба',
    internalPhoneCode: '+53',
    countryCode: 'CU',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: 'Cyprus',
    countryRU: 'Кипр',
    internalPhoneCode: '+357',
    countryCode: 'CY',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Czech Republic',
    countryRU: 'Чешская Республика',
    internalPhoneCode: '+420',
    countryCode: 'CZ',
    phoneMask: '+000 000 000 000',
  },
  {
    country: 'Denmark',
    countryRU: 'Дания',
    internalPhoneCode: '+45',
    countryCode: 'DK',
    phoneMask: '+00 00 00 00 00',
  },
  {
    country: 'Djibouti',
    countryRU: 'Джибути',
    internalPhoneCode: '+253',
    countryCode: 'DJ',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Dominica',
    countryRU: 'Доминика',
    internalPhoneCode: '+1767',
    countryCode: 'DM',
    phoneMask: '+0000 000 0000',
  },
  {
    country: 'Dominican Republic',
    countryRU: 'Доминиканская Республика',
    internalPhoneCode: '+1809',
    countryCode: 'DO',
    phoneMask: '+0000 000 0000',
  },
  {
    country: 'Ecuador',
    countryRU: 'Эквадор',
    internalPhoneCode: '+593',
    countryCode: 'EC',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Egypt',
    countryRU: 'Египет',
    internalPhoneCode: '+20',
    countryCode: 'EG',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: 'El Salvador',
    countryRU: 'Сальвадор',
    internalPhoneCode: '+503',
    countryCode: 'SV',
    phoneMask: '+000 00 0000 0000',
  },
  {
    country: 'Equatorial Guinea',
    countryRU: 'Экваториальная Гвинея',
    internalPhoneCode: '+240',
    countryCode: 'GQ',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Eritrea',
    countryRU: 'Эритрея',
    internalPhoneCode: '+291',
    countryCode: 'ER',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Estonia',
    countryRU: 'Эстония',
    internalPhoneCode: '+372',
    countryCode: 'EE',
    phoneMask: '+000 000 000',
    altMasks: ['+000 000 0000', '+000 0000 0000', '+000 000000000'],
  },
  {
    country: 'Ethiopia',
    countryRU: 'Эфиопия',
    internalPhoneCode: '+251',
    countryCode: 'ET',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Faroe Islands',
    countryRU: 'Фарерские острова',
    internalPhoneCode: '+298',
    countryCode: 'FO',
    phoneMask: '+000 000000',
  },
  {
    country: 'Fiji',
    countryRU: 'Фиджи',
    internalPhoneCode: '+679',
    countryCode: 'FJ',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Finland',
    countryRU: 'Финляндия',
    internalPhoneCode: '+358',
    countryCode: 'FI',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'France',
    countryRU: 'Франция',
    internalPhoneCode: '+33',
    countryCode: 'FR',
    phoneMask: '+00 0 00 00 00 00',
  },
  {
    country: 'French Guiana',
    countryRU: 'Французская Гвиана',
    internalPhoneCode: '+594',
    countryCode: 'GF',
    phoneMask: '+000 000 00 00 00',
  },
  {
    country: 'French Polynesia',
    countryRU: 'Французская Полинезия',
    internalPhoneCode: '+689',
    countryCode: 'PF',
    phoneMask: '+000 000000',
  },
  {
    country: 'Gabon',
    countryRU: 'Габон',
    internalPhoneCode: '+241',
    countryCode: 'GA',
    phoneMask: '+000 000000',
  },
  {
    country: 'Gambia',
    countryRU: 'Гамбия',
    internalPhoneCode: '+220',
    countryCode: 'GM',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Georgia',
    countryRU: 'Грузия',
    internalPhoneCode: '+995',
    countryCode: 'GE',
    phoneMask: '+000 000 000000',
  },
  {
    country: 'Germany',
    countryRU: 'Германия',
    internalPhoneCode: '+49',
    countryCode: 'DE',
    phoneMask: '+00 00 00000000',
    altMasks: ['+00 00 000000000'],
  },
  {
    country: 'Ghana',
    countryRU: 'Гана',
    internalPhoneCode: '+233',
    countryCode: 'GH',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Gibraltar',
    countryRU: 'Гибралтар',
    internalPhoneCode: '+350',
    countryCode: 'GI',
    phoneMask: '+000 00000',
  },
  {
    country: 'Greece',
    countryRU: 'Греция',
    internalPhoneCode: '+30',
    countryCode: 'GR',
    phoneMask: '+00 0 000 0000',
  },
  {
    country: 'Greenland',
    countryRU: 'Гренландия',
    internalPhoneCode: '+299',
    countryCode: 'GL',
    phoneMask: '+000 000000',
  },
  {
    country: 'Grenada',
    countryRU: 'Гренада',
    internalPhoneCode: '+1473',
    countryCode: 'GD',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Guadeloupe',
    countryRU: 'Гваделупа',
    internalPhoneCode: '+590',
    countryCode: 'GP',
    phoneMask: '+000 000 00 00 00',
  },
  {
    country: 'Guam',
    countryRU: 'Гуам',
    internalPhoneCode: '+1671',
    countryCode: 'GU',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Guatemala',
    countryRU: 'Гватемала',
    internalPhoneCode: '+502',
    countryCode: 'GT',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Guinea',
    countryRU: 'Гвинея',
    internalPhoneCode: '+224',
    countryCode: 'GN',
    phoneMask: '+000 000 000000',
  },
  {
    country: 'Guinea-Bissau',
    countryRU: 'Гвинея-Бисау',
    internalPhoneCode: '+245',
    countryCode: 'GW',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Guyana',
    countryRU: 'Гайана',
    internalPhoneCode: '+592',
    countryCode: 'GY',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Haiti',
    countryRU: 'Гаити',
    internalPhoneCode: '+509',
    countryCode: 'HT',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Honduras',
    countryRU: 'Гондурас',
    internalPhoneCode: '+504',
    countryCode: 'HN',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Hungary',
    countryRU: 'Венгрия',
    internalPhoneCode: '+36',
    countryCode: 'HU',
    phoneMask: '+00 0 000 0000',
    altMasks: ['+00 00 000 0000'],
  },
  {
    country: 'Hungary (Alternative)',
    countryRU: 'Венгрия (альтернатива)',
    internalPhoneCode: '+06',
    countryCode: 'HU',
    phoneMask: '+00 0 000 0000',
    altMasks: ['+00 00 000 0000'],
  },
  {
    country: 'Iceland',
    countryRU: 'Исландия',
    internalPhoneCode: '+354',
    countryCode: 'IS',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'India',
    countryRU: 'Индия',
    internalPhoneCode: '+91',
    countryCode: 'IN',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: 'Indonesia',
    countryRU: 'Индонезия',
    internalPhoneCode: '+62',
    countryCode: 'ID',
    phoneMask: '+00 00 0000 0000',
  },
  {
    country: 'Iraq',
    countryRU: 'Ирак',
    internalPhoneCode: '+964',
    countryCode: 'IQ',
    phoneMask: '+000 (00) 000 00000',
  },
  {
    country: 'Ireland',
    countryRU: 'Ирландия',
    internalPhoneCode: '+353',
    countryCode: 'IE',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Israel',
    countryRU: 'Израиль',
    internalPhoneCode: '+972',
    countryCode: 'IL',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Italy',
    countryRU: 'Италия',
    internalPhoneCode: '+39',
    countryCode: 'IT',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'Jamaica',
    countryRU: 'Ямайка',
    internalPhoneCode: '+1876',
    countryCode: 'JM',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Japan',
    countryRU: 'Япония',
    internalPhoneCode: '+81',
    countryCode: 'JP',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'Jordan',
    countryRU: 'Джордан',
    internalPhoneCode: '+962',
    countryCode: 'JO',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Kazakhstan',
    countryRU: 'Казахстан',
    internalPhoneCode: '+77',
    phoneCode: '7',
    countryCode: 'KZ',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Kenya',
    countryRU: 'Кения',
    internalPhoneCode: '+254',
    countryCode: 'KE',
    phoneMask: '+000 000 000000',
  },
  {
    country: 'Kiribati',
    countryRU: 'Кирибати',
    internalPhoneCode: '+686',
    countryCode: 'KI',
    phoneMask: '+000 00000',
  },
  {
    country: 'Kuwait',
    countryRU: 'Кувейт',
    internalPhoneCode: '+965',
    countryCode: 'KW',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Kyrgyzstan',
    countryRU: 'Кыргызстан',
    internalPhoneCode: '+996',
    countryCode: 'KG',
    phoneMask: '+000 000 000000',
  },
  {
    country: 'Latvia',
    countryRU: 'Латвия',
    internalPhoneCode: '+371',
    countryCode: 'LV',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Lebanon',
    countryRU: 'Ливан',
    internalPhoneCode: '+961',
    countryCode: 'LB',
    phoneMask: '+000 00 000 000',
  },
  {
    country: 'Lesotho',
    countryRU: 'Лесото',
    internalPhoneCode: '+266',
    countryCode: 'LS',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Liberia',
    countryRU: 'Либерия',
    internalPhoneCode: '+231',
    countryCode: 'LR',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Liechtenstein',
    countryRU: 'Лихтенштейн',
    internalPhoneCode: '+423',
    countryCode: 'LI',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Lithuania',
    countryRU: 'Литва',
    internalPhoneCode: '+370',
    countryCode: 'LT',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Luxembourg',
    countryRU: 'Люксембург',
    internalPhoneCode: '+352',
    countryCode: 'LU',
    phoneMask: '+000 000000',
  },
  {
    country: 'Madagascar',
    countryRU: 'Мадакаскар',
    internalPhoneCode: '+261',
    countryCode: 'MG',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Malawi',
    countryRU: 'Малави',
    internalPhoneCode: '+265',
    countryCode: 'MW',
    phoneMask: '+000 000000000',
  },
  {
    country: 'Malaysia',
    countryRU: 'Малазия',
    internalPhoneCode: '+60',
    countryCode: 'MY',
    phoneMask: '+00 0 000 0000',
  },
  {
    country: 'Maldives',
    countryRU: 'Мальдивы',
    internalPhoneCode: '+960',
    countryCode: 'MV',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Mali',
    countryRU: 'Мали',
    internalPhoneCode: '+223',
    countryCode: 'ML',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Malta',
    countryRU: 'Мальта',
    internalPhoneCode: '+356',
    countryCode: 'MT',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Marshall Islands',
    countryRU: 'Маршаловы острова',
    internalPhoneCode: '+692',
    countryCode: 'MH',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Martinique',
    countryRU: 'Мартиника',
    internalPhoneCode: '+596',
    countryCode: 'MQ',
    phoneMask: '+000 000 00 00 00',
  },
  {
    country: 'Mauritania',
    countryRU: 'Мавритания',
    internalPhoneCode: '+222',
    countryCode: 'MR',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Mauritius',
    countryRU: 'Маврикий',
    internalPhoneCode: '+230',
    countryCode: 'MU',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Mayotte',
    countryRU: 'Майотта',
    internalPhoneCode: '+262',
    countryCode: 'YT',
    phoneMask: '+000 000 00 00 00',
  },
  {
    country: 'Mexico',
    countryRU: 'Мехико',
    internalPhoneCode: '+52',
    countryCode: 'MX',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: 'Monaco',
    countryRU: 'Монако',
    internalPhoneCode: '+377',
    countryCode: 'MC',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Mongolia',
    countryRU: 'Монголия',
    internalPhoneCode: '+976',
    countryCode: 'MN',
    phoneMask: '+000 00 000000',
  },
  {
    country: 'Montenegro',
    countryRU: 'Черногория',
    internalPhoneCode: '+382',
    countryCode: 'ME',
    phoneMask: '+000 00 000000',
  },
  {
    country: 'Montserrat',
    countryRU: 'Монтсеррат',
    internalPhoneCode: '+1664',
    countryCode: 'MS',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Morocco',
    countryRU: 'Марокко',
    internalPhoneCode: '+212',
    countryCode: 'MA',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Myanmar',
    countryRU: 'Мьянма',
    internalPhoneCode: '+95',
    countryCode: 'MM',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'Namibia',
    countryRU: 'Намибиа',
    internalPhoneCode: '+264',
    countryCode: 'NA',
    phoneMask: '+000 00 000000',
  },
  {
    country: 'Nauru',
    countryRU: 'Науру',
    internalPhoneCode: '+674',
    countryCode: 'NR',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Nepal',
    countryRU: 'Непал',
    internalPhoneCode: '+977',
    countryCode: 'NP',
    phoneMask: '+000 000 000 0000',
  },
  {
    country: 'Netherlands',
    countryRU: 'Нидерланды',
    internalPhoneCode: '+31',
    countryCode: 'NL',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'Netherlands Antilles',
    countryRU: 'Нидерландские Антильские острова',
    internalPhoneCode: '+599',
    countryCode: 'AN',
    phoneMask: '+000 00000000',
  },
  {
    country: 'New Caledonia',
    countryRU: 'Новая Каледония',
    internalPhoneCode: '+687',
    countryCode: 'NC',
    phoneMask: '+000 000000',
  },
  {
    country: 'New Zealand',
    countryRU: 'Новая Зеландия',
    internalPhoneCode: '+64',
    countryCode: 'NZ',
    phoneMask: '+00 (0) 000 0000',
    altMasks: ['+00 (00) 000 0000', '+00 (000) 000 0000'],
  },
  {
    country: 'Nicaragua',
    countryRU: 'Никарагуа',
    internalPhoneCode: '+505',
    countryCode: 'NI',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Niger',
    countryRU: 'Нигер',
    internalPhoneCode: '+227',
    countryCode: 'NE',
    phoneMask: '+000 00 000000',
  },
  {
    country: 'Nigeria',
    countryRU: 'Нигерия',
    internalPhoneCode: '+234',
    countryCode: 'NG',
    phoneMask: '+000 000 000 0000',
  },
  {
    country: 'Niue',
    countryRU: 'Ниуэ',
    internalPhoneCode: '+683',
    countryCode: 'NU',
    phoneMask: '+000 0000000',
  },
  {
    country: 'Norfolk Island',
    countryRU: 'Остров Норфолк',
    internalPhoneCode: '+672',
    countryCode: 'NF',
    phoneMask: '+000 0 00 000',
  },
  {
    country: 'Northern Mariana Islands',
    countryRU: 'Северные Марианские острова',
    internalPhoneCode: '+1670',
    countryCode: 'MP',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Norway',
    countryRU: 'Норвегия',
    internalPhoneCode: '+47',
    countryCode: 'NO',
    phoneMask: '+00 0000 0000',
  },
  {
    country: 'Oman',
    countryRU: 'Оман',
    internalPhoneCode: '+968',
    countryCode: 'OM',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Pakistan',
    countryRU: 'Пакистан',
    internalPhoneCode: '+92',
    countryCode: 'PK',
    phoneMask: '+00 000 0000000',
  },
  {
    country: 'Palau',
    countryRU: 'Палау',
    internalPhoneCode: '+680',
    countryCode: 'PW',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Panama',
    countryRU: 'Панама',
    internalPhoneCode: '+507',
    countryCode: 'PA',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Papua New Guinea',
    countryRU: 'Папуа-Новая Гвинея',
    internalPhoneCode: '+675',
    countryCode: 'PG',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Paraguay',
    countryRU: 'Парагвай',
    internalPhoneCode: '+595',
    countryCode: 'PY',
    phoneMask: '+000 000 000000',
  },
  {
    country: 'Peru',
    countryRU: 'Перу',
    internalPhoneCode: '+51',
    countryCode: 'PE',
    phoneMask: '+00 00 000000000',
  },
  {
    country: 'Philippines',
    countryRU: 'Филипины',
    internalPhoneCode: '+63',
    countryCode: 'PH',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'Poland',
    countryRU: 'Польша',
    internalPhoneCode: '+48',
    countryCode: 'PL',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'Portugal',
    countryRU: 'Португалия',
    internalPhoneCode: '+351',
    countryCode: 'PT',
    phoneMask: '+000 000 000 000',
  },
  {
    country: 'Puerto Rico',
    countryRU: 'Пуэрто-Рико',
    internalPhoneCode: '+1939',
    countryCode: 'PR',
    phoneMask: '+0000 000 0000',
  },
  {
    country: 'Qatar',
    countryRU: 'Катар',
    internalPhoneCode: '+974',
    countryCode: 'QA',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Romania',
    countryRU: 'Румыния',
    internalPhoneCode: '+40',
    countryCode: 'RO',
    phoneMask: '+00 000 000 000',
  },
  {
    country: 'Rwanda',
    countryRU: 'Руанда',
    internalPhoneCode: '+250',
    countryCode: 'RW',
    phoneMask: '000 000 000',
  },
  {
    country: 'Samoa',
    countryRU: 'Самоа',
    internalPhoneCode: '+685',
    countryCode: 'WS',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'San Marino',
    countryRU: 'Сан-Марино',
    internalPhoneCode: '+378',
    countryCode: 'SM',
    phoneMask: '+000 0000 000000',
  },
  {
    country: 'Saudi Arabia',
    countryRU: 'Саудовская Аравия',
    internalPhoneCode: '+966',
    countryCode: 'SA',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Senegal',
    countryRU: 'Сенегал',
    internalPhoneCode: '+221',
    countryCode: 'SN',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Serbia',
    countryRU: 'Сербия',
    internalPhoneCode: '+381',
    countryCode: 'RS',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Seychelles',
    countryRU: 'Сейшельские острова',
    internalPhoneCode: '+248',
    countryCode: 'SC',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Sierra Leone',
    countryRU: 'Сьерра-Леоне',
    internalPhoneCode: '+232',
    countryCode: 'SL',
    phoneMask: '+000 00 000000',
  },
  {
    country: 'Singapore',
    countryRU: 'Сингапур',
    internalPhoneCode: '+65',
    countryCode: 'SG',
    phoneMask: '+00 0000 0000',
  },
  {
    country: 'Slovakia',
    countryRU: 'Словакия',
    internalPhoneCode: '+421',
    countryCode: 'SK',
    phoneMask: '+000 000 000 000',
  },
  {
    country: 'Slovenia',
    countryRU: 'Словения',
    internalPhoneCode: '+386',
    countryCode: 'SI',
    phoneMask: '+000 0 000 00 00',
  },
  {
    country: 'Solomon Islands',
    countryRU: 'Соломоновы острова',
    internalPhoneCode: '+677',
    countryCode: 'SB',
    phoneMask: '+000 00000',
  },
  {
    country: 'South Africa',
    countryRU: 'Южная Африка',
    internalPhoneCode: '+27',
    countryCode: 'ZA',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    countryRU: 'Южная Георгия и Южные Сандвичевы острова',
    internalPhoneCode: '+500',
    countryCode: 'GS',
    phoneMask: '+000 00000',
  },
  {
    country: 'Spain',
    countryRU: 'Испания',
    internalPhoneCode: '+34',
    countryCode: 'ES',
    phoneMask: '+00 000 000 000',
  },
  {
    country: 'Sri Lanka',
    countryRU: 'Шри-Ланка',
    internalPhoneCode: '+94',
    countryCode: 'LK',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'Sudan',
    countryRU: 'Судан',
    internalPhoneCode: '+249',
    countryCode: 'SD',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Suriname',
    countryRU: 'Суринам',
    internalPhoneCode: '+597',
    countryCode: 'SR',
    phoneMask: '+000 000000',
  },
  {
    country: 'Swaziland',
    countryRU: 'Свазиленд',
    internalPhoneCode: '+268',
    countryCode: 'SZ',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Sweden',
    countryRU: 'Швеция',
    internalPhoneCode: '+46',
    countryCode: 'SE',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'Switzerland',
    countryRU: 'Швейцария',
    internalPhoneCode: '+41',
    countryCode: 'CH',
    phoneMask: '+00 00 000 0000',
  },
  {
    country: 'Tajikistan',
    countryRU: 'Таджикистан',
    internalPhoneCode: '+992',
    countryCode: 'TJ',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Thailand',
    countryRU: 'Тайланд',
    internalPhoneCode: '+66',
    countryCode: 'TH',
    phoneMask: '+00 0 000 0000',
  },
  {
    country: 'Togo',
    countryRU: 'Того',
    internalPhoneCode: '+228',
    countryCode: 'TG',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Tokelau',
    countryRU: 'Токелау',
    internalPhoneCode: '+690',
    countryCode: 'TK',
    phoneMask: '+000 0000',
  },
  {
    country: 'Tonga',
    countryRU: 'Тонга',
    internalPhoneCode: '+676',
    countryCode: 'TO',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Trinidad and Tobago',
    countryRU: 'Тринидад и Тобаго',
    internalPhoneCode: '+1868',
    countryCode: 'TT',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Tunisia',
    countryRU: 'Тунис',
    internalPhoneCode: '+216',
    countryCode: 'TN',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Turkey',
    countryRU: 'Турция',
    internalPhoneCode: '+90',
    countryCode: 'TR',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: 'Turkmenistan',
    countryRU: 'Туркменистан',
    internalPhoneCode: '+993',
    countryCode: 'TM',
    phoneMask: '+000 00 000000',
  },
  {
    country: 'Turks and Caicos Islands',
    countryRU: 'Острова Теркс и Кайкос',
    internalPhoneCode: '+1649',
    countryCode: 'TC',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Tuvalu',
    countryRU: 'Тувалу',
    internalPhoneCode: '+688',
    countryCode: 'TV',
    phoneMask: '+000 00000',
  },
  {
    country: 'Uganda',
    countryRU: 'Уганда',
    internalPhoneCode: '+256',
    countryCode: 'UG',
    phoneMask: '+000 000 000000',
  },
  {
    country: 'Ukraine',
    countryRU: 'Украина',
    internalPhoneCode: '+380',
    countryCode: 'UA',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'United Arab Emirates',
    countryRU: 'Объединенные Арабские Эмираты',
    internalPhoneCode: '+971',
    countryCode: 'AE',
    phoneMask: '+000 00 000000',
    altMasks: ['+000 00 0000000'],
  },
  {
    country: 'United Kingdom',
    countryRU: 'Великобритания',
    internalPhoneCode: '+44',
    countryCode: 'GB',
    phoneMask: '+00 0000 000000',
  },
  {
    country: 'Uruguay',
    countryRU: 'Уругвай',
    internalPhoneCode: '+598',
    countryCode: 'UY',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Uzbekistan',
    countryRU: 'Узбекистан',
    internalPhoneCode: '+998',
    countryCode: 'UZ',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Vanuatu',
    countryRU: 'Вануату',
    internalPhoneCode: '+678',
    countryCode: 'VU',
    phoneMask: '+000 00000',
  },
  {
    country: 'Wallis and Futuna',
    countryRU: 'Уоллис и Футуна',
    internalPhoneCode: '+681',
    countryCode: 'WF',
    phoneMask: '+000 00 0000',
  },
  {
    country: 'Yemen',
    countryRU: 'Йемен',
    internalPhoneCode: '+967',
    countryCode: 'YE',
    phoneMask: '+000 0 000000',
  },
  {
    country: 'Zambia',
    countryRU: 'Замбия',
    internalPhoneCode: '+260',
    countryCode: 'ZM',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Zimbabwe',
    countryRU: 'Зибваве',
    internalPhoneCode: '+263',
    countryCode: 'ZW',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Land Islands',
    countryRU: 'Острова суши',
    internalPhoneCode: '+354',
    countryCode: 'AX',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Bolivia, Plurinational State of',
    countryRU: 'Боливия, Многонациональное Государство',
    internalPhoneCode: '+591',
    countryCode: 'BO',
    phoneMask: '+000 000 000 0000',
  },
  {
    country: 'Brunei Darussalam',
    countryRU: 'Бруней-Даруссалам',
    internalPhoneCode: '+673',
    countryCode: 'BN',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Cocos (Keeling) Islands',
    countryRU: 'Кокосовые (Килинг) острова',
    internalPhoneCode: '+61',
    countryCode: 'CC',
    phoneMask: '+00 0 0000 0000',
  },
  {
    country: 'Congo, The Democratic Republic of the',
    countryRU: 'Конго, Демократическая Республика',
    internalPhoneCode: '+243',
    countryCode: 'CD',
    phoneMask: '+000 00 00 00000',
  },
  {
    country: "Cote d'Ivoire",
    countryRU: "Кот-д'Ивуара",
    internalPhoneCode: '+225',
    countryCode: 'CI',
    phoneMask: '+000 00000000',
  },
  {
    country: 'Falkland Islands (Malvinas)',
    countryRU: 'Фолклендские (Мальвинские) острова',
    internalPhoneCode: '+500',
    countryCode: 'FK',
    phoneMask: '+000 00000',
  },
  {
    country: 'Guernsey',
    countryRU: 'Гернси',
    internalPhoneCode: '+44',
    countryCode: 'GG',
    phoneMask: '+00 (0) 0000 000000',
  },
  {
    country: 'Hong Kong',
    countryRU: 'Гонконг',
    internalPhoneCode: '+852',
    countryCode: 'HK',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Iran, Islamic Republic of',
    countryRU: 'Иран, Исламская Республика',
    internalPhoneCode: '+98',
    countryCode: 'IR',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: "Korea, Democratic People's Republic of",
    countryRU: 'Корея, Народно-Демократическая Республика',
    internalPhoneCode: '+850',
    countryCode: 'KP',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Korea, Republic of',
    countryRU: 'Корея, Республика',
    internalPhoneCode: '+82',
    countryCode: 'KR',
    phoneMask: '+00 0 000 0000',
  },
  {
    country: "(Laos) Lao People's Democratic Republic",
    countryRU: '(Лаос) Лаосская Народно-Демократическая Республика',
    internalPhoneCode: '+856',
    countryCode: 'LA',
    phoneMask: '+000 00 0000 0000',
  },
  {
    country: 'Libyan Arab Jamahiriya',
    countryRU: 'Ливийская Арабская Джамахирия',
    internalPhoneCode: '+218',
    countryCode: 'LY',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Macao',
    countryRU: 'Макао',
    internalPhoneCode: '+853',
    countryCode: 'MO',
    phoneMask: '+000 0000 0000',
  },
  {
    country: 'Macedonia',
    countryRU: 'Македония',
    internalPhoneCode: '+389',
    countryCode: 'MK',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Micronesia, Federated States of',
    countryRU: 'Микронезия, Федеративные Штаты',
    internalPhoneCode: '+691',
    countryCode: 'FM',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Moldova, Republic of',
    countryRU: 'Молдова, Республика',
    internalPhoneCode: '+373',
    countryCode: 'MD',
    phoneMask: '+000 000 00000',
  },
  {
    country: 'Mozambique',
    countryRU: 'Мозамбик',
    internalPhoneCode: '+258',
    countryCode: 'MZ',
    phoneMask: '+000 000 000000',
  },
  {
    country: 'Palestina',
    countryRU: 'Палестина',
    internalPhoneCode: '+970',
    countryCode: 'PS',
    phoneMask: '+000 0 000 0000',
  },
  {
    country: 'Pitcairn',
    countryRU: 'Питкэрн',
    internalPhoneCode: '+64',
    countryCode: 'PN',
    phoneMask: '+00 0 000 0000',
  },
  {
    country: 'Réunion',
    countryRU: 'Реюньон',
    internalPhoneCode: '+262',
    countryCode: 'RE',
    phoneMask: '+000 000 00 00 00',
  },
  {
    country: 'Russia',
    countryRU: 'Россия',
    internalPhoneCode: '+7',
    countryCode: 'RU',
    phoneMask: '+0 (000) 000-00-00',
  },
  {
    country: 'Ascension Island',
    countryRU: 'Остров Вознесения',
    internalPhoneCode: '+247',
    countryCode: 'AC',
    phoneMask: '+000 000000',
    altMasks: ['+000 00000', '+000 00000-00000', '+000 000000-000000'],
  },
  {
    country: 'Saint Barthélemy',
    countryRU: 'Сен-Бартелеми',
    internalPhoneCode: '+590',
    countryCode: 'BL',
    phoneMask: '+000 000 00 00 00',
  },
  {
    country: 'Saint Helena, Ascension and Tristan Da Cunha',
    countryRU: 'Святой Елены, Вознесения и Тристан-да-Кунья',
    internalPhoneCode: '+290',
    countryCode: 'SH',
    phoneMask: '+000 0000',
  },
  {
    country: 'Saint Kitts and Nevis',
    countryRU: 'Сент-Китс и Невис',
    internalPhoneCode: '+1869',
    countryCode: 'KN',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Saint Lucia',
    countryRU: 'Сент-Люсия',
    internalPhoneCode: '+1758',
    countryCode: 'LC',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Saint Martin',
    countryRU: 'Сен-Мартен',
    internalPhoneCode: '+590',
    countryCode: 'MF',
    phoneMask: '+000 000 000000',
  },
  {
    country: 'Saint Pierre and Miquelon',
    countryRU: 'Сен-Пьер и Микелон',
    internalPhoneCode: '+508',
    countryCode: 'PM',
    phoneMask: '+508 00 00 00',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    countryRU: 'Сент-Винсент и Гренадины',
    internalPhoneCode: '+1784',
    countryCode: 'VC',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Sao Tome and Principe',
    countryRU: 'Сент-Винсент и Гренадины',
    internalPhoneCode: '+239',
    countryCode: 'ST',
    phoneMask: '+000 000 0000',
  },
  {
    country: 'Somalia',
    countryRU: 'Сомали',
    internalPhoneCode: '+252',
    countryCode: 'SO',
    phoneMask: '+000 00 000 000',
  },
  {
    country: 'Svalbard and Jan Mayen',
    countryRU: 'Шпицберген и Ян-Майен',
    internalPhoneCode: '+47',
    countryCode: 'SJ',
    phoneMask: '+00 0000 0000',
  },
  {
    country: 'Syrian Arab Republic',
    countryRU: 'Сирийская Арабская Республика',
    internalPhoneCode: '+963',
    countryCode: 'SY',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Taiwan',
    countryRU: 'Тайвань',
    internalPhoneCode: '+886',
    countryCode: 'TW',
    phoneMask: '+000 0 0000 0000',
  },
  {
    country: 'Tanzania',
    countryRU: 'Танзания',
    internalPhoneCode: '+255',
    countryCode: 'TZ',
    phoneMask: '+000 00 000 0000',
  },
  {
    country: 'Timor-Leste',
    countryRU: 'Тимор-Лешти',
    internalPhoneCode: '+670',
    countryCode: 'TL',
    phoneMask: '+000 000 000',
  },
  {
    country: 'Venezuela, Bolivarian Republic of',
    countryRU: 'Венесуэла, Боливарианская Республика',
    internalPhoneCode: '+58',
    countryCode: 'VE',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: 'Viet Nam',
    countryRU: 'Вьетнам',
    internalPhoneCode: '+84',
    countryCode: 'VN',
    phoneMask: '+00 000 000 0000',
  },
  {
    country: 'Virgin Islands, British',
    countryRU: 'Виргинские острова, Британские',
    internalPhoneCode: '+1284',
    countryCode: 'VG',
    phoneMask: '+0 (000) 000 0000',
  },
  {
    country: 'Virgin Islands, U.S.',
    countryRU: 'Виргинские острова, США',
    internalPhoneCode: '+1340',
    countryCode: 'VI',
    phoneMask: '+0 (000) 000 0000',
  },
];
