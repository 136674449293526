import { Pipe, PipeTransform } from '@angular/core';
import { DateIsEqualPipe } from '../date-is-equal/date-is-equal.pipe';

@Pipe({
  name: 'checkDateSelected',
  standalone: true
})
export class CheckDateSelectedPipe implements PipeTransform {

  constructor( private _dateIsEqual: DateIsEqualPipe) {}

  transform(date: Date, multiDateSelect: boolean, dateSelected: Date | null, startDateSelected: Date | null, endDateSelected: Date | null): boolean {
    const dateSelectedCompare = (!multiDateSelect && (this._dateIsEqual.transform(date, dateSelected)))
    const startDateSelectedCompare = (multiDateSelect && (this._dateIsEqual.transform(date, startDateSelected)))
    const endDateSelectedCompare = (multiDateSelect && (this._dateIsEqual.transform(date, endDateSelected)))

    return dateSelectedCompare || startDateSelectedCompare || endDateSelectedCompare;
  }
}
