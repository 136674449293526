import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  ReleaseBulkResourceForm,
  ReleaseReadResponse,
  ReleaseResourceData,
  ReleaseResourceForm,
  ResponseInterface,
} from '@mzic/mzic-interfaces';
import { deleteNullProperties } from '@mzic/mzic-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReleaseService {
  private apiUrl = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  createRelease(
    data: Partial<ReleaseResourceForm>,
  ): Observable<ResponseInterface<ReleaseResourceData>> {
    // Garante de excluir propriedades que estão nulas (para não quebrar a API)
    deleteNullProperties(data);

    return this._http.post<ResponseInterface<ReleaseResourceData>>(
      `${this.apiUrl}/api/distribution/release/v2/create`,
      data,
    );
  }

  createBulkRelease(
    data: Partial<ReleaseBulkResourceForm>[],
  ): Observable<ResponseInterface<ReleaseResourceData>> {
    return this._http.post<ResponseInterface<ReleaseResourceData>>(
      `${this.apiUrl}/api/distribution/release/v2/create/bulk`,
      data,
    );
  }

  updateRelease(
    data: Partial<ReleaseResourceForm>,
    id: number,
  ): Observable<ResponseInterface<ReleaseReadResponse>> {
    // Garante de excluir propriedades que estão nulas (para não quebrar a API)
    deleteNullProperties(data);

    // Não pode enviar o id no corpo da requisição
    delete data.id;

    return this._http.put<ResponseInterface<ReleaseReadResponse>>(
      `${this.apiUrl}/api/distribution/release/v2/update/${id}`,
      data,
    );
  }

  getReleaseById(
    id: number,
  ): Observable<ResponseInterface<ReleaseReadResponse>> {
    return this._http.get<ResponseInterface<ReleaseReadResponse>>(
      `${this.apiUrl}/api/distribution/release/v2/find/${id}`,
    );
  }
}
