// set project version to global window object.
// This value must be updated manually to the *next* version, each time a new PR is submitted.
// TODO: think of a way to auto-update this value in the build/prepublish process
window.__PLUGGY_CONNECT_SDK_VERSION = '2.7.0';
// use NPM modules package names as references for easier referenceability
const REACT_PLUGGY_CONNECT_PACKAGE_NAME = 'react-pluggy-connect';
const PLUGGY_CONNECT_PACKAGE_NAME = 'pluggy-connect-sdk';
/**
 * Retrieve SDK versions from global window object
 *
 * @returns sdkVersion {string[]} - array of current sdks versions strings
 */
export function getSdkVersion() {
    const { __PLUGGY_CONNECT_SDK_VERSION, __REACT_PLUGGY_CONNECT_SDK_VERSION, } = window;
    const sdkVersion = [];
    if (__REACT_PLUGGY_CONNECT_SDK_VERSION) {
        const reactPluggyConnectSdkversion = `${REACT_PLUGGY_CONNECT_PACKAGE_NAME}@${__REACT_PLUGGY_CONNECT_SDK_VERSION}`;
        sdkVersion.push(reactPluggyConnectSdkversion);
    }
    const pluggyConnectSdkVersion = `${PLUGGY_CONNECT_PACKAGE_NAME}@${__PLUGGY_CONNECT_SDK_VERSION}`;
    sdkVersion.push(pluggyConnectSdkVersion);
    return sdkVersion;
}
