import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'mzic-option',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class MzicOptionComponent {
  @Input({ required: true }) id: string | number | undefined;
  @Input() value!: string | number;

  @Output() optionChange: EventEmitter<string | number> = new EventEmitter<
    string | number
  >();

  constructor(public readonly eRef: ElementRef) {}

  selectOption() {
    this.optionChange.emit(this.id);
  }
}
