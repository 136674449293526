import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mzic-skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mzic-skeleton.component.html',
  styleUrls: ['./mzic-skeleton.component.scss'],
})
export class MzicSkeletonComponent {}
