<div
  class="mzic-track-list-item show-image-{{ showImage }}"
  [class.opacity-image]="opacityImage">
  <div class="image layout-{{ imageLayout }}" *ngIf="showImage">
    @if (trackNumber > 0) {
      <div class="text-over-image text-body-xsm-bold">{{ trackNumber | zeropad }}</div>
    }

    @if (image) {
      <img
        [src]="image | mzicImage: imageCDNSizes.DEFAULT"
        onerror="this.onerror=null;this.src='assets/icons/empty-song.svg'" />
    } @else {
      <img src="assets/icons/empty-song.svg" />
    }
  </div>
  <div
    class="text"
    [ngClass]="{ bold: nameInBold }"
    [class.text--disabled]="disabled">
    <div class="title" title="{{ title }}" *ngIf="title.length > 0">
      <span class="limited-text">
        {{ title }}
        <span *ngIf="version?.length" class="version">({{ version }})</span>
      </span>
    </div>
    <small class="limited-text" title="{{ description ? description : '-' }}">
      {{ description ? description : '-' }}
    </small>
  </div>
</div>
