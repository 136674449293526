import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  ArtistTypeResourceData,
  ArtistTypesResourceData,
  ResponseInterface,
} from '@mzic/mzic-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArtistTypeService {
  private apiUrl = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  getArtistTypeIdByRoleId(
    roleId: number,
  ): Observable<ResponseInterface<ArtistTypesResourceData>> {
    return this._http.get<ResponseInterface<ArtistTypesResourceData>>(
      `${this.apiUrl}/api/distribution/artist-type/v2/find?lsRoleId=${roleId}`,
    );
  }

  getArtistTypes(
    priorities: number[] = [],
    sort = '',
  ): Observable<ResponseInterface<ArtistTypeResourceData>> {
    return this._http.get<ResponseInterface<ArtistTypeResourceData>>(
      `${this.apiUrl}/api/distribution/artist-type/v2/find${priorities.length > 0 ? `?priority=${priorities.join(',')}` : ''}${sort !== '' ? `&sort=${sort}` : ''}&size=400`,
    );
  }
}
