import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeropad',
  standalone: true,
})
export class ZeropadPipe implements PipeTransform {
  transform(value: number, maxValue = 10, zeros = 2): string {
    if (value > maxValue) {
      return value.toString();
    }

    const valueString = value.toString();
    const padding = '0'.repeat(Math.max(0, zeros - valueString.length));

    return `${padding}${valueString}`;
  }
}
