import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MzicSvgComponent } from '../mzic-svg/mzic-svg.component';

@Component({
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  templateUrl: './mzic-toast.component.html',
  styleUrls: ['./mzic-toast.component.scss'],
})
export class MzicToastComponent implements OnInit, OnDestroy {
  @Output() closeEvent = new EventEmitter();

  @Input() title = '';
  @Input() description = '';
  @Input() icon = '';
  @Input() iconColor = '';
  @Input() iconType: 'stroke' | 'fill' = 'fill';
  @Input() button = '';
  @Input() showCloseButton = true;
  @Input() showLoader = false;
  @Input() loaderColor = 'bg-purple';
  @Input() time = 3000;
  @Input() width = '404px';

  interval: any;
  loaderPercent = '0%';

  constructor(private elementRef: ElementRef) {}

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit(): void {
    let counter = 1;
    this.interval = setInterval(() => {
      const p = (100 * counter) / this.time;
      this.loaderPercent = `${p}%`;
      counter = counter + 10;
    }, 10);
    // todo: diogo, precisa manter esse codigo para o toast funcionar e fechar sozinho
    setTimeout(() => {
      this.close();
    }, this.time);
  }

  close(buttonClicked = false) {
    this.elementRef.nativeElement.remove();
    this.closeEvent.emit(buttonClicked);
    this.ngOnDestroy();
  }
}
