<div
  *ngIf="options?.showOutside"
  class="mzic-popover-outside {{ options.cssClass }} show-background-{{
    options.showBackground
  }}"
  (click)="outsideClick()"></div>
<div
  class="mzic-popover {{ options.cssClass }} fixed-{{ options.fixed }}"
  #popover>
  <ng-content></ng-content>
  <ng-template #container></ng-template>
</div>
