<div class="mzic-popover style-toast" [ngStyle]="{ width: width }">
  <div
    *ngIf="showLoader"
    class="progress {{ loaderColor }}"
    [ngStyle]="{ width: loaderPercent }"></div>
  <div>
    <mzic-svg [src]="icon" class="w28 {{ iconColor }} {{ iconType }}" />
    <div class="message-container">
      <div class="main" *ngIf="title.length > 0">{{ title }}</div>
      <div class="secondary" *ngIf="description.length">{{ description }}</div>
    </div>
  </div>
  <div>
    <a (click)="close(true)" class="link-btn" *ngIf="button.length > 0">
      {{ button }}
    </a>
    <mzic-svg
      *ngIf="showCloseButton"
      (click)="close()"
      src="assets/icons/mzic-close.svg"
      class="neutral-700 w16 clickable" />
  </div>
</div>
