import { CountDataProfileDTO } from '../account/account.model';
import { ApiResponse } from '../shared/api-response.interface';
import { WalletType } from './user-types';

export interface GetUserProfile extends ApiResponse {
  data: GetUserProfileData;
}

export interface GetUserProfileData {
  username: string;
  email: string;
  profilePhoto: string;
  backgroundPhoto: string;
  bio: string;
  birthDate: string;
  currencyType: string;
  walletId: number;
  createdAt: string;
  fullName: string;
  phoneNumber: string;
  chainWallet: string;
  cognito: string;
  userId: number;
  walletType: WalletType;
  countDataProfile: CountDataProfileDTO;
  phoneNumberValidate: boolean;
  region: string;
  gatewayType: string;
  socialSecurityNumber: string;
  cbo: string;
  motherFullName: string;
  pep: boolean;
  revenue: number;
  verified: boolean;
}

export function createEmptyUserProfile(): GetUserProfileData {
  return {
    username: '',
    email: '',
    profilePhoto: '',
    backgroundPhoto: '',
    bio: '',
    birthDate: '',
    currencyType: '',
    walletId: 0,
    createdAt: '',
    fullName: '',
    phoneNumber: '',
    chainWallet: '',
    cognito: '',
    userId: 0,
    walletType: 'BACKOFFICE',
    countDataProfile: {
      offer: 0,
      certificate: 0,
      favorite: 0,
      song: 0,
    },
    phoneNumberValidate: false,
    region: '',
    gatewayType: '',
    socialSecurityNumber: '',
    cbo: '',
    motherFullName: '',
    pep: false,
    revenue: 0,
    verified: false,
  };
}
