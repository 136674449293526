import { Pipe, PipeTransform } from '@angular/core';
import { getMonthName } from '../../util/util';
import { LANGUAGE } from '../../model/calendar-language.model';

@Pipe({
  name: 'monthName',
  standalone: true,
})
export class MonthNamePipe implements PipeTransform {
  transform(monthNumber: number, year: number, language: LANGUAGE): string {
    return getMonthName(year, monthNumber, language)
  }
}
