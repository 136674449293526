import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'mzic-mzic-popover',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mzic-popover.component.html',
  styleUrl: './mzic-popover.component.scss',
})
export class MzicPopoverComponent {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;

  // pode criar quantas propriedades quiser, essa é só de exemplo
  @Input() custom = 'custom';
}
