import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { MzicScrollingPaginationDirective } from '@mzic/mzic-directives';
import { OptionSpotifyItem } from '@mzic/mzic-interfaces';
import { Subject, Subscription } from 'rxjs';
import { SearchSpotifyOptionSelectedComponent } from '../search-spotify-option-selected/search-spotify-option-selected.component';
import { SearchSpotifyOptionComponent } from '../search-spotify-option/search-spotify-option.component';

@Component({
  selector: 'mzic-search-spotify',
  standalone: true,
  imports: [
    CommonModule,
    SearchSpotifyOptionComponent,
    SearchSpotifyOptionSelectedComponent,
    MzicScrollingPaginationDirective,
    TranslocoModule,
    NgOptimizedImage,
  ],
  providers: [
    provideTranslocoScope({
      scope: 'mzic-search-spotify',
      alias: 'lang',
    }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search-spotify.component.html',
  styleUrls: ['./search-spotify.component.scss'],
})
export class SearchSpotifyComponent implements OnInit, OnDestroy {
  @Input({ required: true }) values!: OptionSpotifyItem[];
  @Input({ required: true }) artistNotFound!: boolean;
  @Input() error = false;
  @Input() loading = true;
  @Input() responsive = true;
  @Input() event = new Subject<'RESET' | 'SERVER_ERROR'>();
  event$!: Subscription;

  @Output() inputChange = new EventEmitter<string>();
  @Output() scrolling = new EventEmitter<{
    inputValue: string;
    page: number;
  }>();
  @Output() valueSelected = new EventEmitter<OptionSpotifyItem>();

  initialOptionSelected: OptionSpotifyItem = {
    uuid: '',
    imageUri: '',
    name: '',
  };

  protected searchValue = signal('');
  protected selectedOption = signal<OptionSpotifyItem>(
    this.initialOptionSelected,
  );
  protected changeIcon: WritableSignal<boolean> = signal(false);
  protected page = 0;

  ngOnInit() {
    this.event$ = this.event.subscribe((event) => {
      if (event === 'RESET') {
        this.searchValue.set('');
        this.values = [];
        this.page = 0;
        this.artistNotFound = false;
        this.error = false;
        this.valueSelected.emit(this.initialOptionSelected);
        this.event$?.unsubscribe();
      }

      if (event === 'SERVER_ERROR') {
        this.error = true;
        this.values = [];
        this.selectedOption.set(this.initialOptionSelected);
      }
    });
  }

  ngOnDestroy() {
    this.event$?.unsubscribe();
  }

  onInputChange(event: Event) {
    this.changeIcon.set(true);
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchValue.set(searchQuery);
    if (searchQuery.length === 0) {
      this.artistNotFound = false;
    }
    if (searchQuery.length >= 3) {
      this.values = [];
      this.page = 0;
      this.inputChange.emit(searchQuery);
    }
  }

  onClearInput() {
    this.searchValue.set('');
    this.values = [];
    this.page = 0;
    this.artistNotFound = false;
    this.error = false;
  }

  onSelectedOption(selected: OptionSpotifyItem) {
    this.selectedOption.set(selected);
    this.valueSelected.emit(selected);
    this.page = 0;
  }

  onFocusOut() {
    this.changeIcon.set(false);
    this.values = [];
    this.page = 0;
  }

  onScrolling(page: number) {
    if (this.page !== page) {
      this.page = page;
      this.scrolling.emit({ inputValue: this.searchValue(), page: this.page });
    }
  }
}
