import { HttpParams } from '@angular/common/http';

export const httpParams = (obj: any) => {
  let params = new HttpParams();

  for (const property in obj) {
    if (obj[property] !== undefined) {
      params = params.set(property, obj[property]);
    }
  }

  return {
    params,
  };
};
