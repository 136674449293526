import {
  eachDayOfInterval,
  endOfWeek,
  format,
  lastDayOfMonth,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import { LANGUAGE } from '../model/calendar-language.model';

export const getWeekdaysName = (language: LANGUAGE = 'pt'): Array<string> => {
  const today = new Date();

  const start = startOfWeek(today);
  const end = endOfWeek(today);
  const weekdays = eachDayOfInterval({ start, end });

  if (language === 'pt')
    return weekdays.map(day => format(day, 'EEEE', { locale: ptBR })).map(day => day.charAt(0).toUpperCase());

  return weekdays.map(day => format(day, 'EEEE', { locale: enUS })).map(day => day.charAt(0).toUpperCase());
};

export const getMonthName = (year: number, month: number, language: LANGUAGE): string => {
  const date = new Date(year, month - 1);
  if (language === 'pt') return format(date, 'MMM', { locale: ptBR });

  return format(date, 'MMM', { locale: enUS });
}

export const getDataForCalendar = (year: number, month: number): Array<Date> => {
  const currentDate = new Date(year, month - 1);
  const firstDayMonth = startOfMonth(currentDate);
  const lastDayMonth = lastDayOfMonth(currentDate);

  const firstDayOfWeek = startOfWeek(firstDayMonth);
  const lastDayOfWeek: Date = endOfWeek(lastDayMonth);

  return eachDayOfInterval({start: firstDayOfWeek, end: lastDayOfWeek});
}

