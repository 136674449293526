export function isRunningInCordova() {
    return typeof cordova !== 'undefined';
}
export function openInCordovaInAppBrowser(url, target) {
    if (!isRunningInCordova()) {
        throw new Error('Not running in Cordova');
    }
    if (!cordova.InAppBrowser) {
        console.error("Tried to open a link in cordova.InAppBrowser, but it's undefined! " +
            "For the best experience, it's recommended to include plugin 'cordova-plugin-inappbrowser' in your build. " +
            'Falling back to window.open() instead. Url:', url);
        window.open(url, target);
        return null;
    }
    const inAppBrowserWindow = cordova.InAppBrowser.open(url, target);
    // inAppBrowser is actually defined only with '_blank' target
    return target === '_blank' ? inAppBrowserWindow : null;
}
