import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[mzicDropInDown]',
  standalone: true,
})
export class MzicDropInDownDirective {
  @Output() dragOver = new EventEmitter<DragEvent>();
  @Output() dragLeave = new EventEmitter<DragEvent>();
  @Output() fileDropped = new EventEmitter<FileList>();

  @HostBinding('class')
  cssClass = '';

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.cssClass = 'on-drag-over';
    this.dragOver.emit(event);
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.cssClass = '';
    this.dragLeave.emit(event);
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer?.files) {
      const files = event.dataTransfer.files as FileList;
      if (files.length) {
        this.cssClass = '';
        this.fileDropped.emit(files);
      }
    }
  }
}
