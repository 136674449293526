import { RoyaltySplitDetail } from './royalty-split-detail.interface';

export interface RoyaltySplitDetailResponse {
  timestamp: string;
  status: string;
  data: RoyaltySplitDetail;
}

export function createEmptyRoyaltySplitDetail(): RoyaltySplitDetail {
  return {
    releases: 0,
    tracks: 0,
    split: {
      id: 0,
      name: '',
      payorWallet: {
        user: {
          id: 0,
          firstName: '',
          lastName: '',
          profilePhoto: '',
          backGroundPhoto: '',
        },
      },
      revenueType: '',
      externalFunding: false,
      startDate: '',
      expirationDate: '',
      type: '',
      assets: [],
      rules: [],
      payees: [],
      teamSplit: {
        team: {
          name: '',
        },
      },
      version: 0,
      code: '',
      status: '',
    },
  };
}
