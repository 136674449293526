import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MzicLabelLocalService {
  public setDataUserTo2FA(userParams: {
    userId: number;
    email: string;
    username: string;
    password?: string;
  }): void {
    const getcontextTo2FA = JSON.stringify(userParams);
    localStorage.setItem('mzic-datauser-2fa', getcontextTo2FA);
  }
}
