import { HttpClient, HttpHeaders } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@mzic/mzic-environments';
import {
  createEmptyWalletBalanceData,
  createEmptyWalletFeeSimulateData,
  createEmptyWalletPaymentMethodBankData,
  createEmptyWalletTransactionsData,
  PostExchangeCurrencyBody,
  PostWalletAddBank,
  PostWalletCashout,
  PostWalletCashoutBody,
  PostWalletFilterBankAccount,
  PostWalletFilterBankAccountData,
  PostWalletToken,
  WalletBalance,
  WalletBalanceData,
  WalletFeeSimulate,
  WalletFeeSimulateData,
  WalletFeeSimulateParams,
  WalletListPaymentMethodBank,
  WalletListPaymentMethodBankData,
  WalletTransactions,
  WalletTransactionsData,
  WalletTransactionsParams,
} from '@mzic/mzic-interfaces';
import { httpParams } from '@mzic/mzic-utils';
import { PluggyConnect } from 'pluggy-connect-sdk';
import { map, Subject } from 'rxjs';

interface WalletState {
  loading: boolean;
  walletBalance: WalletBalanceData;
  walletTransactions: WalletTransactionsData;
  walletFeeSimulate: WalletFeeSimulateData;
  walletListPaymentMethodBank: WalletListPaymentMethodBankData[];
  walletToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  private apiUrl = `${environment.apiUrl}/api/backoffice`;

  private pluggyConnect: PluggyConnect | undefined;

  private state = signal<WalletState>({
    loading: false,
    walletBalance: createEmptyWalletBalanceData(),
    walletTransactions: createEmptyWalletTransactionsData(),
    walletFeeSimulate: createEmptyWalletFeeSimulateData(),
    walletListPaymentMethodBank: createEmptyWalletPaymentMethodBankData(),
    walletToken: '',
  });

  walletBalance = computed(() => this.state().walletBalance);
  walletTransactions = computed(() => this.state().walletTransactions);
  walletFeeSimulate = computed(() => this.state().walletFeeSimulate);
  walletListPaymentMethodBank = computed(
    () => this.state().walletListPaymentMethodBank,
  );
  walletBankAccountMain = computed(
    () =>
      this.state().walletListPaymentMethodBank.filter(
        (bankAccount) => bankAccount.main,
      )[0],
  );
  walletToken = computed(() => this.state().walletToken);

  walletBalance$ = new Subject<WalletBalanceData>();
  walletTransactions$ = new Subject<WalletTransactionsData>();
  walletFeeSimulate$ = new Subject<WalletFeeSimulateData>();
  walletListPaymentMethodBank$ = new Subject<
    WalletListPaymentMethodBankData[]
  >();
  walletToken$ = new Subject<string>();

  constructor(private readonly _http: HttpClient) {
    this.walletBalance$
      .pipe(takeUntilDestroyed())
      .subscribe((walletBalance) => {
        this.state.update((state) => ({
          ...state,
          loading: true,
          walletBalance,
        }));
      });

    this.walletTransactions$
      .pipe(takeUntilDestroyed())
      .subscribe((walletTransactions) => {
        this.state.update((state) => ({
          ...state,
          loading: true,
          walletTransactions,
        }));
      });

    this.walletFeeSimulate$
      .pipe(takeUntilDestroyed())
      .subscribe((walletFeeSimulate) => {
        this.state.update((state) => ({
          ...state,
          loading: true,
          walletFeeSimulate,
        }));
      });

    this.walletListPaymentMethodBank$
      .pipe(takeUntilDestroyed())
      .subscribe((walletListPaymentMethodBank) => {
        this.state.update((state) => ({
          ...state,
          loading: true,
          walletListPaymentMethodBank,
        }));
      });

    this.walletToken$.pipe(takeUntilDestroyed()).subscribe((walletToken) => {
      this.state.update((state) => ({
        ...state,
        loading: true,
        walletToken,
      }));
    });
  }

  getWalletBalance(teamId: number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .get<WalletBalance>(`${this.apiUrl}/wallet/v1/balance`, httpOptions)
      .pipe(map((response) => response.data));
  }

  getWalletTransactions(teamId: number, params?: WalletTransactionsParams) {
    if (params?.pageable?.page) {
      params.pageable.page--;
    }

    const headers = new HttpHeaders().append('TEAM-ID', `${teamId}`);

    return this._http
      .get<WalletTransactions>(`${this.apiUrl}/wallet/v1/transactions`, {
        headers,
        ...httpParams({
          ...params?.pageable,
          transactionType: params?.transactionType,
          transactionDateStart: params?.transactionDateStart,
          transactionDateEnd: params?.transactionDateEnd,
        }),
      })
      .pipe(map((response) => response.data));
  }

  getWalletFeeSimulate(teamId: number, params?: WalletFeeSimulateParams) {
    const headers = new HttpHeaders().append('TEAM-ID', `${teamId}`);

    return this._http
      .get<WalletFeeSimulate>(`${this.apiUrl}/wallet/v1/fee/simulate`, {
        headers,
        ...httpParams(params),
      })
      .pipe(map((response) => response.data));
  }

  getWalletListPaymentMethodBank(teamId: number) {
    const headers = new HttpHeaders().append('TEAM-ID', `${teamId}`);

    return this._http
      .get<WalletListPaymentMethodBank>(
        `${this.apiUrl}/wallet/v1/list/payment-method/bank`,
        {
          headers,
        },
      )
      .pipe(map((response) => response.data));
  }

  postWalletToken(teamId: number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .post<PostWalletToken>(
        `${this.apiUrl}/wallet/v1/generate-token`,
        {},
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  postWalletFilterBankAccount(body: any) {
    return this._http
      .post<PostWalletFilterBankAccount>(
        `${this.apiUrl}/wallet/v1/filter/bank-account`,
        body,
      )
      .pipe(map((response) => response.data));
  }

  postWalletCashOut(
    teamId: number,
    confirmationCode: string,
    body: PostWalletCashoutBody,
  ) {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .post<PostWalletCashout>(
        `${this.apiUrl}/wallet/v1/cashout?confirmationCode=${confirmationCode}`,
        body,
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  postWalletExchangeCurrency(teamId: number, body: PostExchangeCurrencyBody) {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .post<PostWalletCashout>(
        `${this.apiUrl}/wallet/v1/exchange-currency`,
        body,
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  postWalletAddBank(teamId: number, body: PostWalletFilterBankAccountData) {
    const newBody = {
      accessToken: body.accessToken,
      accountsIds: body.accounts.map((account) => account.accountId),
    };

    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .post<PostWalletAddBank>(
        `${this.apiUrl}/wallet/v1/add/payment-method/bank`,
        newBody,
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  deleteWalletBankAccount(teamId: number, paymentMethodId: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .delete<PostWalletAddBank>(
        `${this.apiUrl}/wallet/v1/delete/bank-account/${paymentMethodId}`,
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  openPluggyConnect(
    onSuccess: (data: any) => void,
    onError: (error: any) => void,
  ): void {
    this.pluggyConnect = new PluggyConnect({
      connectToken: this.walletToken(),
      includeSandbox: !environment.production,
      onSuccess,
      onError,
    });

    this.pluggyConnect.init();
  }

  closePluggy() {
    this.pluggyConnect?.destroy();
  }
}
