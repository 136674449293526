import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { SelectOption, SelectVariationEnum } from '@mzic/mzic-interfaces';
import { SelectBasicComponent } from '../select-basic/select-basic.component';

@Component({
  selector: 'mzic-select-control',
  standalone: true,
  templateUrl: './select-control.component.html',
  styleUrl: './select-control.component.scss',
  imports: [CommonModule, SelectBasicComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectControlComponent),
      multi: true,
    },
  ],
})
export class SelectControlComponent implements ControlValueAccessor, Validator {
  @Input() variation: SelectVariationEnum = SelectVariationEnum.Default;
  @Input() useLightStyle = false;
  @Input() useSuccessStyle = false;
  @Input() useErrorStyle = false;
  @Input() invalid = false;
  @Input() loading = false;
  @Input() placeholder = '';
  @Input() options: SelectOption[] | undefined;

  handleOptionChange(optionsId: (string | number)[] | undefined) {
    this.writeValue(optionsId);
    this.onChanged(optionsId);
    this.onTouched();
  }

  handleSelectClosedChange(closed: boolean) {
    this.onTouched();
  }

  protected disabled = false;
  protected value!: (string | number)[] | undefined;

  onChanged!: (value: (string | number)[] | undefined) => void;
  onTouched!: () => void;

  writeValue(value: (string | number)[] | undefined) {
    this.value = value;
  }

  registerOnChange(fn: (value: (string | number)[] | undefined) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const invalid = control.value === '';
    return invalid ? { invalid: true } : null;
  }
}
