import { Injectable } from '@angular/core';
import { RegistryTypeEnum, WalletTeamData } from '@mzic/mzic-interfaces';
import { capitalize } from '@mzic/mzic-utils';
import { MzicTokenLocalService } from '../mzic-token-local/mzic-token-local.service';

@Injectable({
  providedIn: 'root',
})
export class MzicArtistLocalService {
  constructor(private readonly _mzicTokenLocalService: MzicTokenLocalService) {}

  public setAllTeams(teams: WalletTeamData[]) {
    localStorage.setItem('mzic-all-teams-local', JSON.stringify(teams));
  }

  public getAllTeams() {
    const teamsLocal = localStorage.getItem('mzic-all-teams-local');
    if (!teamsLocal) {
      return [];
    }
    const teams = JSON.parse(teamsLocal) as WalletTeamData[];

    teams.map(
      (team) =>
        (team.team.registryType = capitalize(
          team.team.registryType,
        ) as RegistryTypeEnum),
    );

    return teams;
  }

  public removeAllTeams() {
    localStorage.removeItem('mzic-all-teams-local');
  }

  public setTeamId(teamId: number): void {
    localStorage.setItem('mzic-teamId-local', teamId.toString());
  }

  public getTeamId(): string {
    return localStorage.getItem('mzic-teamId-local') as string;
  }

  public setWalletTeam(wallet: WalletTeamData): void {
    localStorage.setItem('mzic-wallet-team', JSON.stringify(wallet));
  }

  public getWalletTeam(): WalletTeamData {
    return JSON.parse(localStorage.getItem('mzic-wallet-team') as string);
  }

  public removeWalletTeam(): void {
    localStorage.removeItem('mzic-wallet-team');
  }

  public removeTeamId(): void {
    localStorage.removeItem('mzic-teamId-local');
  }

  public removeAllStorage(): void {
    this.removeDataUSerTo2FA();
    this.removeInstagramUsername();
    this.removeViberateUuid();
    this.removeTeamId();
    this.removeWalletTeam();
    this.removeAllTeams();
    this._mzicTokenLocalService.clearAlltokens();
  }

  public setDataUserTo2FA(userParams: {
    userId: number;
    email: string;
    username: string;
    password?: string;
  }): void {
    const getcontextTo2FA = JSON.stringify(userParams);
    localStorage.setItem('mzic-datauser-2fa', getcontextTo2FA);
  }

  public getDataUserTo2FA(): string {
    return localStorage.getItem('mzic-datauser-2fa') as string;
  }

  public removeDataUSerTo2FA() {
    localStorage.removeItem('mzic-datauser-2fa');
  }

  public getViberateUuid(): string {
    return localStorage.getItem('mzic-viberate-uuid') as string;
  }

  public setViberateUuid(viberateUuid: string) {
    localStorage.setItem('mzic-viberate-uuid', viberateUuid);
  }

  public removeViberateUuid() {
    localStorage.removeItem('mzic-viberate-uuid');
  }

  public getInstagramUsername(): string {
    return localStorage.getItem('mzic-instagram-username') as string;
  }

  public setInstagramUsername(username: string) {
    localStorage.setItem('mzic-instagram-username', username);
  }

  public removeInstagramUsername() {
    localStorage.removeItem('mzic-instagram-username');
  }
}
