import {
  computed,
  effect,
  inject,
  Injectable,
  OnDestroy,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@mzic/mzic-environments';
import {
  createEmptyPayee,
  createEmptyPayeeLanguages,
  createEmptyPayeeType,
  CreatePayeeRequest,
  GetSettingsV2FindData,
  Payee,
  PayeeLanguages,
  PayeeType,
} from '@mzic/mzic-interfaces';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FeedbackState } from '../../feedback/state/feedback.state';
import { MzicArtistLocalService } from '../../mzic-artist-local/mzic-artist-local.service';
import { SettingsService } from '../../settings/settings.service';
import { RoyaltySplitsService } from '../royalty-splits.service';

interface PayeeModel {
  isLoading: boolean;
  payee: Payee[];
  recentPayee: Payee[];
  selectedPayee: Payee[];
  payeeImage: string;
  payeeTypes: PayeeType[];
  payeeLanguages: PayeeLanguages[];
  freePercentage: number;
}

@Injectable({
  providedIn: 'root',
})
export class PayeeState implements OnDestroy {
  private royaltySplitsService = inject(RoyaltySplitsService);
  private mzicArtistLocalService = inject(MzicArtistLocalService);
  private feedbackState = inject(FeedbackState);
  private settingsService = inject(SettingsService);

  private initialState = {
    isLoading: false,
    payee: createEmptyPayee(),
    recentPayee: createEmptyPayee(),
    selectedPayee: [],
    payeeImage: '',
    payeeTypes: createEmptyPayeeType(),
    payeeLanguages: createEmptyPayeeLanguages(),
    freePercentage: 100,
  };

  private state = signal<PayeeModel>(this.initialState);

  isLoadingSnapshot = computed(() => this.state().isLoading);
  payeeSnapshot = computed(() => this.state().payee);
  recentPayeeSnapshot = computed(() => this.state().payee);
  selectedPayeeSnapshot = computed(() => this.state().selectedPayee);
  payeeImageSnapshot = computed(() => this.state().payeeImage);
  payeeTypesSnapshot = computed(() => this.state().payeeTypes);
  payeeLanguagesSnapshot = computed(() => this.state().payeeLanguages);
  freePercentageSnapshot = computed(() => this.state().freePercentage);

  private isLoading$ = new BehaviorSubject<boolean>(false);
  private payee$ = new BehaviorSubject<Payee[]>([]);
  private recentPayee$ = new BehaviorSubject<Payee[]>([]);
  private selectedPayee$ = new BehaviorSubject<Payee[]>([]);
  private payeeImage$ = new BehaviorSubject<string>('');
  private payeeTypes$ = new BehaviorSubject<PayeeType[]>([]);
  private payeeLanguages$ = new BehaviorSubject<PayeeLanguages[]>([]);
  private freePercentage$ = new BehaviorSubject<number>(100);

  private subscription: Subscription = new Subscription();

  settings: GetSettingsV2FindData | undefined;

  get artistId(): number {
    return this.mzicArtistLocalService.getWalletTeam()['team'].artistId;
  }

  get isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  get payee(): Observable<Payee[]> {
    return this.payee$.asObservable();
  }

  get recentPayee(): Observable<Payee[]> {
    return this.recentPayee$.asObservable();
  }

  get selectedPayee(): Observable<Payee[]> {
    return this.selectedPayee$.asObservable();
  }

  get payeeTypes(): Observable<PayeeType[]> {
    return this.payeeTypes$.asObservable();
  }

  get payeeImage(): Observable<string> {
    return this.payeeImage$.asObservable();
  }

  get payeeLanguages(): Observable<PayeeLanguages[]> {
    return this.payeeLanguages$.asObservable();
  }

  get freePercentage(): Observable<number> {
    return this.freePercentage$.asObservable();
  }

  constructor() {
    effect(() => {
      if (!environment.production) {
        console.log('Payee State', this.state());
      }
    });

    this.isLoading$.pipe(takeUntilDestroyed()).subscribe((isLoading) => {
      this.state.update((state) => ({
        ...state,
        isLoading,
      }));
    });

    this.payee$.pipe(takeUntilDestroyed()).subscribe((payee) => {
      this.state.update((state) => ({
        ...state,
        payee,
      }));
    });

    this.recentPayee$.pipe(takeUntilDestroyed()).subscribe((recentPayee) => {
      this.state.update((state) => ({
        ...state,
        recentPayee,
      }));
    });

    this.selectedPayee$
      .pipe(takeUntilDestroyed())
      .subscribe((selectedPayee) => {
        this.state.update((state) => ({
          ...state,
          selectedPayee,
        }));
      });

    this.payeeImage$.pipe(takeUntilDestroyed()).subscribe((payeeImage) => {
      this.state.update((state) => ({
        ...state,
        payeeImage,
      }));
    });

    this.payeeTypes$.pipe(takeUntilDestroyed()).subscribe((payeeTypes) => {
      this.state.update((state) => ({
        ...state,
        payeeTypes,
      }));
    });

    this.payeeLanguages$
      .pipe(takeUntilDestroyed())
      .subscribe((payeeLanguages) => {
        this.state.update((state) => ({
          ...state,
          payeeLanguages,
        }));
      });

    this.freePercentage$
      .pipe(takeUntilDestroyed())
      .subscribe((freePercentage) => {
        this.state.update((state) => ({
          ...state,
          freePercentage,
        }));
      });

    this.subscription.add(
      this.settingsService
        .getSettingsV2Find()
        .subscribe((data) => (this.settings = data)),
    );
  }

  loadPayee(searchTerm: string) {
    this.isLoading$.next(true);

    this.subscription.add(
      this.royaltySplitsService.getPayeeFind(searchTerm).subscribe({
        next: (payee) => {
          this.payee$.next(payee.data);
          this.isLoading$.next(false);
        },
        error: () => {
          this.isLoading$.next(false);
        },
      }),
    );
  }

  loadRecentPayee() {
    this.isLoading$.next(true);

    this.subscription.add(
      this.royaltySplitsService.getRecentPayee().subscribe({
        next: (payees) => {
          this.recentPayee$.next(payees.data);

          payees.data.forEach((payee) => {
            if (payee.id === this.artistId) {
              payee.artistTypeId = 2;
              payee.languageId = this.settings?.language;

              this.updateSelectPayee(payee, 'add');
            }
          });

          this.isLoading$.next(false);
        },
        error: () => {
          this.isLoading$.next(false);
        },
      }),
    );
  }

  loadPayeeTypes() {
    this.isLoading$.next(true);

    this.subscription.add(
      this.royaltySplitsService.getPayeeTypes().subscribe({
        next: (payeeTypes) => {
          this.payeeTypes$.next(payeeTypes.data.content);
          this.isLoading$.next(false);
        },
        error: () => {
          this.isLoading$.next(false);
        },
      }),
    );
  }

  loadPayeeLanguages() {
    this.isLoading$.next(true);

    this.subscription.add(
      this.royaltySplitsService.getPayeeLanguages().subscribe({
        next: (payeeLanguages) => {
          this.payeeLanguages$.next(payeeLanguages.data);
          this.isLoading$.next(false);
        },
        error: () => {
          this.isLoading$.next(false);
        },
      }),
    );
  }

  uploadPayeeImage(file: FormData) {
    this.isLoading$.next(true);

    this.subscription.add(
      this.royaltySplitsService.uploadPayeeImage(file).subscribe({
        next: (image) => {
          this.payeeImage$.next(image.data);
          this.isLoading$.next(false);
        },
        error: () => {
          this.isLoading$.next(false);
        },
      }),
    );
  }

  createPayee(payload: CreatePayeeRequest) {
    this.isLoading$.next(true);

    payload = {
      ...payload,
      hash: this.payeeImageSnapshot(),
    };

    this.subscription.add(
      this.royaltySplitsService.createPayee(payload).subscribe({
        next: (payee) => {
          const newPayee = { ...payee.data, new: true } as Payee;

          this.recentPayee$.next([...this.recentPayeeSnapshot(), newPayee]);
          this.payeeImage$.next('');
          this.isLoading$.next(false);
          this.feedbackState.setFeedback(
            'Payee added successfully!',
            'SUCCESS',
          );
        },
        error: (err) => {
          this.isLoading$.next(false);
          this.feedbackState.setFeedback(err.error.error.error, 'ERROR');
        },
      }),
    );
  }

  updateSelectPayee(payee: Payee, mode: 'add' | 'remove' | 'edit') {
    if (mode === 'add') {
      this.selectedPayee$.next([...this.selectedPayeeSnapshot(), payee]);
    }

    if (mode === 'remove') {
      this.selectedPayee$.next(
        this.selectedPayeeSnapshot().filter((p) => p.id !== payee.id),
      );
    }

    if (mode === 'edit') {
      this.selectedPayee$.next(
        this.selectedPayeeSnapshot().filter((p) => p.id !== payee.id),
      );

      this.selectedPayee$.next([...this.selectedPayeeSnapshot(), payee]);
    }

    const updatedRecentPayee = this.state().recentPayee.map((recentPayee) => {
      if (recentPayee.id === payee.id) {
        return {
          ...recentPayee,
          checked: (mode === 'add' || mode === 'edit') && !recentPayee.checked,
        };
      }

      return recentPayee;
    });
    this.recentPayee$.next(updatedRecentPayee);

    if (mode === 'edit') {
      this.feedbackState.setFeedback('Payee edited successfully!', 'SUCCESS');
    }

    if (mode === 'add' && payee.id !== this.artistId) {
      this.feedbackState.setFeedback('Payee added successfully!', 'SUCCESS');
    }

    if (mode === 'remove' && payee.id !== this.artistId) {
      this.feedbackState.setFeedback('Payee removed successfully!', 'SUCCESS');
    }

    this.calculatePercentages();
  }

  updatePercentage(payee: Payee, percentage: number) {
    const updatedSelectedPayee = this.state().selectedPayee.map((p) => {
      if (p.id === payee.id) {
        return {
          ...p,
          percentage,
          fixedPercentage: percentage > 0,
        };
      }

      return p;
    });

    this.selectedPayee$.next(updatedSelectedPayee);
    this.calculatePercentages();
  }

  calculatePercentages() {
    const countSelectedPayee = this.selectedPayeeSnapshot().filter(
      (p) => !p.fixedPercentage,
    ).length;

    const selectedPayeeFixed = this.selectedPayeeSnapshot().filter(
      (payee) => payee.fixedPercentage,
    );
    const countPercentageFixed = selectedPayeeFixed.reduce(
      (acc, payee) => acc + Number(payee.sharePercentage ?? 0),
      0,
    );

    const updatedSelectedPayee = this.state()
      .selectedPayee.filter((payee) => !payee.fixedPercentage)
      .map((payee) => {
        payee.sharePercentage = Math.round(
          (100 - countPercentageFixed) / countSelectedPayee,
        );

        return payee;
      });

    this.selectedPayee$.next([...updatedSelectedPayee, ...selectedPayeeFixed]);

    this.calculateFrePercentage();
  }

  calculateFrePercentage() {
    const countFrePercentage = this.state()
      .selectedPayee.filter((p) => !p.fixedPercentage)
      .reduce((acc, payee) => acc + Number(payee.sharePercentage ?? 0), 0);

    this.freePercentage$.next(countFrePercentage);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
