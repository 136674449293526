<div
  class="select"
  [class.select--white]="useLightStyle"
  [class.select--uneditabble]="!editabble"
  [class.select--disabled]="disabled || loading"
  [class.select--opened]="opened">
  <div
    class="selected-item"
    [class.selected-item--invalid]="invalid"
    [class.selected-item--selected]="hasOptionsSelected && !loading"
    [class.selected-item--error]="useErrorStyle && !loading"
    [class.selected-item--success]="useSuccessStyle && !loading"
    [class.selected-item--small]="variation === selectVariationEnum.Small"
    (click)="toggleContent()">
    <div class="select-option">
      @if (optionSelected && optionSelected.image && !multiple && !loading) {
        <div class="select-option__image">
          <img [src]="optionSelected.image" [alt]="optionSelected.title" />
        </div>
      }

      <div class="select-option__main">
        <header>
          <div class="select-option__main-title">
            @if (loading) {
              <div>Loading...</div>
            } @else {
              <div
                [title]="
                  optionsSelected
                    ? (optionsSelected | extractTitle: placeholder)
                    : placeholder
                "
                [innerHTML]="
                  optionsSelected
                    ? (optionsSelected
                      | extractTitle: placeholder
                      | boldCurrency)
                    : placeholder
                "></div>
            }
          </div>
          @if (optionSelected && optionSelected.version && !multiple) {
            <div class="select-option__main-version">
              ({{ optionSelected.version }})
            </div>
          }
        </header>

        @if (optionSelected && optionSelected.description && !multiple) {
          <div class="select-option__main-description">
            {{ optionSelected.description }}
          </div>
        }
      </div>
    </div>

    <div class="selected-item__icon">
      @if (loading) {
        <mzic-spinner />
      } @else {
        <mzic-svg class="w15" src="assets/icons/mzic-arrow-down.svg" />
      }
    </div>
  </div>

  @if (opened) {
    <div class="select__container">
      <div
        class="select__content"
        [class.select__content--top]="
          orientation === selectOrientationEnum.Top
        ">
        @if (searchable) {
          <mzic-select-search
            [loading]="showSearchLoading"
            (clearTextChange)="handleClearTextChange($event)"
            (searchTextChange)="
              handleSearchTextChange($event)
            "></mzic-select-search>
        }
        <!-- Título das opções (opcional) -->
        @if (optionsLabel) {
          <p class="select__content-label">{{ optionsLabel }}</p>
        }
        <section
          class="select__content-wrapper"
          [class.select__content-wrapper--extra-padding]="extraPadding">
          <div class="select__content-scroll">
            <ng-content select="[content]"></ng-content>
          </div>
          <div class="observer"></div>
        </section>
      </div>
    </div>
  }
</div>
