import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  input,
  InputSignal,
  model,
  OnDestroy,
  OnInit,
  output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { MzicDurationPipe } from '@mzic/mzic-utils';
import { NgxMaskDirective } from 'ngx-mask';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { MzicSvgComponent } from '../mzic-svg/mzic-svg.component';
import { MzicToggleComponent } from '../mzic-toggle/mzic-toggle.component';
import { PreviewIntervalTime } from './mzic-audio-player.model';
import {
  MzicAudioPlayerCtrl,
  MzicAudioPlayerFile,
} from './mzic-audio-player.type';
import { getAudioData, linearPath } from './waveform-path';

@Component({
  selector: 'mzic-audio-player',
  standalone: true,
  imports: [
    CommonModule,
    MzicToggleComponent,
    MzicSvgComponent,
    ReactiveFormsModule,
    NgxMaskDirective,
    TranslocoModule,
    MzicDurationPipe,
  ],
  providers: [
    provideTranslocoScope({
      scope: 'mzic-audio-player',
      alias: 'player',
    }),
  ],
  templateUrl: './mzic-audio-player.component.html',
  styleUrls: ['./mzic-audio-player.component.scss'],
})
export class MzicAudioPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('audioPlayer') audioPlayer!: ElementRef<HTMLAudioElement>;
  @ViewChild('svgContainer') svgContainer!: ElementRef<HTMLDivElement>;

  width = model(0);
  type = model('mirror');
  samples = model(150);
  height = input(100);
  colorDefault = input('#545454');
  colorActive = input('#9655ff');
  paths = input([{ d: 'V', sy: 0, x: 50, ey: 100 }]);
  showAudioPlayer = input(false);
  showInputFile = input(false);
  showPreviewButton = input(false);
  showDuration = input(false);
  previewDuration = input(15);
  showStartPauseButton = input(false);
  ctrl: InputSignal<Subject<MzicAudioPlayerCtrl> | undefined> = input();

  mzicError = output<ErrorEvent>();
  mzicPlay = output<Event>();
  mzicPlaying = output<Event>();
  mzicPause = output<Event>();
  mzicTimeUpdate = output<Event>();
  mzicCanPlay = output<Event>();
  mzicLoadedMetaData = output<Event>();
  mzicLoadStart = output<Event>();
  mzicEnded = output<Event>();
  mzicLoadedData = output<Event>();
  mzicPreviewInterval = output<PreviewIntervalTime>();

  protected startTimeControl = new FormControl<string | null>('0');

  private _destroyRef = inject(DestroyRef);
  private windowResize = new Subject<void>();

  fileName = '';
  audioPercent = 0;
  audioPath = '';
  audioData: any;
  loading = false;
  drawPath = '';
  ctrlSubscription$?: Subscription;

  xPosition = 0;
  svgContainerWidth = 0;
  duration = 0;

  showPreview = false;
  audioIsPlaying = false;
  startPlayInsidePreview!: number;
  startPlayTimeInsidePreview!: number;

  @HostListener('window:resize', ['$event.target'])
  public onResize() {
    this.windowResize.next();
  }

  async ngOnInit() {
    this.startTimeControl.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value) => {
        if (value) this.setPreviewStartTime(value);
      });

    if (this.ctrl()) {
      this.ctrlSubscription$ = this.ctrl()?.subscribe((action) =>
        this.manageCtrlAction(action),
      );
    }

    this.windowResize
      .asObservable()
      .pipe(debounceTime(100))
      .subscribe(() => this.windowResized());

    const element = document.getElementById('previewContainer');
    if (element) {
      this.dragElement(element);
    }
  }

  dragElement(element: HTMLElement) {
    let pos1 = 0;
    // let pos2 = 0;
    let pos3 = 0;
    // let pos4 = 0;

    const closeDragElement = () => {
      document.onmouseup = null;
      document.onmousemove = null;

      this.setPreviewStartTime();
    };

    const dragMouseDown = (e: any) => {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      // pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    };

    const elementDrag = (e: any) => {
      e = e || window.event;
      e.preventDefault();

      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      // pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      // pos4 = e.clientY;
      // set the element's new position:
      // element.style.top = element.offsetTop - pos2 + 'px';

      this.xPosition = element.offsetLeft - pos1;
      if (this.xPosition < 0) {
        this.xPosition = 0;
      }

      const elementRect = element.getBoundingClientRect();
      const elementWidth = elementRect.width;

      if (this.xPosition + elementWidth >= this.svgContainerWidth) {
        this.xPosition = this.svgContainerWidth - elementWidth;
      }

      const durationPosition = this.pxToTime(this.xPosition);
      this.setTime(durationPosition);
      element.style.left = this.xPosition + 'px';
    };

    element.onmousedown = dragMouseDown;
  }

  ngOnDestroy() {
    this.ctrlSubscription$?.unsubscribe();
  }

  setPreviewStartTime(time?: string) {
    const element = document.getElementById('previewContainer') as HTMLElement;
    let previewTime = 0;
    if (time) {
      previewTime = parseInt(time);
    } else {
      previewTime = parseInt(this.pxToTime(this.xPosition).toFixed(2), 10);
      this.startTimeControl.setValue(previewTime.toString().padStart(4, '0'));
    }

    element.style.left = this.timeToPx(previewTime) + 'px';
    const duration = this.audioPlayer?.nativeElement?.duration;
    const tempEndTime = previewTime + this.previewDuration();
    const endTime = tempEndTime < duration ? tempEndTime : duration;

    this.startPlayTimeInsidePreview = previewTime;
    this.startPlayInsidePreview = previewTime / duration;
    this.mzicPreviewInterval.emit({ startTime: previewTime, endTime: endTime });
  }

  windowResized() {
    this.drawWave();
  }

  setComponentsProps() {
    const svgContainer = document.getElementById('svgContainer');
    if (svgContainer && this.duration) {
      const svgContainerRect = svgContainer.getBoundingClientRect();
      this.svgContainerWidth = svgContainerRect.width || 0;
    }
  }

  buttonPlay() {
    if (this.showPreview) this.setTime(this.startPlayTimeInsidePreview);
    this.ctrl()?.next({ action: 'play' });
  }

  buttonPause() {
    this.ctrl()?.next({ action: 'pause' });
  }

  enablePreview(enable: boolean) {
    this.showPreview = enable;
  }

  setPreviewStart(time: string) {
    const t = time.split(':');
    const minutes = parseInt(t[0]);
    const seconds = parseInt(t[1]);
    let timeSeconds = minutes * 60 + seconds;

    if (timeSeconds >= this.duration - this.previewDuration()) {
      timeSeconds = this.duration - this.previewDuration();
    }

    this.setTime(timeSeconds);
    this.movePreviewStart(timeSeconds);
  }

  pxToTime(px: number) {
    return (px * this.duration) / this.svgContainerWidth;
  }

  timeToPx(duration: number) {
    return (duration * this.svgContainerWidth) / this.duration;
  }

  movePreviewStart(duration: number) {
    const position = this.timeToPx(duration);
    const element = document.getElementById('previewContainer');
    if (element) {
      element.style.left = position + 'px';
    }
  }

  setTime(duration: number) {
    this.audioPlayer.nativeElement.currentTime = duration;
  }

  setAudioPercent(percent: number) {
    this.audioPercent = percent;
  }

  setFile(file: MzicAudioPlayerFile) {
    this.audioPath = file.path;
    this.drawWave();
  }

  manageCtrlAction(ctrl: MzicAudioPlayerCtrl) {
    switch (ctrl.action) {
      case 'setFile':
        if (ctrl.file) {
          this.setFile(ctrl.file);
        }
        break;

      case 'setPercent':
        this.setAudioPercent(ctrl.data as number);
        break;

      case 'setPreviewStart':
        this.setPreviewStart(ctrl.data as string);
        break;

      case 'enablePreview':
        this.enablePreview(ctrl.data as boolean);
        break;

      case 'play':
        this.play();
        break;

      case 'pause':
        this.pause();
        break;
    }
  }

  async drawWave() {
    this.audioData = await getAudioData(this.audioPath);
    this.duration = this.audioData?.duration || 0;

    setTimeout(() => {
      this.width.set(
        this.svgContainer.nativeElement.getBoundingClientRect().width,
      );

      this.drawPath = linearPath(this.audioData, {
        samples: this.samples(),
        width: this.width(),
        height: this.height(),
        type: this.type(),
        top: 0,
        paths: this.paths(),
      });

      const element = document.querySelector('#audioSvg path');
      if (element) {
        setTimeout(() => element.setAttribute('d', this.drawPath), 100);

        this.setComponentsProps();
        this.setPreviewWidth();
      }
    }, 10);
  }

  setPreviewWidth() {
    const previewContainer = document.getElementById('previewContainer');

    if (previewContainer) {
      const width =
        (this.previewDuration() * this.svgContainerWidth) / this.duration + 4; // +4 de border de 2px pra cada lado

      if (this.previewDuration() >= this.duration) {
        previewContainer.style.width = `100%`;
      } else {
        previewContainer.style.width = `${width}px`;
      }
    }
  }

  async onFileChange(event: any) {
    const file = event.target.files[0];
    if (event?.target?.files[0]) {
      this.setFile({ name: file.name, path: URL.createObjectURL(file) });
    }
  }

  play() {
    this.audioPlayer.nativeElement.play();
    this.audioIsPlaying = true;
  }

  pause() {
    this.audioPlayer.nativeElement.pause();
    this.audioIsPlaying = false;
  }

  audioError(event: ErrorEvent) {
    this.mzicError.emit(event);
  }
  audioPlay(event: Event) {
    this.mzicPlay.emit(event);
  }
  audioPlaying(event: Event) {
    this.mzicPlaying.emit(event);
  }
  audioPause(event: Event) {
    this.mzicPause.emit(event);
  }
  audioTimeUpdate(event: Event, element: MzicAudioPlayerComponent) {
    const audioData = element.audioData as AudioBuffer;
    const audioPlayer = element.audioPlayer.nativeElement as HTMLAudioElement;

    if (audioData?.duration && audioPlayer) {
      const currentTime = audioPlayer.currentTime;
      this.audioPercent = currentTime / audioData?.duration;

      // Essa condição verifica se o commponent está habilitado para fazer o preview
      // e se o tempo atual tempo atual da música é maior que o tempo de início do preview + a duração do preview.
      // Exemplo: o usuário setou a música para começar em 10 segundos e o tempo do preview é de 15 segundos,
      // a música só tocará de 10 segundos até 25 segundos (10+15), após isso, a música precisa ser pausada para não
      // ultrapassar o tempo de preview máximo.
      if (
        this.showPreview &&
        currentTime > this.startPlayTimeInsidePreview + this.previewDuration()
      ) {
        this.ctrl()?.next({ action: 'pause' });
      }
    }

    this.mzicTimeUpdate.emit(event);
  }

  audioCanplay(event: Event) {
    this.mzicCanPlay.emit(event);
  }

  audioLoadedMetadata(event: Event) {
    this.mzicLoadStart.emit(event);
  }

  audioLoadstart(event: Event) {
    this.mzicLoadStart.emit(event);
  }

  audioEnded(event: Event) {
    this.mzicEnded.emit(event);
    this.audioIsPlaying = false;
  }

  audioLoadedData(event: Event) {
    this.mzicLoadedData.emit(event);
  }
}
