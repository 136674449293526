export enum TransactionStatusEnum {
  PROCESSANDO = 'Processando',
  COMPLETO = 'Completo',
  CANCELADO = 'Cancelado',
}

export const TransactionStatusColors: Record<string, string> = {
  [TransactionStatusEnum.PROCESSANDO]: 'purple',
  [TransactionStatusEnum.COMPLETO]: 'green',
  [TransactionStatusEnum.CANCELADO]: 'red',
};
