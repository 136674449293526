import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'mzic-table-pagination',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mzic-table-pagination.component.html',
  styleUrls: ['./mzic-table-pagination.component.scss'],
})
export class MzicTablePaginationComponent implements OnInit, OnChanges {
  @Output() paginationChanged = new EventEmitter<string>();
  @Input() totalItemsCount = 0;
  @Input() pageSize = 1;
  @Input() pageNumber = 1;

  // pageValues = ['1', '2', '3', '...', '10'];
  pageValues = ['1'];
  currentPageValue = '1';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const pageNumber =
      this.activatedRoute.snapshot.queryParamMap.get('pageNumber');
    if (pageNumber && parseInt(pageNumber) > 0) {
      this.currentPageValue = pageNumber;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const pageSize = changes['pageSize'];
    const pageNumber = changes['pageNumber'];
    const totalItemsCount = changes['totalItemsCount'];

    if (
      pageSize?.currentValue !== pageSize?.previousValue ||
      pageNumber?.currentValue !== pageNumber?.previousValue ||
      totalItemsCount?.currentValue !== totalItemsCount?.previousValue
    ) {
      this.calculatePagination();
    }

    setTimeout(() => {
      this.currentPageValue = pageNumber.currentValue.toString();
    }, 200);
    this.updateQueryParams(this.pageNumber.toString());
  }

  calculatePagination() {
    if (this.totalItemsCount && this.totalItemsCount > 0 && this.pageSize > 0) {
      const totalPages = Math.ceil(this.totalItemsCount / this.pageSize);
      this.pageValues = [];
      for (let i = 1; i <= totalPages; i++) {
        this.pageValues.push(i.toString());
      }
      this.filterPageValues();
    }
  }

  filterPageValues() {
    if (this.pageValues.length < 5) {
      return;
    }

    const values = this.pageValues.map((v) => parseInt(v));
    const newArray: number[] = [1];

    const activeIndex = values.findIndex((v) => v === this.pageNumber);

    if (values[activeIndex - 1] && values[activeIndex - 1] !== 1) {
      newArray.push(values[activeIndex - 1]);
    }

    if (newArray.findIndex((v) => v === this.pageNumber) === -1) {
      newArray.push(this.pageNumber);
    }

    if (
      values[activeIndex + 1] &&
      values[activeIndex + 1] !== values[values.length - 1]
    ) {
      newArray.push(values[activeIndex + 1]);
    }

    if (newArray.findIndex((v) => v === values[values.length - 1]) === -1) {
      newArray.push(values[values.length - 1]);
    }

    newArray.sort((a, b) => a - b);
    const uniqueNewArray = [...new Set(newArray)];

    const arrayString: string[] = [];

    for (let i = 0; i < uniqueNewArray.length; i++) {
      arrayString.push(uniqueNewArray[i].toString());

      if (
        i < uniqueNewArray.length - 1 &&
        uniqueNewArray[i + 1] !== uniqueNewArray[i] + 1
      ) {
        arrayString.push('...');
      }
    }

    this.pageValues = arrayString;
  }

  selectPagination(pageValue: string) {
    // TODO: implementar opção dos 3 pontinhos??
    if (pageValue !== '...') {
      this.currentPageValue = pageValue;
      this.paginationChanged.emit(pageValue);
      this.updateQueryParams(pageValue);
    }
  }

  selectPrevPagination() {
    const index = this.pageValues.indexOf(this.currentPageValue);
    const prevValue = this.pageValues[index - 1];
    this.selectPagination(prevValue);
  }

  selectNextPagination() {
    const index = this.pageValues.indexOf(this.currentPageValue);
    const nextValue = this.pageValues[index + 1];
    this.selectPagination(nextValue);
  }

  updateQueryParams(pageNumber: string) {
    const queryParams: Params = { pageNumber };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
