import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import { GetTimezone } from '@mzic/mzic-interfaces';
import { MzicArtistLocalService } from '../mzic-artist-local/mzic-artist-local.service';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  private apiUrl = environment.apiUrl;

  constructor(
    private readonly _http: HttpClient,
    private mzicArtistLocalService: MzicArtistLocalService,
  ) {}

  getTimezone() {
    const headers = new HttpHeaders().append('TEAM-ID', this.getTeamId());
    return this._http.get<GetTimezone>(
      `${this.apiUrl}/api/distribution/timezone/v2/find`,
      { headers },
    );
  }

  private getTeamId() {
    return this.mzicArtistLocalService.getTeamId();
  }
}
