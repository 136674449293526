import { RoyaltySplitList } from './royalty-split-list.interface';

export interface RoyaltySplitListResponse {
  timestamp: string;
  status: string;
  data: RoyaltySplitList;
}

export function createEmptyRoyaltySplitList(): RoyaltySplitList {
  return {
    content: [],
    pageable: {
      sort: {
        empty: true,
        sorted: false,
        unsorted: true,
      },
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: true,
      unpaged: true,
    },
    totalElements: 0,
    totalPages: 0,
    last: true,
    size: 0,
    number: 0,
    sort: {
      empty: true,
      sorted: false,
      unsorted: true,
    },
    numberOfElements: 0,
    first: true,
    empty: true,
  };
}
