import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'mzic-toggle',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './mzic-toggle.component.html',
  styleUrls: ['./mzic-toggle.component.scss'],
})
export class MzicToggleComponent {
  @Input() size: 'default' | 'small' | 'mini' = 'default';
  @Output() changed = new EventEmitter<boolean>();
  @Input() checked = false;
  @Input() disabled = false;

  onModelChange(e: boolean) {
    if (this.disabled) return;

    this.checked = e;
    this.changed.emit(e);
  }
}
