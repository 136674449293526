import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boldCurrency',
  standalone: true,
})
export class BoldCurrencyPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    // Encontra moeda americana
    const currencyRegex = /\$\d+(\.\d{1,2})?/g;

    return value.replace(currencyRegex, (match) => `<b>${match}</b>`);
  }
}
