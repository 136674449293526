import { Payee } from './payee.interface';

export interface PayeeResponse {
  timestamp: string;
  status: string;
  data: Payee[];
}

export function createEmptyPayee(): Payee[] {
  return [
    {
      id: 0,
      artistExternalId: 0,
      name: '',
      photo: '',
      email: '',
      artistAppleId: '',
      artistSpotifyId: '',
      uuidViberate: '',
      fullName: '',
      socialSecurity: '',
    },
  ];
}
