export enum TransactionTypeEnum {
  PRIMARY_MARKET = 'PRIMARY_MARKET',
  SECONDARY_MARKET = 'SECONDARY_MARKET',
  ROYALTY_SPLIT = 'ROYALTY_SPLIT',
  ROYALTY_DISTRIBUTION = 'ROYALTY_DISTRIBUTION',
  CASH_OUT = 'CASH_OUT',
  MONEY_EXCHANGE = 'MONEY_EXCHANGE',
  DEPOSIT = 'DEPOSIT',
  ARTIST_SHARE_TRANSFER = 'ARTIST_SHARE_TRANSFER',
}

export const TransactionTypeImages: Record<TransactionTypeEnum, string> = {
  [TransactionTypeEnum.PRIMARY_MARKET]: 'assets/icons/market.svg',
  [TransactionTypeEnum.SECONDARY_MARKET]: 'assets/icons/market.svg',
  [TransactionTypeEnum.ROYALTY_SPLIT]: 'assets/icons/royalty.svg',
  [TransactionTypeEnum.ROYALTY_DISTRIBUTION]: 'assets/icons/royalty.svg',
  [TransactionTypeEnum.CASH_OUT]: 'assets/icons/cash_out.svg',
  [TransactionTypeEnum.MONEY_EXCHANGE]: 'assets/icons/money_exchange.svg',
  [TransactionTypeEnum.DEPOSIT]: 'assets/icons/deposit.svg',
  [TransactionTypeEnum.ARTIST_SHARE_TRANSFER]: 'assets/icons/artist_share.svg',
};
