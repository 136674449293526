import { Route } from '@angular/router';
import { AssetComponent } from './pages/asset/asset.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';

export const mzicIoRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'en/home',
    pathMatch: 'full',
  },
  { path: ':lang/home', component: ComingSoonComponent },
  { path: ':lang/asset/MZIC1', component: AssetComponent }, // Depois vai virar um {id}
];
