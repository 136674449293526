export interface Country {
  id: number;
  name: string;
  flagUrl: string;
  isoAlphaTwo: string;
  isoAlphaThree: string;
  isoCode: string;
  currencyType: string;
  revelatorCountryId: number;
}

export function createEmptyCountry(): Country[] {
  return [
    {
      id: 0,
      name: '',
      flagUrl: '',
      isoAlphaTwo: '',
      isoAlphaThree: '',
      isoCode: '',
      currencyType: '',
      revelatorCountryId: 0,
    },
  ];
}
