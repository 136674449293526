import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  LabelResource,
  LabelResourceData,
  ResponseInterface,
} from '@mzic/mzic-interfaces';
import { Observable } from 'rxjs';
import { MzicArtistLocalService } from '../../mzic-artist-local/mzic-artist-local.service';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  private apiUrl = environment.apiUrl;

  constructor(
    private readonly _http: HttpClient,
    private mzicArtistLocalService: MzicArtistLocalService,
  ) {}

  getLabels(): Observable<ResponseInterface<LabelResourceData>> {
    return this._http.get<ResponseInterface<LabelResourceData>>(
      `${this.apiUrl}/api/distribution/label/v2/find`,
    );
  }

  create(label: LabelResource): Observable<ResponseInterface<number>> {
    return this._http.post<ResponseInterface<number>>(
      `${this.apiUrl}/api/distribution/label/v2/create`,
      label,
    );
  }

  update(label: LabelResource): Observable<ResponseInterface<number>> {
    return this._http.put<ResponseInterface<number>>(
      `${this.apiUrl}/api/distribution/label/v2/update`,
      label,
    );
  }
}
