import { Pipe, PipeTransform } from '@angular/core';
import { Interval, isEqual, isWithinInterval } from 'date-fns';

@Pipe({
  name: 'isDateBetween',
  standalone: true,
})
export class IsDateBetweenPipe implements PipeTransform {
  transform(date: Date, start: Date | null, end: Date | null): boolean {
    if (start) {
      if (isEqual(date, start)) return false;
    }

    if (end) {
      if (isEqual(date, end)) return false;
    }

    if (start && end) {
      const interval: Interval = {
        start, end
      }

      return isWithinInterval(date, interval);
    }

    return false
  }
}
