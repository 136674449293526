import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate',
  standalone: true,
})
export class CustomDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string): string | null {
    return (
      this.datePipe.transform(value, 'MMM dd, yyyy')?.toUpperCase() ?? null
    );
  }
}
