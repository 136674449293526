import { Store } from './store.interface';

export interface StoreResponse {
  timestamp: string;
  status: string;
  data: Store[];
}

export function createEmptyStore(): Store[] {
  return [
    {
      id: 0,
      iconUrl: '',
      name: '',
      active: false,
      tierPriceSale: {
        lsTierPriceRelease: [],
        lsTierPriceTrack: [],
      },
      lsMonetizationPolicy: [],
      dependencyStore: {
        id: 0,
        tierPriceReleaseId: 0,
        tierPriceTrackId: 0,
        monetizationPolicyId: 0,
      },
      lsToolTip: [],
    },
  ];
}
