import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
// import { MzicTooltipService } from '@mzic/mzic-components';

@Directive({
  selector: '[mzicCopy]',
  standalone: true,
})
export class MzicCopyDirective {
  @Input('mzicCopy')
  public payload!: string;

  @Output()
  public readonly copied: EventEmitter<string> = new EventEmitter<string>();

  // constructor(private mzicTooltipService: MzicTooltipService) {}

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();

    const listener = (e: ClipboardEvent) => {
      if (e?.clipboardData) {
        e.clipboardData.setData('text', this.payload);
        e.preventDefault();
        this.copied.emit(this.payload);
      }
    };

    if (event.target) {
      this.showTooltip(event.target as HTMLElement);
    }

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  }

  showTooltip(target: HTMLElement) {
    /// @@@@@@ todo: implementar com o overlay
    // this.mzicPopoverService.openTooltip(target, 'Copied');
    // this.mzicTooltipService.open();
  }
}
