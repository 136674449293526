<div
  class="explain"
  (mouseover)="isOpen = true"
  (mouseleave)="isOpen = false"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin">
  <mzic-svg [class]="classes" [src]="iconUrl"></mzic-svg>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen">
  <div class="explain__tooltip">
    <div [innerHTML]="text"></div>
  </div>
</ng-template>
