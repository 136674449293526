import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'decimalWithSpan',
  standalone: true,
})
export class DecimalWithSpanPipe implements PipeTransform {
  transform(value: string, separator: '.' | ',' | ['.', ',']): SafeHtml {
    const split = value.split(separator as string);
    if (split.length < 2) {
      return value;
    }
    return split[0] + `<span>${separator}${split[1]}</span>`;
  }
}
