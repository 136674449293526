import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mzic-progress-circle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mzic-progress-circle.component.html',
  styleUrls: ['./mzic-progress-circle.component.scss'],
})
export class MzicProgressCircleComponent implements OnInit {
  @Input() width = 150;
  @Input() strokeWidth = 3;
  @Input() inactiveColor = '#545454';
  @Input() activeColor = '#9976FF';
  @Input() percent!: EventEmitter<number>;

  dashArray = 440;
  dashOffset = this.dashArray;

  ngOnInit(): void {
    this.setDashOffset();

    if (this.percent) {
      this.percent.subscribe((number) => this.setPercent(number));
    }
  }

  setDashOffset() {
    switch (this.width) {
      case 42:
        this.dashArray = 123;
        break;

      case 150:
        this.dashArray = 440;
        break;

      default:
        break;
    }

    this.dashOffset = this.dashArray;
  }

  setPercent(percent: number) {
    if (percent < 0) {
      percent = 0;
    }
    if (percent > 100) {
      percent = 100;
    }
    this.dashOffset = this.dashArray - (this.dashArray * percent) / 100;
  }
}
