import { PayeeLanguages } from './payee-languages.interface';

export interface PayeeLanguagesResponse {
  timestamp: string;
  status: string;
  data: PayeeLanguages[];
}

export function createEmptyPayeeLanguages(): PayeeLanguages[] {
  return [
    {
      cod: 0,
      language: '',
      region: '',
      countryCode: '',
      displayLanguage: '',
      active: false,
      name: '',
    },
  ];
}
