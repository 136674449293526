import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import { ResponseInterface } from '@mzic/mzic-interfaces';
import { Observable } from 'rxjs';

interface ReportTypeItem {
  code: string;
  name: string;
  needAskCurrency: boolean;
  imgHash: string;
}

@Injectable({
  providedIn: 'root',
})
export class RoyaltyRunResourceService {
  private apiUrl = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  getReportTypes(): Observable<ResponseInterface<ReportTypeItem[]>> {
    return this._http.get<ResponseInterface<ReportTypeItem[]>>(
      `${this.apiUrl}/api/royalty/run/v1/find/report-type`,
    );
  }
}
