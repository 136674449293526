var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create, destroy, EVENT, } from 'zoid/dist/zoid.frameworks.frame';
import { getContainerCss } from './components/loader/css';
import { loaderComponentHtml } from './components/loader/html';
import { getCSS } from './components/modal/css';
import { getModalWrapper } from './components/modal/html';
import { isRunningInCordova, openInCordovaInAppBrowser } from './utils/cordova';
import { openOauthUrlInCordovaBrowser } from './utils/oauth';
import { once } from './utils/once';
import { adaptPluggyConnectProps, } from './utils/props';
import { getSdkVersion } from './version';
// the URL where the widget component is being hosted
const CONNECT_PRODUCTION_URL = 'https://connect.pluggy.ai';
let zoidComponentInstance;
// reference to the container uid, generated once it's rendered
let pluggyConnectContainerUid;
/**
 * Helper to wrap container class names with current container UID
 * @param className
 */
function containerCssClassName(className) {
    return `${pluggyConnectContainerUid}_${className}`;
}
/**
 * Method to be executed from both the child and parent components, to execute the
 * handshake between both so the parent can render the child.
 *
 * @returns a singleton ZoidComponent reference, that will take care of
 * wrapping and rendering the actual ZoidComponentInstance (this
 * should be instantiated only once per page/context).
 */
export function initialize() {
    if (!zoidComponentInstance) {
        zoidComponentInstance = create({
            // The html tag used to render the component
            tag: 'pluggy-connect-widget',
            // The url of the page that will show in the iframe or popup, when someone includes the component on their site
            url: ({ props }) => {
                const { url: urlByProp = CONNECT_PRODUCTION_URL } = props;
                return urlByProp;
            },
            dimensions: {
                width: '320px',
                height: '568px',
            },
            props: {
                connectToken: {
                    type: 'string',
                },
                url: {
                    type: 'string',
                    required: false,
                },
                includeSandbox: {
                    type: 'boolean',
                    required: false,
                },
                allowConnectInBackground: {
                    type: 'boolean',
                    required: false,
                },
                allowFullscreen: {
                    type: 'boolean',
                    required: false,
                },
                updateItem: {
                    type: 'string',
                    required: false,
                },
                connectorTypes: {
                    type: 'array',
                    required: false,
                },
                connectorIds: {
                    type: 'array',
                    required: false,
                },
                countries: {
                    type: 'array',
                    required: false,
                },
                selectedConnectorId: {
                    type: 'number',
                    required: false,
                },
                language: {
                    type: 'string',
                    required: false,
                },
                theme: {
                    type: 'string',
                    required: false,
                },
                moveSecurityData: {
                    type: 'string',
                    required: false,
                },
                products: {
                    type: 'array',
                    required: false,
                },
                sdkVersion: {
                    type: 'array',
                    required: false,
                    default: () => getSdkVersion(),
                },
                _runningInCordova: {
                    type: 'boolean',
                    required: false,
                    default: () => isRunningInCordova(),
                },
                onSuccessProp: {
                    type: 'function',
                    required: false,
                },
                onErrorProp: {
                    type: 'function',
                    required: false,
                },
                onOpenProp: {
                    type: 'function',
                    required: false,
                },
                onHideProp: {
                    type: 'function',
                    required: false,
                },
                onCloseProp: {
                    type: 'function',
                    required: false,
                },
                onEventProp: {
                    type: 'function',
                    required: false,
                },
            },
            attributes: {
                iframe: {
                    scrolling: 'no',
                    title: 'Pluggy',
                },
                popup: {
                    scrolling: 'no',
                    title: 'Pluggy',
                },
            },
            prerenderTemplate({ doc, props }) {
                const htmlNew = doc.createElement('html');
                htmlNew.innerHTML = loaderComponentHtml({
                    nonce: props.cspNonce,
                });
                return htmlNew;
            },
            containerTemplate({ doc, dimensions: { height, width }, close, uid, frame, prerenderFrame, event, props, }) {
                if (!prerenderFrame || !frame) {
                    throw new Error('Unexpected state: prerenderFrame or frame not defined');
                }
                // set container uid global reference
                pluggyConnectContainerUid = uid;
                const container = doc.createElement('div');
                const connectTheme = props.theme || 'light';
                container.id = uid;
                container.innerHTML = getModalWrapper(uid, connectTheme);
                const frameContainer = container.querySelector(`.${uid}_container`);
                if (!frameContainer) {
                    throw new Error('Unexpected state: not found frame container');
                }
                const visibleClassName = containerCssClassName('visible');
                const invisibleClassName = containerCssClassName('invisible');
                const prerenderFrameClassName = containerCssClassName('prerender-frame');
                frameContainer.appendChild(frame);
                frameContainer.appendChild(prerenderFrame);
                // Add styles
                const style = doc.createElement('style');
                // if allowFullscreen is not defined, default to true
                const allowFullscreenOrDefault = props.allowFullscreen !== undefined ? props.allowFullscreen : true;
                style.innerHTML = [
                    getCSS({
                        uid,
                        themeColor: 'fafafa',
                        height,
                        width,
                        allowFullscreen: allowFullscreenOrDefault,
                    }),
                    getContainerCss({ uid }),
                ].join('\n');
                container.appendChild(style);
                prerenderFrame.classList.add(prerenderFrameClassName);
                prerenderFrame.classList.add(visibleClassName);
                frame.classList.add(invisibleClassName);
                event.on(EVENT.RENDERED, () => {
                    // hide prerender frame, display content frame
                    prerenderFrame.classList.remove(visibleClassName);
                    prerenderFrame.classList.add(invisibleClassName);
                    frame.classList.remove(invisibleClassName);
                    frame.classList.add(visibleClassName);
                });
                // Remove scrolling from document body when modal is open (ie. visible)
                const modalVisibleClassName = containerCssClassName('has-modal-visible');
                event.on(EVENT.DISPLAY, () => {
                    // modal displayed, add visible class & call onOpen callback
                    document.body.classList.add(modalVisibleClassName);
                    const { onOpenProp } = props;
                    onOpenProp === null || onOpenProp === void 0 ? void 0 : onOpenProp();
                });
                // Wrap onCloseProp to be called only once, per component instance, which is
                // what we want to allow.
                // This "hack" is necessary because Zoid may trigger 2 times EVENT.CLOSE event,
                // for example when removing the container element from DOM and when explicitly
                // calling close() handler at the same time.
                // Each time a new container/component is rendered, the props are re-created from scratch,
                // so this will work for all cases.
                const onClosePropWrapper = props.onCloseProp
                    ? once(props.onCloseProp)
                    : null;
                event.on(EVENT.CLOSE, () => {
                    // modal closed, remove visible class & call onClose callback
                    // Note: this callback may be called 2 times in some cases due to Zoid logic,
                    // for example when unmounting container component AND calling close() explicitly.
                    document.body.classList.remove(modalVisibleClassName);
                    onClosePropWrapper === null || onClosePropWrapper === void 0 ? void 0 : onClosePropWrapper();
                });
                // receive message from iframe
                window.addEventListener('message', (event) => {
                    const { origin, data } = event;
                    const { url: connectWebappUrl = 'https://connect.pluggy.ai', } = props;
                    if (origin !== connectWebappUrl) {
                        // origin not matching, comming from another place -> ignore message
                        return;
                    }
                    let pluggyConnectMessage;
                    try {
                        pluggyConnectMessage = JSON.parse(data);
                    }
                    catch (_a) {
                        // could not parse, data is not a valid JSON string
                        return;
                    }
                    if (pluggyConnectMessage.type === 'OAUTH_OPEN' &&
                        isRunningInCordova()) {
                        const { message: oauthUrl } = pluggyConnectMessage;
                        // in the case of pluggy-connect running in Cordova mobile native environment
                        // we must handle the Oauth popup opening manually by explicitly managing a new iframe window
                        openOauthUrlInCordovaBrowser(oauthUrl, (payload) => { var _a; return (_a = frame.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage(payload, '*'); });
                        return;
                    }
                    if (pluggyConnectMessage.type === 'LINK_OPEN' &&
                        isRunningInCordova()) {
                        const { message: externalUrl } = pluggyConnectMessage;
                        // in the case of pluggy-connect running in Cordova mobile native environment,
                        // open the link specifically in the system browser,
                        // so the main app frame is not blocked, and the user can go back to the app easily
                        openInCordovaInAppBrowser(externalUrl, '_system');
                        return;
                    }
                    if (pluggyConnectMessage.type === 'CONTINUE_IN_BACKGROUND') {
                        // hide pluggy-connect, we remove the 'has-modal-visible' class to restore the scroll
                        document.body.classList.remove(modalVisibleClassName);
                        return;
                    }
                }, false);
                // Register modal close() on 'Escape' key button press
                document.addEventListener('keydown', function escapeKeyCloseHandler(event) {
                    if (event.key !== 'Escape') {
                        return;
                    }
                    // escape key -> close (and remove 'keydown' listener)
                    close();
                    document.removeEventListener('keydown', escapeKeyCloseHandler);
                });
                return container;
            },
        });
    }
    return zoidComponentInstance;
}
export class PluggyConnect {
    constructor(props) {
        this.zoidComponent = initialize();
        // extend component props with current wrapper props
        const extendedProps = Object.assign(Object.assign({}, props), { sdkVersion: getSdkVersion() });
        // adapt props to avoid collision with Zoid component props
        // assign to private variable for reusability by React wrapper
        this.componentPropsExtendedAdapted = adaptPluggyConnectProps(extendedProps);
    }
    /**
     * Render the component using the specified component props,
     * as a modal with an iframe, appended to the page body (or the DOM uppermost element).
     *
     * @param containerElement - parent element where component should be rendered at. If not specified, will render at body root element.
     * @returns promise that resolves when rendered successfully, or throws if failed.
     */
    init(containerElement) {
        const container = containerElement ||
            (document.getElementsByTagName('body') ||
                document.getElementsByTagName('html') ||
                document.getElementsByTagName('div'))[0];
        // initialize zoid componentInstance
        this.componentInstance = this.zoidComponent(this.componentPropsExtendedAdapted);
        return this.componentInstance.render(container).catch((error) => {
            console.error(`Failed to render <PluggyConnect /> component`, error);
            throw error;
        });
    }
    /**
     * Manually cleanup the component.
     * This is not recommended, you should create the component once
     * and re-render it as needed each new time to save resources.
     */
    destroy() {
        zoidComponentInstance = undefined;
        return destroy();
    }
    /**
     * If the component is minimized, re-open it.
     * @retruns promise that resolves when re-opened successfully, or throws if failed.
     */
    show() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.componentInstance) {
                throw new Error('Failed to show <PluggyConnect /> component: component not initialized');
            }
            try {
                // open wrapper modal
                yield this.componentInstance.show();
                // add modal visible class
                const modalVisibleClassName = containerCssClassName('has-modal-visible');
                document.body.classList.add(modalVisibleClassName);
            }
            catch (error) {
                console.error(`Failed to show <PluggyConnect /> component`, error);
                throw error;
            }
        });
    }
    /**
     * Minimize the component.
     * Useful if you want to hide Connect widget after credentials have been submitted,
     * and you want to just continue connecting in background while still listening to
     * other callback events.
     */
    hide() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.componentInstance) {
                throw new Error('Failed to hide <PluggyConnect /> component: component not initialized');
            }
            try {
                // hide wrapper modal
                yield this.componentInstance.hide();
                // remove modal visible class
                const modalVisibleClassName = containerCssClassName('has-modal-visible');
                document.body.classList.remove(modalVisibleClassName);
            }
            catch (error) {
                console.error(`Failed to hide <PluggyConnect /> component`, error);
                throw error;
            }
        });
    }
}
export default PluggyConnect;
