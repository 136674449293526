export const getCSS = ({ uid, themeColor, height, width, allowFullscreen, }) => `
/* Modal Content/Box */
@keyframes ${uid}_fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

#${uid} {
  /* prevent container el from taking site space */
  height: 0;

  /* prevent special container cursors from leaking in*/
  cursor: default;
}

.${uid}_has-modal-visible {
  /* modal is opened/visible, prevent scrolling */
  overflow: hidden;
}

.${uid}_close:hover,
.${uid}_close:focus {
  color: white;
  background: #${themeColor};
  text-decoration: none;
  cursor: pointer;
}

.${uid}_modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000000000;
  background: rgba(0, 0, 0, 0.7);
}

.${uid}_modal-container {
  /* center the modal content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.${uid}_modal {
  width: ${width};
  max-width: 500px;
  height: ${height};
  max-height: 100%;
  z-index: 100;
  background: white;
  border: none;
  border-radius: 10px;

  /* modal box-shadow */
  -webkit-box-shadow: 0 4px 24px rgba(0,0,0,0.5);
  -moz-box-shadow: 0 4px 24px rgba(0,0,0,0.5);
  box-shadow: 0 4px 24px rgba(0,0,0,0.5);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}

/* dark theme */
.${uid}_modal.dark {
  background: #121212;
}

.${uid}_closed {
  display: none;
}

.${uid}_modal-content {
  width: 100%;
  height: 100%;
  overflow: hidden;

  border-radius: 10px;
}

.${uid}_modal .close-button {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 20px;
  background: black;
  color: white;
  padding: 5px 10px;
  font-size: 1.3rem;
}

.${uid}_container {
    height: 100%;
    width: 100%;
    position: relative;
}

.${uid}_container iframe {
    height: 100%;
    width: 100%;
}

${allowFullscreen
    ? `
    /** when display is narrower that 500px (mobile device)
    open modal in full screen */
    @media screen and (max-width: 500px) {
      .${uid}_modal {
        height: 100%;
        width: 100%;
        border-radius: 0px;
      }
    }`
    : ''}
`;
