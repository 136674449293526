import { openInCordovaInAppBrowser } from './cordova';
export function openOauthUrlInCordovaBrowser(url, onComplete) {
    const inAppBrowserWindow = openInCordovaInAppBrowser(url, '_blank');
    // track oauth result in navigation URL
    inAppBrowserWindow === null || inAppBrowserWindow === void 0 ? void 0 : inAppBrowserWindow.addEventListener('loadstop', (event) => {
        const { url } = event;
        const isPluggyOauthCallbackUrl = (url.startsWith('https://api.pluggy.ai/') ||
            url.startsWith('https://api.pluggy.dev/') ||
            url.startsWith('http://localhost:9090/')) &&
            url.includes('/oauthCallback.html');
        if (!isPluggyOauthCallbackUrl) {
            // not oauth result URL, ignore
            return;
        }
        const params = new URLSearchParams(url.split('?')[1]);
        const oauthResult = Object.fromEntries(params.entries());
        onComplete(oauthResult);
        inAppBrowserWindow.close();
    });
}
