import { CommonModule, ViewportScroller } from '@angular/common';
import {
  Component,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MzicSvgComponent } from '@mzic/mzic-components';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { Interpreter } from '../../../../data/interpreters-mzic1';
import { Composer, composersData } from './../../../../data/composers-mzic1';
import { interpretersData } from './../../../../data/interpreters-mzic1';

@Component({
  selector: 'mzic-page-mobile',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent, FormsModule],
  templateUrl: './mobile.component.html',
  styleUrl: './mobile.component.scss',
})
export class MobileComponent implements OnInit {
  // Simulador
  multiplier = 228.318406;
  rangeLevel = 2;
  rangeValue: any = 0;
  tokens = 0;
  total = 0;
  composersName: string[] = [];
  composers = composersData;
  interpreters = interpretersData;
  composer!: Composer;
  interpreter!: Interpreter;
  interpreterSelected = 0;
  composerSelected = 0;
  tabProjectionSelected = 1;
  tabPrincipalSelected = 'top';

  // Player
  isPlaying = false;
  composerCoverUrl = '/assets/images/main/covers/';
  audioUrl = '/assets/songs/guilherme-benuto-simone-mendes-manda-um-oi.mp3';

  currentTemplate!: TemplateRef<any>;

  // ViewChild para os templates
  @ViewChild('spotify') spotifyTemplate!: TemplateRef<any>;
  @ViewChild('youtube') youtubeTemplate!: TemplateRef<any>;
  @ViewChild('instagram') instagramTemplate!: TemplateRef<any>;
  @ViewChild('tiktok') tiktokTemplate!: TemplateRef<any>;

  @ViewChild('lyricsDialog') lyricsDialog!: TemplateRef<any>;

  expandDetailsCard = true;
  expandPerformanceCard = true;
  expandInterpreterCard = true;
  viewportScroller = inject(ViewportScroller);
  dialog = inject(MatDialog);

  currentProgress = 0;
  progressCircleDashArray = 2 * Math.PI * 45; // Circunferência do círculo
  progressCircleDashOffset = this.progressCircleDashArray;

  get tokenCount() {
    return this.tokens.toFixed(0) + ' tokens';
  }

  ngOnInit(): void {
    this.composersName = this.composers.map((item: Composer) => item.nome);
    this.composer = this.composers[0];
    this.interpreter = this.interpreters[0];

    this.updateSimulatorValues();

    // Instancia o componente Swiper
    new Swiper('.swiper', {
      modules: [Navigation],
      direction: 'horizontal',
      slidesPerView: 1,
      spaceBetween: 24,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  changeLevelSimulator(level: number) {
    this.rangeLevel = level;
    this.updateSimulatorValues();
  }

  updateSimulatorValues() {
    // Calcula o valor percentual do nível atual
    this.rangeValue = ((this.rangeLevel - 1) * (100 / 12)).toFixed(0);

    // Calcula a quantidade de tokens baseada no nível atual
    this.tokens = (this.rangeLevel / 13) * 5000;

    // Calcula o total multiplicando tokens por 310
    this.total = this.tokens * this.multiplier;
  }

  play(audio: HTMLAudioElement): void {
    if (this.isPlaying) {
      this.stop(audio);
      return;
    }

    audio.play();
    this.isPlaying = true;
    this.updateProgress(audio);

    // Detecta quando o áudio termina
    audio.onended = () => {
      this.isPlaying = false;
    };
  }

  stop(audio: HTMLAudioElement): void {
    audio.pause();
    this.isPlaying = false;
  }

  updateProgress(audio: HTMLAudioElement) {
    const update = () => {
      this.currentProgress = (audio.currentTime / audio.duration) * 100;
      this.progressCircleDashOffset =
        this.progressCircleDashArray -
        (this.currentProgress / 100) * this.progressCircleDashArray;
      if (!audio.paused) {
        requestAnimationFrame(update);
      }
    };
    update();
  }

  tabComposer(index: number) {
    this.composerSelected = index;
    this.composer = this.composers[index];
  }

  tabInterpreter(index: number) {
    this.interpreterSelected = index;
    this.interpreter = this.interpreters[index];
  }

  tabProjection(index: number) {
    this.tabProjectionSelected = index;
  }

  getSocialTemplateByIndex(index: number) {
    if (index === 0) {
      return this.spotifyTemplate;
    }

    return this.youtubeTemplate;
  }

  getSocialTemplate(title: string) {
    switch (title) {
      case 'Spotify':
        return this.spotifyTemplate;
      case 'Youtube':
        return this.youtubeTemplate;
      case 'Instagram':
        return this.instagramTemplate;
      case 'TikTok':
        return this.tiktokTemplate;
      default:
        return this.spotifyTemplate;
    }
  }

  scrollToSection(sectionId: string): void {
    this.tabPrincipalSelected = sectionId;

    // Caso especial para o topo
    if (sectionId === 'top') {
      this.viewportScroller.scrollToPosition([0, 0]);
    }

    const element = document.getElementById(sectionId);
    // Considera o tamanho do header
    const headerOffset = 152;
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
      this.viewportScroller.scrollToPosition([0, offsetPosition]);
    }
  }

  openLyrics() {
    this.dialog.open(this.lyricsDialog, {
      maxWidth: '342px',
      panelClass: 'lyrics-dialog',
    });
  }

  closeLyrics() {
    this.dialog.closeAll();
  }
}
