import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concatArtistNames',
  standalone: true,
})
export class ConcatArtistNamesPipe implements PipeTransform {
  transform(payees: any[]): string {
    if (!payees || payees.length <= 9) {
      return '';
    }

    return payees
      .slice(1)
      .map((payee) => payee.artist.name)
      .join(', ');
  }
}
