import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'mzic-select-simple-option',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './select-simple-option.component.html',
  styleUrl: './select-simple-option.component.scss',
})
export class SelectSimpleOptionComponent {
  @Input() value: any = '';
  @Input() title = '';
}
