import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  MzicPopoverOLD,
  MzicPopoverService,
  MzicPopoverStyleEnum,
} from '../mzic-popover';
import { MzicSvgComponent } from '../mzic-svg/mzic-svg.component';

@Component({
  selector: 'mzic-table-page-size',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  providers: [MzicPopoverService],
  templateUrl: './mzic-table-page-size.component.html',
  styleUrls: ['./mzic-table-page-size.component.scss'],
})
export class MzicTablePageSizeComponent implements OnInit {
  @ViewChild('popoverTemplate') popoverTemplate!: TemplateRef<any>;
  @ViewChild('actualPageSize') actualPageSize!: ElementRef<HTMLDivElement>;

  @Input() pageValues = ['10', '20', '30', '40', 'All'];
  @Output() pageSizeChanged = new EventEmitter<string>();

  popover: MzicPopoverOLD | undefined;
  currentPageValue = this.pageValues[0];

  constructor(
    private mzicPopoverService: MzicPopoverService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const pageSize = this.activatedRoute.snapshot.queryParamMap.get('pageSize');
    if (pageSize && (pageSize === 'All' || parseInt(pageSize) > 0)) {
      this.currentPageValue = pageSize;
    }
  }

  open() {
    const target = this.actualPageSize.nativeElement;
    this.popover = this.mzicPopoverService.openTemplateDeprecated(
      this.popoverTemplate,
      {
        target,
        position: 'topCenter',
        cssClass: MzicPopoverStyleEnum.SMALL_DARK,
        gapY: -5,
      },
    );
  }

  selectPageValue(value: string) {
    this.currentPageValue = value;
    this.pageSizeChanged.emit(value);
    this.popover?.instance.close();
    this.updateQueryParams(value);
  }

  updateQueryParams(pageSize: string) {
    const queryParams: Params = { pageSize };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
