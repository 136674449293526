import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import { AlbumsDTO, ResponseInterface } from '@mzic/mzic-interfaces';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpotifyService {
  private apiUrl = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  getAlbums(uuid: string, limit = 20, offset = 0, searchTerm?: string) {
    let url = `${this.apiUrl}/api/distribution/spotify/v1/albums/${uuid}?limit=${limit}&offset=${offset}`;

    if (searchTerm) {
      url = `${url}&releaseNameFilter=${searchTerm}`;
    }

    return this._http
      .get<ResponseInterface<AlbumsDTO>>(`${url}`)
      .pipe(map((response) => response.data.releases));
  }
}
