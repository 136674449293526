import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'isDateToday',
  standalone: true,
})
export class IsDateTodayPipe implements PipeTransform {
  transform(date: Date): boolean {
    const today = format(new Date(), 'dd/MM/yyyy');
    const dateFormated = format(date, 'dd/MM/yyyy');

    return today === dateFormated;
  }
}
