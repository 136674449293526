import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
  standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) {
      return ``;
    }

    const cleaned = value.replace(/\D/g, '');
    const lastFour = cleaned.slice(-4);
    const initial = cleaned.slice(0, -4);
    const maskedInitial = initial.replace(/./g, '•');

    const formatted = [
      maskedInitial.slice(0, 2),
      maskedInitial.slice(2, 4),
      maskedInitial.slice(4, 9),
    ].join(' ');

    return `${formatted} ${lastFour}`;
  }
}
