import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  Artist,
  CreateArtistRequest,
  RecentArtist,
  ResponseInterface,
  UpdateArtistRequest,
} from '@mzic/mzic-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArtistService {
  private apiUrl = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  createArtist(
    data: CreateArtistRequest,
  ): Observable<ResponseInterface<RecentArtist>> {
    const url = `${this.apiUrl}/api/distribution/artist/v2/create`;
    return this._http.post<ResponseInterface<RecentArtist>>(url, data);
  }

  public getArtists(
    search?: string,
    limit?: number,
    artistTypePriority?: number[],
    inExternal: boolean | null = null,
  ): Observable<ResponseInterface<RecentArtist[]>> {
    let url = `${this.apiUrl}/api/distribution/artist/v2/find`;

    if (search || limit || artistTypePriority) {
      url += '?';

      if (search) {
        url += `search=${search}&`;
      }

      if (limit) {
        url += `limit=${limit}&`;
      }

      if (artistTypePriority) {
        url += `artistTypePriority=${artistTypePriority.join(',')}&`;
      }

      if (inExternal !== null) {
        url += `inExternal=${inExternal}&`;
      }

      // Remove the trailing '&' character
      url = url.slice(0, -1);
    }

    return this._http.get<ResponseInterface<RecentArtist[]>>(url);
  }

  getArtistByViberate(
    hash: string,
  ): Observable<ResponseInterface<Partial<RecentArtist>>> {
    const url = `${this.apiUrl}/api/distribution/artist/v2/findByViberate/${hash}`;
    return this._http.get<ResponseInterface<Partial<RecentArtist>>>(url);
  }

  getArtistById(id: number): Observable<ResponseInterface<Artist>> {
    return this._http.get<ResponseInterface<Artist>>(
      `${this.apiUrl}/api/distribution/artist/v2/find/${id}`,
    );
  }

  updateArtist(
    id: number,
    data: Partial<UpdateArtistRequest>,
  ): Observable<ResponseInterface<RecentArtist>> {
    const url = `${this.apiUrl}/api/distribution/artist/v2/update/${id}`;
    return this._http.post<ResponseInterface<RecentArtist>>(url, data);
  }

  getRecentArtists(
    limit = 50,
    priorities = [1, 2, 3, 4],
  ): Observable<ResponseInterface<RecentArtist[]>> {
    return this._http.get<ResponseInterface<RecentArtist[]>>(
      `${this.apiUrl}/api/distribution/artist/v2/findRecent?limit=${limit}&artistTypePriority=${priorities.join(',')}`,
    );
  }
}
