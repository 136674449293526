import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  AssetsAvailableResponse,
  CreatePayeeRequest,
  GetCountries,
  GetCountriesParams,
  GetTrackV2FindParams,
  PayeeCreateResponse,
  PayeeImageUploadResponse,
  PayeeLanguagesResponse,
  PayeeResponse,
  PayeeTypeResponse,
  RevenueTypeResponse,
  RoyaltySplitConfirmInvitationResponse,
  RoyaltySplitCreateRequest,
  RoyaltySplitCreateResponse,
  RoyaltySplitDetailResponse,
  RoyaltySplitInvitationPendingResponse,
  RoyaltySplitInvitationResponse,
  RoyaltySplitListRequest,
  RoyaltySplitListResponse,
  StoreRequest,
  StoreResponse,
} from '@mzic/mzic-interfaces';
import { httpParams, LanguageEnum } from '@mzic/mzic-utils';
import { map, Observable } from 'rxjs';
import { CountryService } from '../country/country.service';
import { MzicArtistLocalService } from '../mzic-artist-local/mzic-artist-local.service';

@Injectable({
  providedIn: 'root',
})
export class RoyaltySplitsService {
  private readonly _http = inject(HttpClient);
  private readonly countryService = inject(CountryService);
  private readonly mzicArtistLocalService = inject(MzicArtistLocalService);

  private apiUrl = environment.apiUrl;

  protected headers = new HttpHeaders().append(
    'TEAM-ID',
    this.teamId.toString(),
  );

  protected languages = [
    { id: LanguageEnum.ENGLISH, name: 'English (US)' },
    { id: LanguageEnum.PORTUGUESE, name: 'Portuguese (BR)' },
  ];

  get teamId(): number {
    return this.mzicArtistLocalService.getWalletTeam()['team'].id;
  }

  getAssetsAvailable(
    params?: GetTrackV2FindParams,
  ): Observable<AssetsAvailableResponse> {
    return this._http
      .get<AssetsAvailableResponse>(
        `${this.apiUrl}/api/royalty/split/v1/assets/available`,
        {
          headers: this.headers,
          ...httpParams(params),
        },
      )
      .pipe(
        map((response) => {
          response.data.map((item) => {
            item.type = item.track ? 'track' : 'release';

            if (item.type === 'release') {
              item.release.eligibleForSplit =
                item.release.tracks.filter(
                  (track) => track.eligibleForSplit.eligible,
                ).length > 0;

              item.release.tracks.map((track) => {
                track.releaseId = item.release.id;
              });
            }

            return item;
          });

          return response;
        }),
      );
  }

  getRevenueType(): Observable<RevenueTypeResponse> {
    return this._http.get<RevenueTypeResponse>(
      `${this.apiUrl}/api/royalty/split/v1/revenue-type`,
      {
        headers: this.headers,
      },
    );
  }

  getRoyaltySplitList(
    payload: RoyaltySplitListRequest,
  ): Observable<RoyaltySplitListResponse> {
    if (payload?.pageable?.page) {
      payload.pageable.page--;
    }

    return this._http.get<RoyaltySplitListResponse>(
      `${this.apiUrl}/api/royalty/split/v1/find`,
      {
        ...httpParams({
          created: payload.created,
          name: payload.searchTerm,
          page: payload?.pageable?.page || 0,
          size: payload?.pageable?.size || 10,
          sort: payload?.pageable?.sort,
        }),
      },
    );
  }

  getRoyaltySplitDetail(
    splitId: string,
  ): Observable<RoyaltySplitDetailResponse> {
    return this._http.get<RoyaltySplitDetailResponse>(
      `${this.apiUrl}/api/royalty/split/v1/detail/${splitId}`,
      {
        headers: this.headers,
      },
    );
  }

  postRoyaltySplitCreate(
    payload: RoyaltySplitCreateRequest,
  ): Observable<RoyaltySplitCreateResponse> {
    return this._http.post<RoyaltySplitCreateResponse>(
      `${this.apiUrl}/api/royalty/split/v1/create`,
      payload,
      {
        headers: this.headers,
      },
    );
  }

  putRoyaltySplitUpdate(
    payload: RoyaltySplitCreateRequest,
  ): Observable<RoyaltySplitCreateResponse> {
    return this._http.post<RoyaltySplitCreateResponse>(
      `${this.apiUrl}/api/royalty/split/v1/update`,
      payload,
      {
        headers: this.headers,
      },
    );
  }

  getStore(params?: StoreRequest): Observable<StoreResponse> {
    return this._http.get<StoreResponse>(
      `${this.apiUrl}/api/distribution/store/v2/find`,
      {
        ...httpParams(params),
      },
    );
  }

  getCountryFind(params?: GetCountriesParams): Observable<GetCountries> {
    return this.countryService.getCountries(params);
  }

  getPayeeFind(searchTerm?: string): Observable<PayeeResponse> {
    let urlParams = new HttpParams()
      .set('inExternal', 'true')
      .set('artistTypePriority', '1,2,3,4,5,6');

    if (searchTerm) {
      urlParams = urlParams.set('search', searchTerm);
    }

    return this._http.get<PayeeResponse>(
      `${this.apiUrl}/api/distribution/artist/v2/find`,
      {
        headers: this.headers,
        params: urlParams,
      },
    );
  }

  getRecentPayee(): Observable<PayeeResponse> {
    const urlParams = new HttpParams().set('artistTypePriority', '1,2,3,4,5,6');

    return this._http.get<PayeeResponse>(
      `${this.apiUrl}/api/distribution/artist/v2/findRecent`,
      {
        headers: this.headers,
        params: urlParams,
      },
    );
  }

  getPayeeTypes(): Observable<PayeeTypeResponse> {
    const urlParams = new HttpParams().set('priority', '1,2,3,4,5');

    return this._http.get<PayeeTypeResponse>(
      `${this.apiUrl}/api/distribution/artist-type/v2/find`,
      {
        headers: this.headers,
        params: urlParams,
      },
    );
  }

  getPayeeLanguages(): Observable<PayeeLanguagesResponse> {
    return this._http
      .get<PayeeLanguagesResponse>(
        `${this.apiUrl}/api/backoffice/settings/v2/all/languages`,
      )
      .pipe(
        map((response) => {
          response.data.map((language) => {
            language.name = this.languages.filter(
              (lang) => lang.id === language.cod,
            )[0].name;
          });
          return response;
        }),
      );
  }

  uploadPayeeImage(
    file: FormData,
    cover = false,
  ): Observable<PayeeImageUploadResponse> {
    const url = `${this.apiUrl}/api/distribution/media/v2/upload/image?cover=${cover}`;

    return this._http.post<PayeeImageUploadResponse>(url, file, {
      headers: this.headers,
    });
  }

  createPayee(data: CreatePayeeRequest): Observable<PayeeCreateResponse> {
    return this._http.post<PayeeCreateResponse>(
      `${this.apiUrl}/api/distribution/artist/v2/create`,
      data,
      {
        headers: this.headers,
      },
    );
  }

  postResendEmailInvitation(
    splitId: number,
    payeeId: number,
  ): Observable<RoyaltySplitInvitationResponse> {
    return this._http.post<RoyaltySplitInvitationResponse>(
      `${this.apiUrl}/api/royalty/invitation/v1/send/${splitId}/${payeeId}`,
      {},
      {
        headers: this.headers,
      },
    );
  }

  getVerifyInvitationPending(): Observable<RoyaltySplitInvitationPendingResponse> {
    return this._http.get<RoyaltySplitInvitationPendingResponse>(
      `${this.apiUrl}/api/royalty/invitation/v1/pending`,
      {
        headers: this.headers,
      },
    );
  }

  postConfirmInvitation(
    splitId: number,
    payeeId: number,
  ): Observable<RoyaltySplitConfirmInvitationResponse> {
    return this._http.post<RoyaltySplitConfirmInvitationResponse>(
      `${this.apiUrl}/api/royalty/invitation/v1/confirm/${splitId}/${payeeId}`,
      {},
      {
        headers: this.headers,
      },
    );
  }
}
