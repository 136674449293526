import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[mzicScrollingPagination]',
})
export class MzicScrollingPaginationDirective {
  @Input() mzicScrollingPagination = 10;
  @Output() scrollingEvent = new EventEmitter<number>();

  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event']) onScrolling() {
    if (
      this.el.nativeElement.scrollTop + this.el.nativeElement.clientHeight ===
      this.el.nativeElement.scrollHeight
    ) {
      const page =
        this.el.nativeElement.childElementCount / this.mzicScrollingPagination;
      this.scrollingEvent.emit(page);
    }
  }
}
