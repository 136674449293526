import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isCurrentMonth',
  standalone: true,
})
export class IsCurrentMonthPipe implements PipeTransform {
  transform(date: Date, monthSelected: number): boolean {
    return date.getMonth() === monthSelected - 1;
  }
}
