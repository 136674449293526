import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Country,
  createEmptyCountry,
  createEmptyStore,
  Store,
} from '@mzic/mzic-interfaces';
import { RoyaltySplitsService } from '@mzic/mzic-services';

import { BehaviorSubject, Observable } from 'rxjs';

interface RulesModel {
  isLoading: boolean;
  stores: Store[];
  countries: Country[];
}

@Injectable({
  providedIn: 'root',
})
export class RulesState {
  private royaltySplitsService = inject(RoyaltySplitsService);

  private initialState = {
    isLoading: false,
    stores: createEmptyStore(),
    countries: createEmptyCountry(),
  };

  private state = signal<RulesModel>(this.initialState);

  isLoadingSnapshot = computed(() => this.state().isLoading);
  storesSnapshot = computed(() => this.state().stores);
  countriesSnapshot = computed(() => this.state().countries);

  private isLoading$ = new BehaviorSubject<boolean>(false);
  private stores$ = new BehaviorSubject<Store[]>([]);
  private countries$ = new BehaviorSubject<Country[]>([]);

  get isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  get stores(): Observable<Store[]> {
    return this.stores$.asObservable();
  }

  get countries(): Observable<Country[]> {
    return this.countries$.asObservable();
  }

  constructor() {
    this.isLoading$.pipe(takeUntilDestroyed()).subscribe((isLoading) => {
      this.state.update((state) => ({
        ...state,
        isLoading,
      }));
    });

    this.stores$.pipe(takeUntilDestroyed()).subscribe((stores) => {
      this.state.update((state) => ({
        ...state,
        stores,
      }));
    });
  }

  loadStores() {
    this.isLoading$.next(true);

    this.royaltySplitsService.getStore().subscribe({
      next: (stores) => {
        this.stores$.next(stores.data);
        this.isLoading$.next(false);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }

  loadCountries() {
    this.isLoading$.next(true);

    this.royaltySplitsService.getCountryFind().subscribe({
      next: (countries) => {
        this.countries$.next(countries.data.content);
        this.isLoading$.next(false);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }
}
