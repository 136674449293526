export interface WalletListPaymentMethodBank {
  timestamp: string;
  status: string;
  data: WalletListPaymentMethodBankData[];
}

export interface WalletListPaymentMethodBankData {
  paymentMethodId: string;
  type: string;
  funding?: string;
  bankName: string;
  routingNumber: string;
  accountType?: string;
  holderName?: string;
  iconUrl: string;
  last4: string;
  main?: boolean;
}

export function createEmptyWalletPaymentMethodBankData(): WalletListPaymentMethodBankData[] {
  return [
    {
      paymentMethodId: '',
      type: '',
      funding: '',
      bankName: '',
      routingNumber: '',
      accountType: '',
      holderName: '',
      iconUrl: '',
      last4: '',
    },
  ];
}

export function createEmptyWalletPaymentMethodMainBankData(): WalletListPaymentMethodBankData {
  return {
    paymentMethodId: '',
    type: '',
    funding: '',
    bankName: '',
    routingNumber: '',
    accountType: '',
    holderName: '',
    iconUrl: '',
    last4: '',
  };
}
