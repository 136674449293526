import { CommonModule } from '@angular/common';
import { Component, effect, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  provideTranslocoScope,
  TranslocoModule,
  TranslocoService,
} from '@jsverse/transloco';
import { LanguageService } from '@mzic/mzic-services';
import { interval, Subscription } from 'rxjs';
import { HeaderComponent } from '../../components/header/header.component';

@Component({
  selector: 'lib-coming-soon',
  standalone: true,
  imports: [CommonModule, HeaderComponent, TranslocoModule],
  templateUrl: './coming-soon.component.html',
  styleUrl: './coming-soon.component.scss',
  providers: [
    provideTranslocoScope({
      scope: 'home',
      alias: 'lang',
    }),
  ],
})
export class ComingSoonComponent implements OnInit, OnDestroy {
  targetDate = new Date('2025-01-01T00:00:00');
  countdownSubscription!: Subscription;
  languageService = inject(LanguageService);
  activatedRoute = inject(ActivatedRoute);
  translocoService = inject(TranslocoService);
  router = inject(Router);

  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;

  constructor() {
    effect(() => {
      const lang = this.languageService.languageState();
      const current = this.router.url.split('/').pop();

      if (lang !== '') {
        this.router.navigate([`${lang}/${current}`]);
      }
    });
  }

  ngOnInit() {
    this.startCountdown();
  }

  ngOnDestroy() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  startCountdown() {
    this.updateCountdown();
    this.countdownSubscription = interval(1000).subscribe(() => {
      this.updateCountdown();
    });
  }

  updateCountdown() {
    const now = new Date().getTime();
    const target = new Date(this.targetDate).getTime();
    const difference = target - now;

    if (difference <= 0) {
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
      this.countdownSubscription?.unsubscribe();

      // o timer terminou.. pode fazer mais alguma coisa aqui...
    } else {
      this.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
    }
  }

  formatNumber(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }
}
