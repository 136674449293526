import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'lib-icon-x-twitter',
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3262 0.0570068H20.6996L13.3297 8.4806L22 19.9429H15.211L9.89403 12.9909L3.81 19.9429H0.434371L8.3172 10.9331L0 0.0570068H6.96089L11.7672 6.41123L17.3262 0.0570068ZM16.1422 17.9236H18.0116L5.94529 1.97028H3.9395L16.1422 17.9236Z" />
    </svg>
  `,
})
export class IconXTwitterComponent {}
