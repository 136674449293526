<div
  class="mzic-avatar-stamp-md-mekari"
  [ngClass]="largeImage ? 'large-icons' : ''">
  <mat-spinner *ngIf="isLoading()" diameter="25" />
  <img
    class="mzic-avatar-stamp-md-mekari__artist"
    [alt]="altImage"
    [src]="imageUri"
    (load)="onLoading()"
    [ngStyle]="isLoading() ? { display: 'none' } : { display: 'block' }" />
  <img
    class="mzic-avatar-stamp-md-mekari__stamp"
    [alt]="altImage"
    [src]="stampUri" />
</div>
