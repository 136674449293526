export interface WalletBalance {
  timestamp: string;
  status: string;
  data: WalletBalanceData;
}

export interface WalletBalanceData {
  balances: Balance[];
  approximateTotalBalance: number;
  currencyType: string;
}

export interface Balance {
  availableBalance: number;
  futureBalance: number;
  currencyType: string;
  exchangeRate: ExchangeRate;
}

export interface ExchangeRate {
  rate: number;
  userDefaultCurrencyType: string;
}

export function createEmptyWalletBalanceData(): WalletBalanceData {
  return {
    balances: [],
    approximateTotalBalance: 0,
    currencyType: '',
  };
}
