// eslint-disable-next-line @nx/enforce-module-boundaries
import { OverlayRef } from '@angular/cdk/overlay';
import { Directive, HostListener, Input } from '@angular/core';
import { MzicTooltipService } from '@mzic/mzic-services';

@Directive({
  selector: '[mzicTooltip]',
  providers: [],
  standalone: true,
})
export class MzicTooltipDirective {
  @Input() mzicTooltip = '';
  @Input() mzicTooltipEvent: 'click' | 'hover' = 'hover';

  tooltipRef: OverlayRef | undefined;

  constructor(private mzicTooltipService: MzicTooltipService) {}

  @HostListener('mouseenter', ['$event.target'])
  onMouseEnter(target: HTMLElement): void {
    if (this.mzicTooltipEvent === 'hover') {
      this.openTooltip(target);
    }
  }
  @HostListener('mouseleave', ['$event.target'])
  onMouseLeave(): void {
    if (this.mzicTooltipEvent === 'hover') {
      this.closeTooltip();
    }
  }

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (this.mzicTooltipEvent === 'click') {
      this.openTooltip(target);
    }
  }

  openTooltip(target: HTMLElement) {
    this.tooltipRef = this.mzicTooltipService.open(
      target,
      this.mzicTooltip,
      this.mzicTooltipEvent,
    );
  }

  closeTooltip() {
    if (this.tooltipRef) {
      this.tooltipRef.dispose();
    }
  }
}
