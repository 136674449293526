import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import Hls from 'hls.js';
import { Subject } from 'rxjs';
import { MzicArtistLocalService } from '../mzic-artist-local/mzic-artist-local.service';
import { MzicTokenLocalService } from '../mzic-token-local/mzic-token-local.service';

@Injectable({
  providedIn: 'root',
})
export class AudioPlayerService {
  private audioElement: HTMLAudioElement | null = null;
  private renderer: Renderer2;
  private hls: Hls | null = null;

  private playSubject = new Subject<void>();
  private pauseSubject = new Subject<void>();

  onPlay$ = this.playSubject.asObservable();
  onPause$ = this.pauseSubject.asObservable();
  showPlayer = false;

  constructor(
    private rendererFactory: RendererFactory2,
    private mzicArtistLocalService: MzicArtistLocalService,
    private mzicTokenLocalService: MzicTokenLocalService,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.createAudioPlayerInDOM();
  }

  setHashAudioSource(trackId: number) {
    const url = `${environment.apiUrl}/api/stream/music/v2/stream/${trackId}/stream.m3u8`;
    this.setAudioSource(url);
  }

  setAudioSource(url: string) {
    if (Hls.isSupported() && this.audioElement) {
      if (this.hls) {
        this.hls.destroy();
      }
      this.hls = new Hls({
        xhrSetup: (xhr) => {
          const teamId = this.mzicArtistLocalService.getTeamId();
          if (teamId) {
            xhr.setRequestHeader('TEAM-ID', teamId);
          }
          const token = this.mzicTokenLocalService.getTokenLocal();
          if (token) {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          }
        },
      });
      this.hls.loadSource(url);
      this.hls.attachMedia(this.audioElement);
      // this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
      //   this.audioElement?.play();
      // });
    }
  }

  play() {
    this.audioElement?.play();
  }

  pause() {
    this.audioElement?.pause();
  }

  private createAudioPlayerInDOM() {
    if (!this.audioElement) {
      this.audioElement = this.renderer.createElement('audio');
      this.renderer.setAttribute(this.audioElement, 'controls', '');

      // Adicione estilos diretamente no elemento
      if (this.showPlayer) {
        this.renderer.setStyle(this.audioElement, 'width', '100%');
        this.renderer.setStyle(this.audioElement, 'height', '30px');
      } else {
        this.renderer.setStyle(this.audioElement, 'width', '1px');
        this.renderer.setStyle(this.audioElement, 'height', '1px');
        this.renderer.setStyle(this.audioElement, 'opacity', '0');
        this.renderer.setStyle(this.audioElement, 'z-index', '-1');
        this.renderer.setStyle(this.audioElement, 'overflow', 'hidden');
      }

      this.renderer.setStyle(this.audioElement, 'position', 'fixed');
      this.renderer.setStyle(this.audioElement, 'bottom', '0');
      this.renderer.setStyle(this.audioElement, 'right', '0');

      // Opcionalmente, adicione uma classe ao elemento para aplicar estilos CSS
      this.renderer.addClass(this.audioElement, 'custom-audio-player');

      // Adicione o elemento ao corpo do documento
      this.renderer.appendChild(document.body, this.audioElement);

      this.audioElement?.addEventListener('play', () =>
        this.playSubject.next(),
      );
      this.audioElement?.addEventListener('pause', () =>
        this.pauseSubject.next(),
      );
    }
  }
}
