import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@mzic/mzic-environments';
import { createEmptyRevenueType, RevenueType } from '@mzic/mzic-interfaces';

import { Observable, Subject } from 'rxjs';
import { RoyaltySplitsService } from '../royalty-splits.service';

interface RevenueTypeModel {
  isLoading: boolean;
  revenueType: RevenueType;
}

@Injectable({
  providedIn: 'root',
})
export class RevenueTypeState {
  private royaltySplitsService = inject(RoyaltySplitsService);

  private initialState = {
    isLoading: false,
    revenueType: createEmptyRevenueType(),
  };

  private state = signal<RevenueTypeModel>(this.initialState);

  isLoadingSnapshot = computed(() => this.state().isLoading);
  revenueTypeSnapshot = computed(
    () => Object.values(this.state().revenueType)[0],
  );

  private isLoading$ = new Subject<boolean>();
  private revenueType$ = new Subject<RevenueType>();

  get isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  get revenueType(): Observable<RevenueType> {
    return this.revenueType$.asObservable();
  }

  constructor() {
    effect(() => {
      if (!environment.production) {
        console.log('Revenue Type State', this.state());
      }
    });

    this.isLoading$.pipe(takeUntilDestroyed()).subscribe((isLoading) => {
      this.state.update((state) => ({
        ...state,
        isLoading,
      }));
    });

    this.revenueType$.pipe(takeUntilDestroyed()).subscribe((revenueType) => {
      this.state.update((state) => ({
        ...state,
        revenueType,
      }));
    });
  }

  loadRevenueType() {
    this.isLoading$.next(true);

    this.royaltySplitsService.getRevenueType().subscribe({
      next: (revenueType) => {
        this.revenueType$.next(revenueType.data);
        this.isLoading$.next(false);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }
}
