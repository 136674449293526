import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OptionSpotifyItem } from '@mzic/mzic-interfaces';
import { SearchSpotifyAvatarComponent } from '../search-spotify-avatar/search-spotify-avatar.component';

@Component({
  selector: 'mzic-option-md-mekari',
  standalone: true,
  imports: [CommonModule, SearchSpotifyAvatarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search-spotify-option.component.html',
  styleUrls: ['./search-spotify-option.component.scss'],
})
export class SearchSpotifyOptionComponent {
  @Input({ required: true }) optionData!: OptionSpotifyItem;
}
