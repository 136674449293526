import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { UserActionRoute } from '@mzic/mzic-utils';
@Injectable({
  providedIn: 'root',
})
export class FlowNavigationService {
  constructor(
    private readonly _router: Router,
    private transloco: TranslocoService,
  ) {}
  navigateAfterConfirmation(flow: UserActionRoute): void {
    if (flow === UserActionRoute.Login) {
      this._router.navigate(['team-selection']);
    } else if (
      flow === UserActionRoute.Registration ||
      flow === UserActionRoute.PendingRegistration
    ) {
      const currentLang = this.transloco.getActiveLang();
      this._router.navigate([
        `${currentLang}/create-account/artist/terms-and-privacy-policy`,
      ]);
    } else {
      // Adicione outras condições conforme necessário
    }
  }
}
