import { ApiResponse } from '../shared/api-response.interface';

export interface GetAllLanguages extends ApiResponse {
  data: GetAllLanguagesData[];
}

export interface GetAllLanguagesData {
  cod: number;
  language: string;
  region: string;
  countryCode: string;
  displayLanguage: string;
  active?: boolean;
  name?: string;
}

export function createEmptyLanguages(): GetAllLanguagesData {
  return {
    cod: 0,
    language: '',
    region: '',
    countryCode: '',
    displayLanguage: '',
    active: false,
    name: '',
  };
}
