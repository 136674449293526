import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mzicConvertTime',
  standalone: true,
})
export class MzicConvertTimePipe implements PipeTransform {
  transform(value: string): string {
    // Converter a string de data para um objeto Date
    const date = new Date(value);

    // Definir o fuso horário para o leste dos EUA (EST)
    date.setUTCHours(date.getUTCHours() - 5);

    // Extrair o horário no formato desejado
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours < 12 ? 'am' : 'pm';

    // Formatar a hora
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${period} EST`;
  }
}
