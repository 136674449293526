import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  public setMzicNavigationCollapsed(collapsed: boolean) {
    localStorage.setItem('mzic-navigation-collapsed', collapsed.toString());
  }
  public getMzicNavigationCollapsed() {
    return localStorage.getItem('mzic-navigation-collapsed') === 'true';
  }
}
