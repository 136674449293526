import {
  BreakpointObserver,
  Breakpoints,
  LayoutModule,
} from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { HeaderComponent } from '../../components/header/header.component';
import { DesktopComponent } from './pages/desktop/desktop.component';
import { MobileComponent } from './pages/mobile/mobile.component';

@Component({
  selector: 'mzic-page-asset',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    TranslocoModule,
    DesktopComponent,
    MobileComponent,
    LayoutModule,
  ],
  templateUrl: './asset.component.html',
  styleUrl: './asset.component.scss',
  providers: [
    provideTranslocoScope({
      scope: 'asset',
      alias: 'lang',
    }),
  ],
})
export class AssetComponent implements OnInit {
  breakpointObserver = inject(BreakpointObserver);
  isMobile = false;

  ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isMobile = result.matches;
        console.log('Is mobile screen:', this.isMobile);
      });
  }
}
