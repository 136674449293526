import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import { GenreResourceData, ResponseInterface } from '@mzic/mzic-interfaces';

@Injectable({
  providedIn: 'root',
})
export class GenreService {
  private apiUrl = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  getGenres() {
    return this._http.get<ResponseInterface<GenreResourceData>>(
      `${this.apiUrl}/api/distribution/genre/v2/find?size=400&sort=name,ASC`,
    );
  }
}
