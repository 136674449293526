import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MzicTokenLocalService {
  public clearAlltokens(): void {
    this.removeTokenLocal();
    this.removeRefreshToken();
  }
  public getTokenLocal(): string {
    return localStorage.getItem('mzic-token-local') as string;
  }

  public getRefreshToken(): string {
    return localStorage.getItem('mzic-refresh-token') as string;
  }

  public removeTokenLocal(): void {
    localStorage.removeItem('mzic-token-local');
  }

  public removeRefreshToken(): void {
    localStorage.removeItem('mzic-refresh-token');
  }

  public setTokenLocal(value: string): void {
    localStorage.setItem('mzic-token-local', value);
  }

  public setRefreshToken(value: string): void {
    localStorage.setItem('mzic-refresh-token', value);
  }
}
