<ng-container *transloco="let t">
  <div class="header flex-end">
    <button
      class="mz-button only-icon small ghost close white"
      (click)="closed.emit()">
      <mzic-svg src="assets/icons/mzic-close.svg" />
    </button>
  </div>
  <div class="main center">
    <mzic-svg src="assets/icons/mzic-close-fill.svg" class="w50 red" />
    <div class="title">{{ t('lang.transaction_error') }}</div>
    <p>
      {{ t('lang.server_connection_issue') }}
    </p>
  </div>
  <div class="footer">
    <button class="mz-button small secondary" (click)="closed.emit()">
      {{ t('lang.close') }}
    </button>
    <button class="mz-button small danger" (click)="tryAgain.emit()">
      {{ t('lang.try_again') }}
    </button>
  </div>
</ng-container>
