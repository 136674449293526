import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formattedNumbers',
  standalone: true,
})
export class FormattedNumbersPipe implements PipeTransform {
  transform(value: number): string {
    if (value < 1) {
      return '';
    }

    const maxCount = 5;
    const numbers = [];

    for (let i = 1; i <= Math.min(value, maxCount); i++) {
      numbers.push(`#${i}`);
    }

    const formattedString = numbers.join(', ');

    if (value > maxCount) {
      return `${formattedString}…`;
    }

    return formattedString;
  }
}
