import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MzicSvgComponent } from '../mzic-svg/mzic-svg.component';

@Component({
  selector: 'mzic-pagination-counter',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  templateUrl: './pagination-counter.component.html',
  styleUrl: './pagination-counter.component.scss',
})
export class PaginationCounterComponent {
  @Input() current!: number;
  @Input() total!: number;
}
