import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import { GetCountries, GetCountriesParams } from '@mzic/mzic-interfaces';
import { httpParams } from '@mzic/mzic-utils';
import { MzicArtistLocalService } from '../mzic-artist-local/mzic-artist-local.service';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private apiUrl = environment.apiUrl;

  constructor(
    private readonly _http: HttpClient,
    private mzicArtistLocalService: MzicArtistLocalService,
  ) {}

  getCountries(params?: GetCountriesParams) {
    if (params?.page) {
      params.page--;
    }
    const headers = new HttpHeaders().append('TEAM-ID', this.getTeamId());
    return this._http.get<GetCountries>(
      `${this.apiUrl}/api/distribution/country/v2/find`,
      { ...httpParams(params), headers },
    );
  }

  private getTeamId() {
    return this.mzicArtistLocalService.getTeamId();
  }
}
