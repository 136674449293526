import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol',
  standalone: true,
})
export class CurrencySymbolPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    value: number | undefined,
    currencyCode: string | undefined,
  ): string | null {
    const formatted = this.currencyPipe.transform(
      value,
      currencyCode,
      'symbol',
      '1.0-0',
    );

    if (formatted) {
      return formatted.replace(/[\d,.]/g, '').trim();
    }

    return null;
  }
}
