import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WalletTeamResponse } from '@mzic/mzic-interfaces';
import { API_URL } from '@mzic/mzic-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MzicTeamResourceService {
  constructor(private readonly _http: HttpClient) {}

  public get(): Observable<WalletTeamResponse> {
    return this._http.get<WalletTeamResponse>(API_URL.TEAMS);
  }
}
