<div class="actual-page-size" (click)="open()" #actualPageSize>
  <div class="number">{{ this.currentPageValue }}</div>
  <mzic-svg src="assets/icons/mzic-arrow-down.svg" class="w10" />
</div>

<ng-template #popoverTemplate>
  <ng-container *ngFor="let pageValue of pageValues; index as i">
    <div (click)="selectPageValue(pageValue)">
      <span class="clickable">{{ pageValue }}</span>
    </div>
  </ng-container>
</ng-template>
