import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'lib-icon-discord',
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.3303 2.26675C18.7767 1.53987 17.1156 1.01161 15.3789 0.710815C15.1656 1.09643 14.9164 1.61508 14.7446 2.02767C12.8985 1.75003 11.0693 1.75003 9.25716 2.02767C9.08539 1.61508 8.83055 1.09643 8.61536 0.710815C6.87681 1.01161 5.21376 1.54181 3.66019 2.2706C0.526643 7.0058 -0.322811 11.6234 0.101917 16.1754C2.18025 17.7275 4.19441 18.6703 6.17457 19.2872C6.66349 18.6144 7.09953 17.8991 7.47518 17.1452C6.75975 16.8734 6.07453 16.5379 5.42707 16.1484C5.59884 16.0212 5.76686 15.8881 5.92918 15.7512C9.87819 17.5983 14.1689 17.5983 18.0707 15.7512C18.235 15.8881 18.403 16.0212 18.5728 16.1484C17.9235 16.5398 17.2364 16.8753 16.521 17.1472C16.8966 17.8991 17.3308 18.6163 17.8216 19.2892C19.8036 18.6722 21.8197 17.7294 23.898 16.1754C24.3964 10.8985 23.0467 6.32328 20.3303 2.26675ZM8.01318 13.376C6.82772 13.376 5.85555 12.2693 5.85555 10.9216C5.85555 9.57391 6.80696 8.46531 8.01318 8.46531C9.21942 8.46531 10.1916 9.57197 10.1708 10.9216C10.1727 12.2693 9.21942 13.376 8.01318 13.376ZM15.9867 13.376C14.8013 13.376 13.8291 12.2693 13.8291 10.9216C13.8291 9.57391 14.7805 8.46531 15.9867 8.46531C17.193 8.46531 18.1651 9.57197 18.1444 10.9216C18.1444 12.2693 17.193 13.376 15.9867 13.376Z" />
    </svg>
  `,
})
export class IconDiscordComponent {}
