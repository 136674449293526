<div class="box">
  <div class="box-circle">
    <svg [attr.width]="width" [attr.height]="width">
      <circle
        [attr.style]="
          'transform:translate(' +
          strokeWidth / 2 +
          'px, ' +
          strokeWidth / 2 +
          'px)'
        "
        [attr.stroke]="inactiveColor"
        [attr.stroke-dasharray]="dashArray"
        [attr.stroke-width]="strokeWidth"
        [attr.cx]="(width - strokeWidth) / 2"
        [attr.cy]="(width - strokeWidth) / 2"
        [attr.r]="(width - strokeWidth) / 2"></circle>
      <circle
        [attr.stroke]="activeColor"
        [attr.style]="
          'transform:translate(' +
          strokeWidth / 2 +
          'px, ' +
          strokeWidth / 2 +
          'px)'
        "
        [attr.stroke-dashoffset]="dashOffset"
        [attr.stroke-dasharray]="dashArray"
        [attr.stroke-width]="strokeWidth"
        [attr.cx]="(width - strokeWidth) / 2"
        [attr.cy]="(width - strokeWidth) / 2"
        [attr.r]="(width - strokeWidth) / 2"></circle>
    </svg>
  </div>
</div>
