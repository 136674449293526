import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import { Observable, Subject, first } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { API_URL } from 'libs/mzic-utils/src/lib/helpers/router-api';

import {
  ArtistSocialMediaDataModel,
  BaseCreateUserDto,
  PaginationCreateAccountInterface as PaginationInterface,
  ParamsArtistAgree,
  ParamsArtistInstagramVerify,
  ParamsMediaUploadImage,
  ResponseAcceptTermsAndPolicy,
  ResponseCheckUsername,
  ResponseInterface,
  ResponseUploadImage,
  SearchArtistModel,
  UserProfileDTO,
} from '@mzic/mzic-interfaces';
import { UrlParams } from '@mzic/mzic-utils';

@Injectable({
  providedIn: 'root',
})
export class MzicCreateAccountService {
  mediaUploadImageError$ = new Subject<void>();

  constructor(private readonly _http: HttpClient) {}

  findSpotifyArtistByNameOrUrl(
    filter: string,
    offset = 0,
    limit = 20,
  ): Observable<PaginationInterface<SearchArtistModel>> {
    const queryParams = { filter, limit, offset };

    return this._http
      .get<PaginationInterface<SearchArtistModel>>(
        API_URL.SOCIAL_ARTISTS_SEARCH,
        {
          params: queryParams,
        },
      )
      .pipe(first());
  }

  getArtistSpotifyAndInstagramData(
    uuid: string,
  ): Observable<ResponseInterface<ArtistSocialMediaDataModel>> {
    const queryParams = { uuid };

    return this._http
      .get<ResponseInterface<ArtistSocialMediaDataModel>>(
        API_URL.SOCIAL_ARTISTS_RETRIEVE,
        {
          params: queryParams,
        },
      )
      .pipe(first());
  }

  verifyInstagramAccount(
    params: ParamsArtistInstagramVerify,
  ): Observable<unknown> {
    return this._http.post(API_URL.SOCIAL_ARTISTS_CREATE, params).pipe(first());
  }

  fetchImage(url: string) {
    return this._http.get(url, { responseType: 'blob' });
  }

  mediaUploadImage(
    params: ParamsMediaUploadImage,
    data: FormData,
  ): Observable<ResponseUploadImage> {
    const query = UrlParams(params);

    return this._http.post<ResponseUploadImage>(
      API_URL.MEDIA_UPLOAD_IMAGE + query,
      data,
    );
  }

  checkUserName(username: string): Observable<ResponseCheckUsername> {
    return this._http.post<ResponseCheckUsername>(
      `${environment.apiUrl}/api/user/user/v3/check/username?username=${username}`,
      {},
    );
  }

  createUserAccount(
    baseCreateUser: BaseCreateUserDto,
  ): Observable<ResponseInterface<UserProfileDTO>> {
    return this._http.post<ResponseInterface<UserProfileDTO>>(
      API_URL.USER_SIGN_UP,
      baseCreateUser,
    );
  }

  artistAcceptTermsAndPolicy(
    params: ParamsArtistAgree,
  ): Observable<ResponseAcceptTermsAndPolicy> {
    return this._http.post<ResponseAcceptTermsAndPolicy>(
      API_URL.USER_AGREE,
      params,
    );
  }
}
