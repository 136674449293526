import { RoyaltySplitPriorityPayee } from './royalty-split-priority-payee.interface';

export interface RoyaltySplitCreateRequest {
  name: string;
  revenueType: string;
  expirationDate: string;
  externalFunding: boolean;
  payees: RoyaltySplitCreatePayee[];
  priorityPayees: RoyaltySplitPriorityPayee[];
  dspsToInclude: number[];
  dspsToExclude: number[];
  countriesToInclude: number[];
  countriesToExclude: number[];
  releaseIds: number[];
  trackIds: number[];
}

export interface RoyaltySplitCreatePayee {
  payeeId: number;
  artistId: number;
  sharePercentage: number;
  artistTypeId: number;
  languageId: number;
}

export function createEmptyRoyaltySplitForm(): RoyaltySplitCreateRequest {
  return {
    name: '',
    revenueType: '',
    expirationDate: '',
    externalFunding: false,
    payees: [],
    priorityPayees: [],
    dspsToInclude: [],
    dspsToExclude: [],
    countriesToInclude: [],
    countriesToExclude: [],
    releaseIds: [],
    trackIds: [],
  };
}
