import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OptionSpotifyItem } from '@mzic/mzic-interfaces';
import { SearchSpotifyAvatarComponent } from '../search-spotify-avatar/search-spotify-avatar.component';

@Component({
  selector: 'mzic-search-spotify-option-selected',
  standalone: true,
  imports: [
    CommonModule,
    SearchSpotifyAvatarComponent,
    MatProgressSpinnerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search-spotify-option-selected.component.html',
  styleUrls: ['./search-spotify-option-selected.component.scss'],
})
export class SearchSpotifyOptionSelectedComponent {
  @Input({ required: true }) optionData!: OptionSpotifyItem;
  @Input() loading = true;
  @Input() responsive = true;
}
