import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'mzic-switch-language',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './switch-language.component.html',
  styleUrl: './switch-language.component.scss',
})
export class SwitchLanguageComponent implements OnInit {
  protected languageActive = 'en';
  @Output() languageChange = new EventEmitter<string>();
  translocoService = inject(TranslocoService);

  ngOnInit() {
    this.translocoService.langChanges$.subscribe((lang) => {
      this.languageActive = lang;
    });
  }

  switchLanguage(lang: string) {
    this.languageChange.emit(lang);
  }
}
