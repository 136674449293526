<mzic-header />

<div class="landing-page">
  <!-- Video de fundo -->
  <video class="landing-page__background-video" autoplay muted loop>
    <source src="/assets/movies/home-bb67-d332dd270507.mp4" type="video/mp4" />
    Seu navegador não suporta vídeos HTML5.
  </video>

  <!-- Conteúdo principal -->
  <div class="landing-page__content">
    <main class="landing-page__main">
      <h1 class="landing-page__title">
        Direitos de royalties musicais
        <span class="landing-page__highlight">MZIC1</span>
      </h1>
      <div class="landing-page__buttons">
        <button class="landing-page__button">tokens de música</button>
        <button class="landing-page__button">royalties digitais</button>
      </div>
      <p class="landing-page__description">
        Escaneie o QR Code ao lado para acessar a lâmina completa do direito em
        oferta, e explore detalhes do artista, nossa análise do mercado, dados
        financeiros e projeções de retorno.
      </p>
    </main>

    <!-- QR Code -->
    <div class="landing-page__qr-code">
      <img src="/assets/images/qrcode-main.png" alt="QR Code" />
    </div>
  </div>
</div>
