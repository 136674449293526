export interface SelectOption {
  id: number | string;
  info: SelectOptionData;
  checked?: boolean;
}

export type SelectOptionValue = (string | number)[] | undefined;

export interface SelectOptionData {
  id?: number | string;
  title: string;
  version?: string;
  description?: string;
  image?: string;
}

export enum SelectVariationEnum {
  Small = 'small',
  Default = 'default',
}

export enum SelectOrientationEnum {
  Top = 'top',
  Bottom = 'bottom',
}
