/**
 * Helper to transform user-provided props values, to our own
 * adapted props object.
 * This is necessary to avoid collision with Zoid component props.
 *
 * @param props
 */
export function adaptPluggyConnectProps(props) {
    const { onError, onSuccess, onOpen, onClose, onEvent, onHide } = props;
    // clone object as-is, to explicitly delete unwanted props later
    const adaptedPropsBase = Object.assign({}, props);
    // remove onXX props as some of them collide with Zoid props,
    // assign them to different keys instead
    delete adaptedPropsBase.onError;
    delete adaptedPropsBase.onSuccess;
    delete adaptedPropsBase.onOpen;
    delete adaptedPropsBase.onClose;
    delete adaptedPropsBase.onEvent;
    delete adaptedPropsBase.onHide;
    // build adapted props object,
    // starting from props without the unneeded ones,
    // adding the ones we need.
    return Object.assign(Object.assign({}, adaptedPropsBase), { onErrorProp: onError, onSuccessProp: onSuccess, onOpenProp: onOpen, onCloseProp: onClose, onEventProp: onEvent, onHideProp: onHide });
}
/**
 * Helper to transform adapted props object, back into user-provided props
 * values.
 * This is necessary to avoid collision with Zoid component props.
 *
 * @param adaptedProps
 */
export function restorePluggyConnectProps(adaptedProps) {
    const { onErrorProp, onSuccessProp, onOpenProp, onCloseProp, onEventProp, onHideProp, } = adaptedProps;
    // build original props object,
    // starting from props without the unneeded ones,
    // adding the ones we need.
    return Object.assign(Object.assign({}, adaptedProps), { onError: onErrorProp, onSuccess: onSuccessProp, onOpen: onOpenProp, onClose: onCloseProp, onEvent: onEventProp, onHide: onHideProp });
}
