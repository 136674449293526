import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@mzic/mzic-environments';
import {
  createEmptyLanguages,
  createEmptySettings,
  createEmptyUserProfile,
  GetAllLanguages,
  GetAllLanguagesData,
  GetSettingsV2Find,
  GetSettingsV2FindData,
  GetUserProfileData,
  PutSettingsV2Update,
  PutSettingsV2UpdateBody,
} from '@mzic/mzic-interfaces';
import { map, Subject } from 'rxjs';

interface SettingState {
  loading: boolean;
  userProfile: GetUserProfileData;
  settings: GetSettingsV2FindData;
  allLanguages: GetAllLanguagesData[];
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private apiUrl = `${environment.apiUrl}/api/backoffice/settings/v2`;

  private state = signal<SettingState>({
    loading: false,
    userProfile: createEmptyUserProfile(),
    settings: createEmptySettings(),
    allLanguages: [createEmptyLanguages()],
  });

  userProfile = computed(() => this.state().userProfile);
  settings = computed(() => this.state().settings);
  allLanguages = computed(() => this.state().allLanguages);

  settings$ = new Subject<{
    userProfile: GetUserProfileData;
    settings: GetSettingsV2FindData;
    allLanguages: GetAllLanguagesData[];
  }>();

  constructor(private readonly _http: HttpClient) {
    this.settings$.pipe(takeUntilDestroyed()).subscribe((settings) => {
      this.state.update((state) => ({
        ...state,
        loading: true,
        userProfile: settings.userProfile,
        settings: settings.settings,
        allLanguages: settings.allLanguages,
      }));
    });
  }

  getSettingsV2Find() {
    return this._http
      .get<GetSettingsV2Find>(`${this.apiUrl}/find`)
      .pipe(map((response) => response.data));
  }

  putSettingsV2Update(body: PutSettingsV2UpdateBody) {
    return this._http
      .put<PutSettingsV2Update>(`${this.apiUrl}/update`, body)
      .pipe(map((response) => response.data));
  }

  getAllLanguages() {
    return this._http
      .get<GetAllLanguages>(`${this.apiUrl}/all/languages`)
      .pipe(map((response) => response.data));
  }
}
