import { computed, effect, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@mzic/mzic-environments';

import { BehaviorSubject, Observable } from 'rxjs';

interface FeedbackModel {
  feedback: {
    message: string;
    type: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackState {
  private initialState = {
    feedback: {
      message: '',
      type: 'SUCCESS',
    },
  };

  private state = signal<FeedbackModel>(this.initialState);

  feedbackSnapshot = computed(() => this.state().feedback);

  private feedback$ = new BehaviorSubject<{ message: string; type: string }>({
    message: '',
    type: 'SUCCESS',
  });

  get feedback(): Observable<{ message: string; type: string }> {
    return this.feedback$.asObservable();
  }

  constructor() {
    effect(() => {
      if (!environment.production) {
        console.log('Feedback State', this.state());
      }
    });

    this.feedback$.pipe(takeUntilDestroyed()).subscribe((feedback) => {
      this.state.update((state) => ({
        ...state,
        feedback,
      }));
    });
  }

  setFeedback(message: string, type: string) {
    this.feedback$.next({
      message,
      type,
    });

    setTimeout(() => {
      this.resetFeeback();
    }, 3000);
  }

  resetFeeback() {
    this.feedback$.next({
      message: '',
      type: 'SUCCESS',
    });
  }
}
