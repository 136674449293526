import { ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ComponentRef,
  EnvironmentInjector,
  EventEmitter,
  Inject,
  Injectable,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MzicPopoverOptions } from '../model/mzic-popover-options.interface';
import { MzicPopover } from '../model/mzic-popover.interface';
import { MzicPopoverOLDComponent } from '../mzic-popover-OLD/mzic-popover-OLD.component';
import { MzicPopoverComponent } from '../mzic-popover.component';
import { MzicPopoverTemplateConfirmComponent } from '../templates/confirm/confirm.component';

interface DialogConfig {
  width?: string;
  height?: string;
  class?: string;
}

interface MzicPopoverInputs {
  custom?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MzicPopoverService {
  private notifier?: Subject<string>;
  private notifierFeedback?: Subject<string>;

  private dialogRef: MatDialogRef<any> | undefined;

  constructor(
    private injector: EnvironmentInjector,
    private dialog: MatDialog,
    private vcr: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  openComponent<T extends object>({
    component,
    dialogConfig = {},
    componentData = {},
    data = {},
  }: {
    component: ComponentType<T> | TemplateRef<T>;
    dialogConfig?: DialogConfig;
    componentData?: MzicPopoverInputs;
    data?: Partial<T>;
    viewContainerRef?: ViewContainerRef;
  }): MzicPopover {
    let classes = ['material-cdk-dialog-transparent'];
    if (dialogConfig.class?.length) {
      classes = [
        'material-cdk-dialog-transparent',
        ...dialogConfig.class.split(' '),
      ];
    }

    const resultSubject = new Subject<any>();

    const dialogRef = this.dialog.open(MzicPopoverComponent, {
      width: dialogConfig.width || 'auto',
      height: dialogConfig.height || 'auto',
      panelClass: classes,
    });

    dialogRef.afterOpened().subscribe(() => {
      const containerRef = dialogRef.componentInstance?.container;
      if (containerRef) {
        containerRef.clear();

        Object.assign(dialogRef.componentInstance, componentData);

        if (component instanceof TemplateRef) {
          containerRef.createEmbeddedView(component);
        } else {
          const componentRef = containerRef.createComponent(component, {
            environmentInjector: this.injector,
          });

          Object.assign(componentRef.instance, data);
          componentRef.changeDetectorRef.detectChanges();

          const instance = componentRef.instance as any;
          if (instance?.closeEmitter instanceof EventEmitter) {
            instance.closeEmitter.subscribe((value: any) => {
              resultSubject.next(value);
              resultSubject.complete();
              dialogRef.close();
            });
          }
        }
      }
    });

    dialogRef.afterClosed().subscribe((value) => {
      resultSubject.next(value);
      resultSubject.complete();
    });

    return {
      close: (value?: any) => {
        resultSubject.next(value);
        resultSubject.complete();
        dialogRef.close();
      },
      onClosed: () => resultSubject.asObservable(),
    };
  }

  openConfirm({
    title = '',
    description = '',
    cancelButton = 'CANCEL',
    confirmButton = 'CONFIRM',
    width = '378px',
  }) {
    return this.openComponent({
      component: MzicPopoverTemplateConfirmComponent,
      dialogConfig: { width },
      data: {
        title,
        description,
        cancelButton,
        confirmButton,
      },
    }).onClosed();
  }

  /**
   * @deprecated The method should not be used, use the openTemplate method instead
   */
  openTemplateDeprecated<T>(
    content: TemplateRef<T>,
    options: MzicPopoverOptions = {},
  ) {
    this.vcr.clear();

    const innerContent = this.vcr.createEmbeddedView(content);
    const component = this.vcr.createComponent(MzicPopoverOLDComponent, {
      environmentInjector: this.injector,
      projectableNodes: [innerContent.rootNodes],
    });

    component.instance.options = options;
    component.instance.closeEvent.subscribe(() => this.close());

    component.hostView.detectChanges();
    this.document.body.prepend(component.location.nativeElement);

    this.notifier = new Subject();
    return {
      notifier: this.notifier?.asObservable(),
      instance: component.instance,
    };
  }

  /**
   * @deprecated The method should not be used, use the openComponent method instead
   */
  openComponentDeprecated<T>(
    content: ComponentRef<T>,
    options: MzicPopoverOptions = {},
    clear = true,
  ) {
    if (clear) {
      this.vcr.clear();
    }
    const component = this.vcr.createComponent(MzicPopoverOLDComponent, {
      environmentInjector: this.injector,
      projectableNodes: [],
    });
    component.instance.options = options;
    component.instance.closeEvent.subscribe(() => this.close());
    component.hostView.detectChanges();

    const innerComponent = component.instance.container.createComponent<T>(
      content as any,
    );

    this.notifier = new Subject();
    return {
      notifier: this.notifier?.asObservable(),
      component: component.instance,
      innerComponent: innerComponent.instance,
    };
  }

  /**
   * @deprecated The method should not be used, use the openConfirm method instead
   */
  openConfirmDeprecated({
    title = '',
    description = '',
    cancelButton = 'CANCEL',
    confirmButton = 'CONFIRM',
    width = '378px',
    showBackground = true,
    closeOnClickOutside = false,
    cssClass = '',
  }: {
    title?: string;
    description?: string;
    cancelButton?: string;
    confirmButton?: string;
    width?: string;
    showBackground?: boolean;
    closeOnClickOutside?: boolean;
    cssClass?: string;
  } = {}) {
    const result = new Subject<boolean>();
    const popover =
      this.openComponentDeprecated<MzicPopoverTemplateConfirmComponent>(
        MzicPopoverTemplateConfirmComponent as any,
        {
          cssClass: `modal-popover ${cssClass}`,
          position: 'center',
          minWidth: width,
          maxWidth: width,
          showBackground,
          closeOnClickOutside,
        },
      );
    popover.innerComponent.title = title;
    popover.innerComponent.description = description;
    popover.innerComponent.cancelButton = cancelButton;
    popover.innerComponent.confirmButton = confirmButton;

    popover.innerComponent.closeEmitter.subscribe((success: boolean) => {
      popover.component.close();
      result.next(success);
      result.complete();
    });

    return result.asObservable();
  }

  close() {
    this.notifier?.complete();
  }
}
