import { RoyaltySplit } from './royalty-split.interface';

export interface RoyaltySplitCreateResponse {
  timestamp: string;
  status: string;
  data: RoyaltySplit;
}

export function createEmptyRoyaltySplit(): RoyaltySplit {
  return {
    name: '',
    revenueType: '',
    expirationDate: '',
    externalFunding: false,
    payees: [],
    priorityPayees: [],
    dspsToInclude: [],
    dspsToExclude: [],
    countriesToInclude: [],
    countriesToExclude: [],
    releaseIds: [],
    trackIds: [],
  };
}
