import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '@mzic/mzic-interfaces';
import { MzicOptionComponent } from '../core/option/option.component';
import { SelectComponent } from '../select/select.component';
import { SelectSimpleOptionComponent } from './select-simple-option/select-simple-option.component';

@Component({
  selector: 'mzic-select-simple',
  standalone: true,
  imports: [CommonModule, SelectComponent, MzicOptionComponent],
  templateUrl: './select-simple.component.html',
  styleUrl: './select-simple.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSimpleComponent),
      multi: true,
    },
  ],
})
export class SelectSimpleComponent
  extends SelectComponent
  implements ControlValueAccessor, AfterContentInit
{
  @ContentChildren(SelectSimpleOptionComponent)
  projectedOptions!: QueryList<SelectSimpleOptionComponent>;

  @Input() options: SelectOption[] | undefined;

  filteredOptions: SelectOption[] = [];
  searched = false;

  value: any;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  ngAfterContentInit(): void {
    // Se as opções forem passadas via projeção de conteúdo, use-as
    if (this.projectedOptions.length) {
      this.options = this.projectedOptions
        .toArray()
        .map((opt) => ({ id: opt.value, info: { title: opt.title } }));
      this.filteredOptions = [...this.options];
    }
  }

  writeValue(value: any): void {
    this.setValue(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setValue(value: any) {
    if (value === null) {
      return;
    }
    if (this.options) {
      const selectedOption = this.options.find((option) => option.id === value);
      if (selectedOption) {
        this.optionsSelected = [selectedOption.info];
        this.value = selectedOption.id;
        this.onChange(this.value);
        this.onTouched();
      } else {
        this.value = '';
        this.optionsSelected = [];
      }
    }
  }

  handleOptionChange(optionId: string | number) {
    this.setValue(optionId);
  }

  handleClosedChange(closed: boolean) {
    this.closedChange.emit(closed);
  }

  handleSearch(searchTerm: string) {
    this.filteredOptions = this.filterOptions(this.options ?? [], searchTerm);
    this.searched = true;
  }

  private filterOptions(
    options: SelectOption[],
    searchTerm: string,
  ): SelectOption[] {
    const lowercasedTerm = searchTerm.toLowerCase();

    return options.filter((option) =>
      option.info.title.toLowerCase().includes(lowercasedTerm),
    );
  }
}
