<div class="header">
  <h3 class="title">{{ title }}</h3>
  <button
    class="mz-button only-icon small ghost close white"
    (click)="closePopover(false)">
    <mzic-svg src="assets/icons/mzic-close.svg" />
  </button>
</div>
<div class="main" *ngIf="description.length > 0">
  <p>{{ description }}</p>
</div>
<div class="footer">
  <button class="mz-button small secondary" (click)="closePopover(false)">
    {{ cancelButton }}
  </button>
  <button class="mz-button small" (click)="closePopover(true)">
    {{ confirmButton }}
  </button>
</div>
