import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mzicDuration',
  standalone: true,
})
export class MzicDurationPipe implements PipeTransform {
  transform(value: number, humanize = false): string {
    if (typeof value !== 'number' || value < 0) {
      return '00:00';
    }
    // hours = Math.floor(_seconds / 3600)
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = Math.floor(value % 60);
    const duration = this.padTime(minutes) + ':' + this.padTime(seconds);

    if (humanize) {
      const split = duration.split(':');
      if (split[0] === '00') {
        return `${split[1]} sec`;
      }
      return `${split[0]} min ${split[1]} sec`;
    }

    return duration;
  }

  private padTime(time: number) {
    return time < 10 ? '0' + time : time;
  }
}
