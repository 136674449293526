import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { SelectOption } from '@mzic/mzic-interfaces';
import { MzicOptionComponent } from '../core/option/option.component';
import { BoldCurrencyPipe } from '../select/pipes/bold-currency/bold-currency.pipe';
import { SelectComponent } from '../select/select.component';
import { MzicCheckboxDirective } from './../mzic-checkbox/mzic-checkbox.directive';

@Component({
  selector: 'mzic-select-basic',
  standalone: true,
  templateUrl: './select-basic.component.html',
  styleUrl: './select-basic.component.scss',
  imports: [
    CommonModule,
    SelectComponent,
    MzicOptionComponent,
    BoldCurrencyPipe,
    MzicCheckboxDirective,
  ],
})
export class SelectBasicComponent extends SelectComponent implements OnChanges {
  @Input() options: SelectOption[] | undefined;
  filteredOptions: SelectOption[] = [];

  searched = false;

  get hasExtraPadding() {
    return this.options !== undefined && this.options.length > 7 ? true : false;
  }

  private filterOptions(
    options: SelectOption[],
    searchTerm: string,
  ): SelectOption[] {
    const lowercasedTerm = searchTerm.toLowerCase();

    return options.filter((option) =>
      option.info.title.toLowerCase().includes(lowercasedTerm),
    );
  }

  handleOptionChange(optionId: string | number) {
    let optionsIdSelected: (number | string)[] = [];
    const [selectedOption] =
      this.options?.filter((option) => option.id === optionId) ?? [];

    if (this.multiple) {
      this.options?.filter((option) => {
        if (option.id === optionId) {
          option.checked = option.checked ? false : true;
        }
      });
    }

    if (selectedOption) {
      if (this.multiple) {
        // Atualiza os itens multiplos selecionados
        this.optionsSelected =
          this.options
            ?.filter((option) => option.checked)
            .map((option) => option.info) ?? [];

        // Coleta os ids dos itens selecionados
        optionsIdSelected =
          this.options
            ?.filter((option) => option.checked)
            .map((option) => option.id) ?? [];
      } else {
        // Atualiza o item selecionado
        this.optionsSelected = [selectedOption.info];

        // Coleta o id do item selecionado
        optionsIdSelected.push(selectedOption.id);
      }

      // Emite os valores selecionados
      this.optionChange.emit(optionsIdSelected);

      this.resetSearch();
    }
  }

  handleClosedChange(closed: boolean) {
    this.closedChange.emit(closed);
  }

  handleSearch(searchTerm: string) {
    this.filteredOptions = this.filterOptions(this.options ?? [], searchTerm);
    this.searched = true;
  }

  resetSearch() {
    this.filteredOptions = this.options ?? [];
    this.searched = false;
  }

  override ngOnChanges(): void {
    if (this.options) {
      this.filteredOptions = [...this.options];
    }
  }
}
