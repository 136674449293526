import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  GetTrackV2Find,
  GetTrackV2FindParams,
  ReleaseAddToQueueResponse,
  ReleaseRetailBody,
  ReleaseRetailResponse,
  ReleaseStatusList,
} from '@mzic/mzic-interfaces';
import { httpParams } from '@mzic/mzic-utils';

@Injectable({
  providedIn: 'root',
})
export class TracksService {
  private apiUrl = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  getTrackV2Find(teamId: string, params?: GetTrackV2FindParams) {
    if (params?.page) {
      params.page--;
    }
    const headers = new HttpHeaders().append('TEAM-ID', teamId);
    return this._http.get<GetTrackV2Find>(
      `${this.apiUrl}/api/distribution/track/v2/find`,
      {
        headers,
        ...httpParams(params),
      },
    );
  }

  getReleasesStatus({
    releaseId,
    status,
  }: { releaseId?: string; status?: string } = {}) {
    return this._http.get<ReleaseStatusList>(
      `${this.apiUrl}/api/distribution/release/v2/status`,
      httpParams({ releaseId, status }),
    );
  }

  setReleaseRetail(body: ReleaseRetailBody) {
    return this._http.post<ReleaseRetailResponse>(
      `${this.apiUrl}/api/distribution/release/v2/retail`,
      body,
    );
  }

  addReleaseToQueue(releaseId: string, storeIds: number[]) {
    return this._http.post<ReleaseAddToQueueResponse>(
      `${this.apiUrl}/api/distribution/release/v2/${releaseId}/addtoqueue`,
      storeIds,
    );
  }
}
