import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TOOLTIP_DATA } from '@mzic/mzic-utils';

@Component({
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="mzic-popover style-tooltip">
      <div [innerHTML]="content"></div>
    </div>
  `,
})
export class MzicTooltipContentComponent {
  content: string | SafeHtml;

  constructor(@Inject(TOOLTIP_DATA) public data: any) {
    this.content = data.content;
  }
}
