import { getLoaderCSS } from '../css';
export const loaderComponentHtml = ({ nonce, text, }) => `
        <div class='preloader spinner'>
            <style ${nonce ? `nonce="${nonce}"` : ''}>
              ${getLoaderCSS()}
            </style>

            <div class='ui big active loader'>
              ${text
    ? `<div class='content'>
                      <div class='ui text loader'>
                        ${text}
                      </div>
                    </div>`
    : ''}
            </div>
        </div>
`;
