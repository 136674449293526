<div class="select-search" [class.select-search--focused]="focused()">
  <mzic-svg class="w15" src="assets/icons/mzic-search.svg"></mzic-svg>
  <input
    mzicAutofocus
    (focus)="toggleFocus()"
    (focusout)="toggleFocus()"
    [(ngModel)]="searchTerm"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onInputChange($event)"
    class="select-search__input"
    type="text"
    placeholder="Search..." />

  <!-- Loading spinner -->
  @if (loading) {
    <mzic-spinner></mzic-spinner>
  }

  <!-- Botão para limpar pesquisa -->
  @if (searchTerm && !loading) {
    <button (click)="resetSearch()" class="clear-button" type="button">
      <mzic-svg class="w15" src="assets/icons/mzic-close.svg"></mzic-svg>
    </button>
  }
</div>
