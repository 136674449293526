import { TableSortChanged } from '@mzic/mzic-interfaces';

export const getSortFromUrl = (sort: string): TableSortChanged | null => {
  if (sort) {
    const data = sort.split(':');
    if (data[0] && data[1] && (data[1] === 'asc' || data[1] === 'desc')) {
      return { id: data[0], type: data[1] };
    }
  }
  return null;
};
