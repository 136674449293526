<button
  (click)="selectPrevPagination()"
  class="prev"
  [disabled]="currentPageValue === '1'"></button>

@for (pageValue of pageValues; track $index) {
  <button
    (click)="selectPagination(pageValue)"
    class="page"
    [class.active]="pageValue === currentPageValue">
    {{ pageValue }}
  </button>
}

<button
  (click)="selectNextPagination()"
  class="next"
  [disabled]="currentPageValue === pageValues[pageValues.length - 1]"></button>
