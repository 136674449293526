import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  CreateWriterRequest,
  RecentArtist,
  ResponseInterface,
} from '@mzic/mzic-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WriterService {
  private apiUrl = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  createWriter(
    data: CreateWriterRequest,
  ): Observable<ResponseInterface<RecentArtist>> {
    const url = `${this.apiUrl}/api/distribution/artist/v2/writer/create`;
    return this._http.post<ResponseInterface<RecentArtist>>(url, data);
  }
}
