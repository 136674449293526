import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyNumber',
  standalone: true,
})
export class OnlyNumberPipe implements PipeTransform {
  transform(value: string | number): string {
    let stringValue = value.toString();
    stringValue = stringValue.replace(/[,.]/g, '');
    return stringValue.replace(/[^0-9]/g, '');
  }
}
