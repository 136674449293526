<mzic-select
  [variation]="variation"
  [orientation]="orientation"
  [optionsSelected]="optionsSelected"
  [optionsLabel]="optionsLabel"
  [useLightStyle]="useLightStyle"
  [useSuccessStyle]="useSuccessStyle"
  [useErrorStyle]="useErrorStyle"
  [invalid]="invalid"
  [searchable]="searchable"
  [showSearchLoading]="showSearchLoading"
  [multiple]="multiple"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [extraPadding]="false"
  (closedChange)="handleClosedChange($event)"
  (searchTextChange)="handleSearch($event)">
  <div content>
    <cdk-virtual-scroll-viewport
      appendOnly
      itemSize="36"
      class="virtual-cdk__viewport">
      @if (filteredOptions.length > 0 && !searched) {
        <header>
          @for (option of optionsPreferred; track option.countryCode) {
            <ng-container
              *ngTemplateOutlet="
                optionCountry;
                context: { $implicit: option }
              "></ng-container>
          }
          <div class="divider-group">All countries</div>
        </header>
      }

      <div
        *cdkVirtualFor="let option of filteredOptions"
        class="virtual-cdk__item">
        <ng-container
          *ngTemplateOutlet="
            optionCountry;
            context: { $implicit: option }
          "></ng-container>
      </div>

      @if (searched && filteredOptions.length === 0) {
        <div class="empty">No results found</div>
      }
    </cdk-virtual-scroll-viewport>
  </div>
</mzic-select>

<ng-template #optionCountry let-option>
  <div class="select-option-country" (click)="handleOption(option)">
    <div class="wrapper">
      <div class="select-option-country__flag">
        <img
          src="./assets/images/flags/{{
            option.countryCode | lowercase
          }}CountryFlag.svg"
          alt="Flag of the country {{ option.country }}" />
      </div>
      <div class="select-option-country__text">{{ option.country }}</div>
    </div>
    <div class="select-option-country__ddi">
      {{ option.internalPhoneCode }}
    </div>
  </div>
</ng-template>
