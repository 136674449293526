import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MzicSvgComponent } from '../../../mzic-svg/mzic-svg.component';

@Component({
  selector: 'mzic-confirm',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class MzicPopoverTemplateConfirmComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() cancelButton = '';
  @Input() confirmButton = '';

  @Output() closeEmitter = new EventEmitter<boolean>();

  closePopover(type: boolean) {
    this.closeEmitter.emit(type);
  }
}
