import { ApiResponse } from '../shared/api-response.interface';

export interface GetSettingsV2Find extends ApiResponse {
  data: GetSettingsV2FindData;
}

export interface GetSettingsV2FindData {
  region: string;
  language: number;
  allowExplicitContent: boolean;
  settingsNotificationDTO: {
    accountActivityEmail: boolean;
    accountActivityInApp: boolean;
    accountActivitySms: boolean;
    securityEmail: boolean;
    securityInApp: boolean;
    securitySms: boolean;
    teamActivityEmail: boolean;
    teamActivityInApp: boolean;
    teamActivitySms: boolean;
  };
}

export function createEmptySettings(): GetSettingsV2FindData {
  return {
    region: '',
    language: 0,
    allowExplicitContent: false,
    settingsNotificationDTO: {
      securityEmail: false,
      securityInApp: false,
      securitySms: false,
      accountActivityEmail: false,
      accountActivityInApp: false,
      accountActivitySms: false,
      teamActivityEmail: false,
      teamActivityInApp: false,
      teamActivitySms: false,
    },
  };
}
