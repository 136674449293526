import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SelectOptionCountry, SelectOptionData } from '@mzic/mzic-interfaces';
import { SelectComponent } from '../select/select.component';
import { CountriesInfo, CountriesInfoPreferred } from './data/countries';

@Component({
  selector: 'mzic-select-country',
  standalone: true,
  imports: [CommonModule, SelectComponent, ScrollingModule],
  templateUrl: './select-country.component.html',
  styleUrl: './select-country.component.scss',
})
export class SelectCountryComponent extends SelectComponent implements OnInit {
  @Output() optionSelectedChange = new EventEmitter<SelectOptionCountry>();
  searched = false;

  filteredOptions: SelectOptionCountry[] = [];

  options: SelectOptionCountry[] = CountriesInfo;
  optionsPreferred: SelectOptionCountry[] = CountriesInfoPreferred;

  ngOnInit() {
    this.filteredOptions = [...this.options];
  }

  private filterOptions(
    options: SelectOptionCountry[],
    searchTerm: string,
  ): SelectOptionCountry[] {
    const lowercasedTerm = searchTerm.toLowerCase();

    return options.filter((option) =>
      option.country.toLowerCase().includes(lowercasedTerm),
    );
  }

  handleClosedChange(closed: boolean) {
    this.searched = false;
    this.closedChange.emit(closed);
  }

  handleSearch(searchTerm: string) {
    this.filteredOptions = this.filterOptions(this.options ?? [], searchTerm);
    this.searched = searchTerm != '' ? true : false;
  }

  handleOption(country: SelectOptionCountry) {
    const option: SelectOptionData = {
      title: country.internalPhoneCode,
      image: `./assets/images/flags/${country.countryCode.toLowerCase()}CountryFlag.svg`,
    };

    this.optionsSelected = [option];
    this.optionChange.emit([country.internalPhoneCode]);
    this.optionSelectedChange.emit(country);
  }
}
