import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { MzicSvgComponent } from '../../../mzic-svg/mzic-svg.component';

@Component({
  selector: 'mzic-http-error',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent, TranslocoModule],
  providers: [
    provideTranslocoScope({
      scope: 'mzic-http-error',
      alias: 'lang',
    }),
  ],
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss'],
})
export class HttpErrorComponent {
  @Output() closed = new EventEmitter<void>();
  @Output() tryAgain = new EventEmitter<void>();
}
