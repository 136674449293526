import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isApplyButtonDisabled',
  standalone: true
})
export class IsApplyButtonDisabledPipe implements PipeTransform {
  transform(multiDateSelect: boolean, dateSelected: Date | null, startDateSelected: Date | null, endDateSelected: Date | null): boolean {
    const isSingleDateAndSelected = !multiDateSelect && !dateSelected;
    const isMultiDateAndStartEndIsSelected = multiDateSelect && (!startDateSelected || !endDateSelected);

    return isSingleDateAndSelected || isMultiDateAndStartEndIsSelected;
  }
}
