import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TableSortChanged, TableSortType } from '@mzic/mzic-interfaces';
import { getSortFromUrl } from '@mzic/mzic-utils';

@Component({
  selector: 'mzic-table-sort',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mzic-table-sort.component.html',
  styleUrls: ['./mzic-table-sort.component.scss'],
})
export class MzicTableSortComponent implements OnInit {
  @Input({ required: true }) id = '';
  @Output() changed = new EventEmitter<TableSortChanged>();
  protected sort: TableSortType = 'default';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const sorter = this.activatedRoute.snapshot.queryParamMap.get('sort');
    if (sorter) {
      const data = getSortFromUrl(sorter);
      if (data && data.id === this.id) {
        this.sort = data.type;
      }
    }
  }

  reset() {
    this.sort = 'default';
  }

  toggleSort() {
    if (this.sort === 'asc') {
      this.setDesc();
    } else if (this.sort === 'desc') {
      this.setDefault();
    } else {
      this.setAsc();
    }
  }

  setDefault() {
    this.sort = 'default';
    this.changed.emit({ type: this.sort, id: this.id });
    this.updateQueryParams();
  }

  setDesc() {
    this.sort = 'desc';
    this.changed.emit({ type: this.sort, id: this.id });
    this.updateQueryParams();
  }

  setAsc() {
    this.sort = 'asc';
    this.changed.emit({ type: this.sort, id: this.id });
    this.updateQueryParams();
  }

  updateQueryParams() {
    const queryParams: Params = { sort: null };
    if (this.sort !== 'default') {
      queryParams['sort'] = `${this.id}:${this.sort}`;
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
