import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import { ImageCDNSizes } from '../../enums';

@Pipe({
  name: 'mzicImage',
  standalone: true,
})
export class MzicImagePipe implements PipeTransform {
  transform(
    imageToken: string,
    width: ImageCDNSizes = ImageCDNSizes.DEFAULT,
  ): string {
    return `${environment.cdnUrl}/${imageToken}/${width}/image`;
  }
}
