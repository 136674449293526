import { DOCUMENT } from '@angular/common';
import {
  EnvironmentInjector,
  Inject,
  Injectable,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { MzicToastComponent } from '../mzic-toast.component';

@Injectable({
  providedIn: 'root',
})
export class MzicToastService {
  constructor(
    private injector: EnvironmentInjector,
    private vcr: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  create({
    title = '',
    description = '',
    icon = '',
    iconColor = 'neutral-500',
    iconType = 'fill',
    button = '',
    showCloseButton = true,
    showLoader = false,
    loaderColor = 'purple',
    time = 3000,
    width = '404px',
  }: {
    title?: string;
    description?: string;
    icon?: string;
    iconColor?: string;
    iconType?: 'stroke' | 'fill';
    button?: string;
    showCloseButton?: boolean;
    showLoader?: boolean;
    loaderColor?: string;
    time?: number;
    width?: string;
  } = {}) {
    const component = this.vcr.createComponent(MzicToastComponent, {
      environmentInjector: this.injector,
    });

    component.instance.title = title;
    component.instance.description = description;
    component.instance.icon = icon;
    component.instance.iconColor = iconColor;
    component.instance.iconType = iconType;
    component.instance.button = button;
    component.instance.showCloseButton = showCloseButton;
    component.instance.showLoader = showLoader;
    component.instance.loaderColor = loaderColor;
    component.instance.time = time;
    component.instance.width = width;

    const notifier = new Subject();
    component.instance.closeEvent.subscribe((buttonClicked: boolean) => {
      notifier.next(buttonClicked);
      notifier.complete();
    });

    component.hostView.detectChanges();
    this.document.body
      .querySelector('#mzicToastContainer')
      ?.prepend(component.location.nativeElement);

    return notifier.asObservable();
  }

  openFeatureComingSoon() {
    this.create({
      description: 'Feature coming soon!',
      icon: 'assets/icons/mzic-starts.svg',
      iconColor: 'purple',
      showCloseButton: true,
    });
  }
}
