import { ArtistItem } from '../distribution/track-resource.interfaces';

export interface GetTrackV2Find {
  timestamp: Date;
  status: string;
  data: GetTrackV2FindData;
  error: {
    error: string;
    title: string;
    code: string;
    trace: string;
    modal: boolean;
  };
  errors: [
    {
      error: string;
      title: string;
      code: string;
      trace: string;
      modal: boolean;
    },
  ];
  page: number;
  metadata: {
    additionalProp1: any;
    additionalProp2: any;
    additionalProp3: any;
  };
  total_pages: number;
  per_page: number;
  total_items: number;
}

export interface GetTrackV2FindParams {
  page: number;
  size: number;
  sort?: string;
  search?: string;
  genreIds?: number[];
  releaseIds?: number[];
  releaseDateStart?: string;
  releaseDateEnd?: string;
  creationDateStart?: string;
  creationDateEnd?: string;
}

export interface GetTrackV2FindData {
  totalPages: number;
  totalElements: number;
  size: number;
  content: GetTrackV2FindItem[];
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  pageable: {
    offset: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  first: boolean;
  last: boolean;
  empty: boolean;
}

export interface GetTrackV2FindItem {
  id: number;
  title: string;
  titleVersion: string;
  primaryGenre: {
    name: string;
    parentGenre: string;
  };
  isrc: string;
  releases: GetTrackV2FindItemRelease[];
  artistNames: string;
  artists: ArtistItem[];
  hash?: string;
  firstReleaseHash: string;
  status: GetTrackV2FindItemStatusEnum;
  fileStatus: 'CLEAN' | 'WARNING' | 'PROCESSING';
}

export interface GetTrackV2FindItemRelease {
  id: number;
  title: string;
  hash: string;
}

export enum GetTrackV2FindItemStatusEnum {
  READY = 'READY',
  IN_INSPECTION = 'IN_INSPECTION',
  DISTRIBUTED = 'DISTRIBUTED',
}
