<mzic-select
  [variation]="variation"
  [orientation]="orientation"
  [optionsSelected]="optionsSelected"
  [optionsLabel]="optionsLabel"
  [useLightStyle]="useLightStyle"
  [useSuccessStyle]="useSuccessStyle"
  [useErrorStyle]="useErrorStyle"
  [invalid]="invalid"
  [searchable]="searchable"
  [multiple]="multiple"
  [fixedContent]="fixedContent"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [loading]="loading"
  [editabble]="editabble"
  [extraPadding]="hasExtraPadding"
  (closedChange)="handleClosedChange($event)"
  (searchTextChange)="handleSearch($event)">
  <div content>
    @for (option of filteredOptions; track option.id) {
      <mzic-option
        (optionChange)="handleOptionChange($event)"
        [id]="option.id"
        [value]="option.info.title">
        <section class="select-option-wrapper">
          <div
            class="select-option"
            [class.select-option--white]="useLightStyle">
            <!-- Se tiver imagem (opcional)-->
            @if (option.info.image) {
              <div class="select-option__image">
                <img [src]="option.info.image" [alt]="option.info.title" />
              </div>
            }

            <div class="select-option__main">
              <header>
                <div class="select-option__main-title">
                  <span [innerHTML]="option.info.title | boldCurrency"></span>
                </div>
                <div class="select-option__main-version">
                  {{ option.info.version }}
                </div>
              </header>

              @if (option.info.description) {
                <div class="select-option__main-description">
                  {{ option.info.description }}
                </div>
              }
            </div>
          </div>
          @if (multiple) {
            <input
              type="checkbox"
              mzicCheckbox
              [name]="'checkbox_' + option.id"
              [checked]="option.checked ?? false" />
          }
        </section>
      </mzic-option>
    }
    @if (searched && filteredOptions.length === 0) {
      <div class="empty">No results found</div>
    }
  </div>
</mzic-select>
