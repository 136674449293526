import { Pipe, PipeTransform } from '@angular/core';
import { ReleaseTypeEnum } from '@mzic/mzic-interfaces';

@Pipe({
  name: 'mzicReleaseType',
  standalone: true,
})
export class MzicReleaseTypePipe implements PipeTransform {
  enum = ReleaseTypeEnum;

  transform(releaseTypeId: number): string {
    if (releaseTypeId === this.enum.ALBUM) {
      return 'Album';
    }
    if (releaseTypeId === this.enum.SINGLE) {
      return 'Single';
    }
    if (releaseTypeId === this.enum.EP) {
      return 'EP';
    }
    return 'Unknown';
  }
}
