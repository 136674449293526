import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@mzic/mzic-environments';
import { AssetsAvailable, GetTrackV2FindParams } from '@mzic/mzic-interfaces';

import { BehaviorSubject, Observable } from 'rxjs';
import { RoyaltySplitsService } from '../royalty-splits.service';

interface AssetsAvailableModel {
  isLoading: boolean;
  assetsAvailable: AssetsAvailable[];
  assetsAvailableChecked: AssetsAvailable[];
  searchTerm: string;
  numberOfRulesNotEligible: number;
  countCheckedAssets: {
    countReleases: number;
    countTracks: number;
  };
  sort: string;
}

@Injectable({
  providedIn: 'root',
})
export class AssetsAvailableState {
  private royaltySplitsService = inject(RoyaltySplitsService);

  private initialState = {
    isLoading: false,
    assetsAvailable: [],
    assetsAvailableChecked: [],
    searchTerm: '',
    numberOfRulesNotEligible: 0,
    countCheckedAssets: {
      countReleases: 0,
      countTracks: 0,
    },
    sort: '',
  };

  private state = signal<AssetsAvailableModel>(this.initialState);

  isLoadingSnapshot = computed(() => this.state().isLoading);
  assetsAvailableSnapshot = computed(() => this.state().assetsAvailable);
  searchTermSnapshot = computed(() => this.state().searchTerm);
  countCheckedAssetsSnapshot = computed(() => this.state().countCheckedAssets);
  sortSnapshot = computed(() => this.state().sort);

  private isLoading$ = new BehaviorSubject<boolean>(false);
  private assetsAvailable$ = new BehaviorSubject<AssetsAvailable[]>([]);
  private assetsAvailableFiltered$ = new BehaviorSubject<AssetsAvailable[]>([]);
  private assetsAvailableChecked$ = new BehaviorSubject<AssetsAvailable[]>([]);
  private countCheckedAssets$ = new BehaviorSubject<{
    countReleases: number;
    countTracks: number;
  }>({
    countReleases: 0,
    countTracks: 0,
  });
  private numberOfRulesNotEligible$ = new BehaviorSubject<number>(0);
  private sort$ = new BehaviorSubject<string>('');

  get isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  get assetsAvailable(): Observable<AssetsAvailable[]> {
    return this.assetsAvailable$.asObservable();
  }

  get assetsAvailableFiltered(): Observable<AssetsAvailable[]> {
    return this.assetsAvailableFiltered$.asObservable();
  }

  get assetsAvailableChecked(): Observable<AssetsAvailable[]> {
    return this.assetsAvailableChecked$.asObservable();
  }

  get numberOfRulesNotEligible(): Observable<number> {
    return this.numberOfRulesNotEligible$.asObservable();
  }

  constructor() {
    effect(() => {
      if (!environment.production) {
        console.log('Assets Available State', this.state());
      }
    });

    this.isLoading$.pipe(takeUntilDestroyed()).subscribe((isLoading) => {
      this.state.update((state) => ({
        ...state,
        isLoading,
      }));
    });

    this.assetsAvailable$
      .pipe(takeUntilDestroyed())
      .subscribe((assetsAvailable) => {
        this.state.update((state) => ({
          ...state,
          assetsAvailable,
        }));

        if (this.searchTermSnapshot().length > 0) {
          this.searchAssets(this.searchTermSnapshot());
        } else {
          this.assetsAvailableFiltered$.next(assetsAvailable);
        }

        this.updateCheckedAssets();
      });

    this.assetsAvailableFiltered$
      .pipe(takeUntilDestroyed())
      .subscribe((assetsAvailableFiltered) => {
        this.state.update((state) => ({
          ...state,
          assetsAvailableFiltered,
        }));
      });

    this.assetsAvailableChecked$
      .pipe(takeUntilDestroyed())
      .subscribe((assetsAvailableChecked) => {
        this.state.update((state) => ({
          ...state,
          assetsAvailableChecked,
        }));
      });

    this.countCheckedAssets$
      .pipe(takeUntilDestroyed())
      .subscribe((countCheckedAssets) => {
        this.state.update((state) => ({
          ...state,
          countCheckedAssets,
        }));
      });

    this.numberOfRulesNotEligible$
      .pipe(takeUntilDestroyed())
      .subscribe((numberOfRulesNotEligible) => {
        this.state.update((state) => ({
          ...state,
          numberOfRulesNotEligible,
        }));
      });

    this.sort$.pipe(takeUntilDestroyed()).subscribe((sort) => {
      this.state.update((state) => ({
        ...state,
        sort,
      }));
    });
  }

  loadAssets(params?: GetTrackV2FindParams) {
    this.isLoading$.next(true);

    if (params?.sort && params.sort !== '') {
      this.sort$.next(params.sort);
    }

    this.royaltySplitsService.getAssetsAvailable(params).subscribe({
      next: (assetsAvailable) => {
        this.assetsAvailable$.next(assetsAvailable.data);
        this.isLoading$.next(false);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }

  searchAssets(query: string) {
    this.isLoading$.next(true);

    this.state.update((state) => ({
      ...state,
      searchTerm: query,
    }));

    if (!query) {
      this.assetsAvailableFiltered$.next(this.assetsAvailable$.value);
      setTimeout(() => {
        this.isLoading$.next(false);
      }, 1000);
      return;
    }

    const filteredAssets = this.assetsAvailable$.value.filter((asset) => {
      const releaseTitleMatches = asset.release?.title
        ?.toLowerCase()
        .includes(query.toLowerCase());
      const trackTitleMatches = asset.track?.title
        ?.toLowerCase()
        .includes(query.toLowerCase());

      return releaseTitleMatches || trackTitleMatches;
    });

    this.assetsAvailableFiltered$.next(filteredAssets);
    setTimeout(() => {
      this.isLoading$.next(false);
    }, 500);
  }

  toggleAssetChecked(id: number, edit?: boolean) {
    const updatedAssets = this.state().assetsAvailable.map((asset) => {
      if (asset.type === 'release' && asset.release.id === id) {
        return {
          ...asset,
          release: {
            ...asset.release,
            checked: !asset.release.checked,
            tracks: asset.release.tracks.map((track) => {
              return {
                ...track,
                checked: edit ? true : !asset.release.checked,
              };
            }),
          },
        };
      }

      if (asset.type === 'track' && asset.track.id === id) {
        return {
          ...asset,
          track: {
            ...asset.track,
            checked: edit ? true : !asset.track.checked,
          },
        };
      }

      return asset;
    });

    this.assetsAvailable$.next(updatedAssets);
  }

  toggleAllAssetsChecked(checked: boolean) {
    this.isLoading$.next(true);

    const updatedAssets = this.state().assetsAvailable.map((asset) => {
      if (asset.type === 'release') {
        const { release } = asset;
        return {
          ...asset,
          release: {
            ...release,
            checked: checked,
          },
        };
      }

      if (asset.type === 'track') {
        return {
          ...asset,
          track: {
            ...asset.track,
            checked: checked,
          },
        };
      }

      return asset;
    });

    this.assetsAvailable$.next(updatedAssets);
    setTimeout(() => {
      this.isLoading$.next(false);
    }, 1000);
  }

  toggleAssetReleaseTrackChecked(releaseId: number, trackId: number) {
    const updatedAssets = this.state().assetsAvailable.map((asset) => {
      if (asset.type !== 'release' || asset.release.id !== releaseId) {
        return asset;
      }

      const updatedTracks = asset.release.tracks.map((track) => {
        if (track.id !== trackId) {
          return track;
        }

        return {
          ...track,
          checked: track.checked === null ? true : !track.checked,
        };
      });

      const allTracksChecked = updatedTracks.every((track) => track.checked);

      return {
        ...asset,
        release: {
          ...asset.release,
          checked: allTracksChecked,
          tracks: updatedTracks,
        },
      };
    });

    this.assetsAvailable$.next(updatedAssets);
  }

  private updateCheckedAssets() {
    const checkedAssets = this.state().assetsAvailable.filter(
      (asset) =>
        asset.release?.checked === true || asset.track?.checked === true,
    );

    const checkedReleaseIds = checkedAssets
      .filter((asset) => asset.release)
      .map((asset) => asset.release.id);

    const checkedTracksReleases = this.state()
      .assetsAvailable.filter((asset) => asset.type === 'release')
      .map((release) => {
        if (checkedReleaseIds.includes(release.release.id)) {
          return [];
        }

        const checkedTracks = release.release.tracks.filter(
          (track) => track.checked,
        );

        return checkedTracks.length > 0 ? checkedTracks : [];
      })
      .flat();

    const formattedCheckedTracksReleases = checkedTracksReleases.map(
      (track) => {
        return {
          track: track,
          type: 'track',
        } as AssetsAvailable;
      },
    );

    this.assetsAvailableChecked$.next([
      ...checkedAssets,
      ...formattedCheckedTracksReleases,
    ]);

    this.sumRulesNotEligibleForSplit();
    this.countCheckedAssets();
  }

  private sumRulesNotEligibleForSplit() {
    let numberOfRulesNotEligible = 0;

    this.state().assetsAvailable.map((assetsAvailable) => {
      if (
        assetsAvailable.type === 'track' &&
        assetsAvailable.track?.checked &&
        assetsAvailable.track?.eligibleForSplit?.ruleNotEligible
      ) {
        numberOfRulesNotEligible += 1;
      }

      if (
        assetsAvailable.type === 'release' &&
        assetsAvailable.release?.eligibleForSplit
      ) {
        assetsAvailable.release.tracks.forEach((track) => {
          if (track?.eligibleForSplit?.ruleNotEligible && track.checked) {
            numberOfRulesNotEligible += 1;
          }
        });
      }
    });

    this.numberOfRulesNotEligible$.next(numberOfRulesNotEligible);
  }

  private countCheckedAssets() {
    const countReleases = this.state().assetsAvailableChecked.filter(
      (asset) => asset.type === 'release',
    ).length;

    const countTracks = this.state().assetsAvailableChecked.filter(
      (asset) => asset.type === 'track',
    ).length;

    this.countCheckedAssets$.next({
      countReleases,
      countTracks,
    });
  }

  toggleReleaseTrackListEvent(releaseId: number) {
    const updatedAssets = this.state().assetsAvailable.map((asset) => {
      if (asset.type !== 'release' || asset.release.id !== releaseId) {
        return asset;
      }

      return {
        ...asset,
        release: {
          ...asset.release,
          childrenOpened: !asset.release.childrenOpened,
        },
      };
    });

    this.assetsAvailable$.next(updatedAssets);
  }
}
